import { Injectable } from '@angular/core';
import { Lane } from '../classes/items';
import { Station } from '../classes/station';
import { Wagon } from '../classes/wagon';
import { LaneService } from './lane.service';
import { WagonService } from './wagon.service';
import { StationService } from './station.service';
import { StakeholderService } from './stakeholder.service';
import { Stakeholder } from '../classes/items';
import { Observable } from 'rxjs';
import { Contact } from '../classes/contact';
import { ArrivalHistoryService } from './arrival-history.service';
import { OperationStatusService, OPERATION_STATUS } from './operation-status.service';
import { ArrivalHistory } from '../classes/arrival-history';
import { OperationStatus } from '../classes/operation-status';
import { StakeholderTypeService } from './stakeholder-type.service';
import { StakeholderType } from '../classes/stakeholder-type';
import { Language } from '../classes/language';
import { LanguageService } from './language.service';
import { SenderReceiverService } from './sender-receiver.service';
import { SenderReceiver } from '../classes/sender-receiver';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private laneService: LaneService,
              private wagonService: WagonService,
              private stationService: StationService,
              private stakeholderService: StakeholderService,
              private arrivalHistoryService: ArrivalHistoryService,
              private operationStatusService: OperationStatusService,
              private stakeholderTypeService: StakeholderTypeService,
              private languageService: LanguageService,
              private senderReceiverService: SenderReceiverService) { }
  
      
  public get isLoadingArrivalHistory(): boolean {
    return this.arrivalHistoryService.isLoading;
  }

  public get arrivalHistory(): ArrivalHistory[] {
    return this.arrivalHistoryService.arrivalHistory;
  }

  public observeArrivalHistory(): Observable<ArrivalHistory[]> {
    return new Observable((observer) => {
      observer.next(this.arrivalHistory)
      this.arrivalHistoryService.change.subscribe(() => {
        observer.next(this.arrivalHistory);
      })
    })
  }

  public get isLoadingOperationStatus(): boolean {
    return this.operationStatusService.isLoading;
  }

  public get operationStatus(): OperationStatus[] {
    return this.operationStatusService.operationStatus;
  }

  public observeOperationStatus(): Observable<OperationStatus[]> {
    return new Observable((observer) => {
      observer.next(this.operationStatus)
      this.operationStatusService.change.subscribe(() => {
        observer.next(this.operationStatus);
      })
    })
  }

  public observeUnseenOperationStatus(): Observable<OperationStatus[]> {
    return new Observable((observer) => {
      const unseen = this.operationStatus?.filter(e => localStorage.getItem(`${OPERATION_STATUS.PREFIX}${e.id}`) !== '1');
      observer.next(unseen);
      this.operationStatusService.change.subscribe(() => {
        const unseen = this.operationStatus?.filter(e => localStorage.getItem(`${OPERATION_STATUS.PREFIX}${e.id}`) !== '1');
        observer.next(unseen);
      })
    })
  }

  public get isLoadingLanes(): boolean {
    return this.laneService.isLoading;
  }

  public get lanes(): Lane[] {
    return this.laneService.lanes;
  }

  public observeLanes(): Observable<Lane[]> {
    return new Observable((observer) => {
      observer.next(this.lanes)
      this.laneService.change.subscribe(() => {
        observer.next(this.lanes);
      })
    })
  }

  public filterLanes(name: string): Lane[] {
    const filterValue = name.toLowerCase();
    return this.lanes?.filter(option => `${option.agreement_number.toLowerCase()}${option.departure_station?.name.toLowerCase()}${option.arrival_station?.name.toLowerCase()}`.indexOf(filterValue) !== -1);
  }

  public displayLane(lane: Lane): string {
    return lane && lane.id ? `${lane.agreement_number} ${lane.departure_station?.name} - ${lane.arrival_station?.name}` : '';
  }

  public lane(id: number): Observable<Lane> {
    return new Observable((observer) => {
      let lane = this.lanes?.find((elem => elem.id == id));
      observer.next(lane);
      this.laneService.change.subscribe(() => {
        let lane = this.lanes?.find((elem => elem.id == id));
        observer.next(lane);
      })
    })
  }

  public get countries(): Observable<string[]> {
    return new Observable((observer) => {
      if (!this.stations) observer.next(['DK'])
      else {
        let countries = [...new Set(this.stations?.map((station) => {
            return station.country_code_iso
        }).filter( Boolean ))]
        observer.next(countries);
      }
      this.stationService.change.subscribe(() => {
        let countries = [...new Set(this.stations?.map((station) => { 
            return station.country_code_iso
        }).filter( Boolean ))]
        observer.next(countries);
      })
    })
  }

  public get sendersReceivers(): SenderReceiver[] {
    return this.senderReceiverService.sendersReceivers;
  }

  public observeSendersReceivers(): Observable<SenderReceiver[]> {
    return new Observable((observer) => {
      observer.next(this.senderReceiverService.sendersReceivers);
      this.senderReceiverService.change.subscribe(() => {
        observer.next(this.senderReceiverService.sendersReceivers);
      })
    })
  }

  public get languages(): Observable<Language[]> {
    return new Observable((observer) => {
      observer.next(this.languageService.languages);
      this.languageService.change.subscribe(() => {
        observer.next(this.languageService.languages);
      })
    })
  }

  public locations(filter?: string): Observable<string[]> {
    return new Observable((observer) => {
      if (!this.wagons) observer.next([]);
      else {
        let locations = [...new Set(this.wagons?.map((wagon) => {
          return wagon.registrations[wagon.registrations.length - 1]?.station?.name;
        }).filter( Boolean ).filter(elem => {
          if (!filter) return true;
          else return elem.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1
        }))]
        observer.next(locations);
      }
      this.wagonService.change.subscribe(() => {
        let locations = [...new Set(this.wagons?.map((wagon) => {
          return wagon.registrations[wagon.registrations.length - 1]?.station?.name
        }).filter( Boolean ).filter(elem => {
          if (!filter) return true;
          else return elem.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1
        }))]
        observer.next(locations)
      })
    })
  }

  public set lanes(lanes: Lane[]) {
    this.laneService.lanes = lanes;
  }

  public get stations(): Station[] {
    return this.stationService.stations;
  }

  public get observeStations(): Observable<Station[]> {
    return new Observable((observer) => {
      observer.next(this.stationService.stations || [])
      this.stationService.change.subscribe(() => {
        observer.next(this.stationService.stations)
      })
    })
  }

  public get isLoadingWagons(): boolean {
    return this.wagonService.isLoading;
  }

  public get wagons(): Wagon[] {
    return this.wagonService.wagons;
  }

  public set wagons(wagons: Wagon[]) {
    this.wagonService.wagons = wagons;
  }

  public observeWagons(): Observable<Wagon[]> {
    return new Observable((observer) => {
      observer.next(this.wagons)
      this.wagonService.change.subscribe(() => {
        observer.next(this.wagons)
      })
    })
  }

  public get isLoadingStakeholderTypes(): boolean {
    return this.stakeholderTypeService.isLoading;
  }

  public get stakeholderTypes(): Observable<StakeholderType[]> {
    return new Observable((observer) => {
      observer.next(this.stakeholderTypeService.stakeholderTypes)
      this.stakeholderTypeService.change.subscribe(() => {
        observer.next(this.stakeholderTypeService.stakeholderTypes);
      })
    })
  }

  public get isLoadingStakeholders(): boolean {
    return this.stakeholderService.isLoading;
  }

  public get stakeholders(): Stakeholder[] {
    return this.stakeholderService.stakeholders;
  }

  public observeStakeholders(): Observable<Stakeholder[]> {
    return new Observable((observer) => {
      observer.next(this.stakeholders)
      this.stakeholderService.change.subscribe(() => {
        observer.next(this.stakeholders);
      })
    })
  }

  public filterStakeholders(name: string): Stakeholder[] {
    const filterValue = name.toLowerCase();
    return this.stakeholders?.filter(option => option.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  public displayStakeholder(stakeholder: Stakeholder): string {
    return stakeholder && stakeholder.name ? stakeholder.name : '';
  }

  public stakeholder(id: number): Observable<Stakeholder> {
    return new Observable((observer) => {
      let stakeholder = this.stakeholders?.find(elem => elem.id == id);
      observer.next(stakeholder);
      this.stakeholderService.change.subscribe(() => {
        let stakeholder = this.stakeholders?.find(elem => elem.id == id);
        observer.next(stakeholder);
      })
    })
  }

  public set stakeholders(stakeholders: Stakeholder[]) {
    this.stakeholderService.stakeholders = stakeholders;
  }

  public clearAll() {
    this.laneService.clear();
    this.stakeholderService.clear();
    this.stationService.clear();
    this.wagonService.clear();
    this.arrivalHistoryService.clear();
    this.operationStatusService.clear();
  }

  private containsLane(lane: Lane, list: Lane[]) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].agreement_number === lane.agreement_number && 
            list[i].departure_station_id === lane.departure_station_id && 
            list[i].arrival_station_id === lane.arrival_station_id) {
            return true;
        }
    }

    return false;
}
}
