import { EventEmitter, Injectable, Output } from '@angular/core';
import { Wagon } from '../classes/wagon';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';
import { SearchService } from './search.service';
import * as moment from 'moment';
import { FilterService } from '../services/filter.service';
import { Container } from '../classes/container';
import { NotificationService } from './notification.service';
import { IWagon } from '../interfaces/i-wagon';

@Injectable({
  providedIn: 'root'
})
export class WagonService {

  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;
  private _wagons: Wagon[] = null;
  public filtered: Array<Wagon> = [];
  private _lastUpdated: Date = null;

  constructor(private http: HttpClient,
              private headers: HeaderService,
              private api: ApiService,
              private searchService: SearchService,
              private filterService: FilterService,
              private notificationService: NotificationService) {
                this.filterService.changeWagon.subscribe(() => {
                  if (!this._isLoading && !this._loadFailed) {
                    this.get(
                      this.filterService.getParams(
                        this.filterService.wagon
                      ),
                      true,
                      true
                    )
                  }
                })
              }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get wagons(): Wagon[] {
    if (!this._wagons && !this._isLoading && !this._loadFailed) {
      this.get(
        this.filterService.getParams(
          this.filterService.wagon
        )
      );
    }
    return this._wagons;
  }

  public update() {
    this.get(
      this.filterService.getParams(
        this.filterService.wagon
      ),
      false,
      false
    );
  }

  public set wagons(wagons: Wagon[]) {
    this._wagons = wagons;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get lastUpdated(): Date {
    return this._lastUpdated;
  }

  public clear() {
    this._wagons = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  private get(params?: string, showLoading: boolean = true, clearData: boolean = false) {
    if (showLoading) {
      this._isLoading = true;
    }
    if (clearData) {
      this._wagons = [];
      this.toggle();
    }
    this.http.get<IWagon[]>(
      `${this.api.LOCATION}/wagons?${params}`,
      this.headers.getOptions()
    )
    .subscribe({
      next: (response: IWagon[]) => {
        if (response && response instanceof Array) {
          let wagons = [];
          for (let wagon of response) {
            for (let i=0; i<wagon.containers.length; i++) {
              wagons.push(new Wagon(wagon, i));
            }
          }
          let updates = [];
          this._wagons = wagons.map((wagon: Wagon) => {
            let oldItem = this._wagons?.find(elem => elem.wagon_number == wagon.wagon_number && elem.containers[0]?.itu_number == wagon.containers[0]?.itu_number);
            if (!oldItem) {
              if (this._wagons?.length) {
                updates.push(wagon);
              }
              return wagon;
            }
            else {
              wagon.selected = oldItem.selected;
              if (oldItem.isDifferent(wagon)) {
                updates.push(wagon);
              }
              return wagon;
            }
          })
          //if (updates.length > 0) this.notificationService.notify(updates);
          this._isLoading = false;
          this._loadFailed = false;
          this._lastUpdated = moment().local().toDate();
          this.toggle();
        }
      },
      error: () => {
        this._loadFailed = true; 
        this._isLoading = false;
      }
    })
  }

  public setETA(wagons: Wagon[], date: string) {
    return this.http.put<any>(
      `${this.api.LOCATION}/wagons/eta`,
      { wagons: wagons.map(w => w.id), eta: date },
      this.headers.getOptions()
    )
  }

  public setComment(containers: Container[], comment: string) {
    return this.http.put<any>(
      `${this.api.LOCATION}/containers/comment`,
      { containers: containers.map(c => c.id), comment: comment },
      this.headers.getOptions()
    )
  }

  public endJourney(containers: Container[], date: string) {
    return this.http.put<any>(
      `${this.api.LOCATION}/containers/end-journey`,
      { containers: containers.map(c => c.id), end_journey_date: date },
      this.headers.getOptions()
    )
  }

  public deleteJourney(containers: Container[]) {
    return this.http.put<any>(
      `${this.api.LOCATION}/containers/delete-journey`,
      { containers: containers.map(c => c.id) },
      this.headers.getOptions()
    )
  }

  public getRegistrations(wagonId: number) {
    return this.http.get<any>(
      `${this.api.LOCATION}/registrations/${wagonId}`,
      this.headers.getOptions()
    )
  }
}
