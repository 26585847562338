import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Stakeholder } from 'src/app/classes/items';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { StringService } from 'src/app/services/string.service';
import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute, NavigationEnd, CanDeactivate } from '@angular/router';
import { StakeholderService } from 'src/app/services/stakeholder.service';
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Lane } from 'src/app/classes/items';
import { DialogAttachLaneComponent } from '../dialog-attach-lane/dialog-attach-lane.component';
import { Sort } from '@angular/material/sort';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { Contact } from 'src/app/classes/contact';
import { map } from 'rxjs/operators';
import { QueryParamService } from 'src/app/services/query-param.service';

@Component({
  selector: 'app-stakeholder',
  templateUrl: './stakeholder.component.html',
  styleUrls: ['./stakeholder.component.scss']
})
export class StakeholderComponent implements OnInit, CanDeactivate<unknown> {

  loading: boolean = false;
  stakeholder: Stakeholder = new Stakeholder();
  form: FormGroup;
  editId: number;
  headers: string[] = [
    'agreement_number', 'departure_station', 'arrival_station', 
    'access_track_and_trace', 'access_demurrage', 'lane_wagons', 
    'open_lane', 'delete'
  ]
  private stakeholderSub: Subscription;

  constructor(public strings: StringService,
              public data: DataService,
              private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private stakeholderService: StakeholderService,
              public dialog: MatDialog,
              private queryParamService: QueryParamService) {}

  ngOnInit(): void {
    this.setupForm();
    if (this.route.snapshot.paramMap.get('stakeholderId')) {
      try {
        this.editId = Number.parseInt(this.route.snapshot.paramMap.get('stakeholderId'));
        this.stakeholderSub = this.data.stakeholder(this.editId).subscribe((stakeholder: Stakeholder) => {
          this.stakeholder = stakeholder;
          this.setupForm(stakeholder);
        })
      } catch(e) { }
    }
  }

  ngOnDestroy() {
    if (this.stakeholderSub) this.stakeholderSub.unsubscribe();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.form.dirty;
  }

  private setupForm(stakeholder?: Stakeholder) {
    this.form = this.fb.group({
      name: new FormControl(stakeholder?.name || '', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      stakeholder_type: new FormControl(stakeholder?.stakeholder_type || null, [

      ])
    })
    if (stakeholder) {
      for (let contact of stakeholder.contacts) {
        this.form.addControl('contact_name_' + contact.id, new FormControl(contact?.name || '', [
          Validators.required,
          Validators.maxLength(50)
        ]))
        this.form.addControl('contact_email_' + contact.id, new FormControl(contact?.email.trim() || '', [
          Validators.required,
          Validators.email,
          Validators.maxLength(50)
        ]))
        this.form.addControl('contact_phone_number_' + contact.id, new FormControl(contact?.phone_number || '', [
          Validators.maxLength(20)
        ]))
        this.form.addControl('contact_portal_access_' + contact.id, new FormControl(contact?.portal_access, [
          Validators.required
        ]))
        this.form.addControl('contact_language_' + contact.id, new FormControl(contact?.language_id || 1, [
          Validators.required
        ]))
      }
    }
  }

  createStakeholder() {
    if (!this.form.valid) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['FILL_IN_REQUIRED'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
      return;
    }
    this.loading = true;
    let stakeholder = new Stakeholder({
      id: this.editId || null,
      name: this.form.value.name,
      stakeholder_type_id: this.form.value.stakeholder_type?.id || null
    });
    for (let contact of this.stakeholder.contacts) {
      stakeholder.contacts.push(
        new Contact({
          id: null,
          notifications: null,
          name: this.form.value['contact_name_' + contact.id],
          email: this.form.value['contact_email_' + contact.id],
          phone_number: this.form.value['contact_phone_number_' + contact.id],
          portal_access: this.form.value['contact_portal_access_' + contact.id],
          language_id: this.form.value['contact_language_' + contact.id]
        })
      )
    }
    if (this.editId) {
      this.stakeholderService.update(stakeholder).subscribe({
        next: () => {
          this.loading = false;
          this.form.markAsPristine();
          this.stakeholderService.clear();
          this.router.navigate(['/stakeholders'], {
            queryParams: this.queryParamService.previousQueryParams
          })
        },
        error: () => { this.loading = false; }
      })
    } else {
      this.stakeholderService.create(stakeholder).subscribe({
        next: () => {
          this.loading = false;
          this.form.markAsPristine();
          this.stakeholderService.clear();
          this.router.navigate(['/stakeholders'], {
            queryParams: this.queryParamService.previousQueryParams
          })
        },
        error: () => { this.loading = false; }
      })
    }
  }

  addContact() {
    if (this.stakeholder.contacts.length > 0) {
      let previousId = this.stakeholder.contacts[this.stakeholder.contacts.length - 1].id;
      if (previousId) {
        if (!this.form.controls['contact_name_' + previousId].valid || 
        !this.form.controls['contact_email_' + previousId].valid || 
        !this.form.controls['contact_phone_number_' + previousId].valid || 
        !this.form.controls['contact_portal_access_' + previousId].valid)
        {
          return;
        }
      }
    }
    let id = this.stakeholder.contacts.length + 1
    this.stakeholder.contacts.push(new Contact({
      id: id, name: '', email: '', portal_access: 0, language_id: 1

    }));
    this.form.addControl('contact_name_' + id, new FormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ]))
    this.form.addControl('contact_email_' + id, new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(50)
    ]))
    this.form.addControl('contact_phone_number_' + id, new FormControl('', [
      Validators.maxLength(20)
    ]))
    this.form.addControl('contact_portal_access_' + id, new FormControl(0, [
      Validators.required
    ]))
    this.form.addControl('contact_language_' + id, new FormControl(1, [
      Validators.required
    ]))
  }

  addLane() {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '850px';
    config.data = { id: this.editId }
    const dialog = this.dialog.open(DialogAttachLaneComponent, config);
    dialog.afterClosed().subscribe((lane: Lane) => {
      if (lane) {
        this.data.clearAll();
      }
    })
  }

  detachLane(lane: Lane) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['DELETE'] + ' ' + this.strings.appStrings['LANE'],
      message: this.strings.appStrings['DELETE'] + ' ' + lane.toString() + '?',
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.stakeholderService.detachLane(this.editId, lane.id).subscribe(() => {
          this.data.clearAll();
        })
      }
    })
  }

  removeContact(id: number) {
    this.form.markAsDirty();
    this.stakeholder.contacts.splice(this.stakeholder.contacts.findIndex(item => item.id === id), 1);
    this.form.removeControl('contact_name_' + id);
    this.form.removeControl('contact_email_' + id);
    this.form.removeControl('contact_phone_number_' + id);
    this.form.removeControl('contact_portal_access_' + id);
    this.form.updateValueAndValidity();
  }

  setAccess(lane: Lane, access: number) {
    this.stakeholderService.setLaneAccess(this.editId, lane.id, lane.access_track_and_trace, access).subscribe();
  }

  sortTable(sort: Sort) {
    const data = this.data.lanes.slice();
    if (!sort.active || sort.direction === '') {
      this.data.lanes = data;
      return;
    }
    this.data.lanes = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'agreement_number': return this.compare(a.agreement_number, b.agreement_number, isAsc);
        case 'departure_station': return this.compare(a.departure_station.name, b.departure_station.name, isAsc);
        case 'arrival_station': return this.compare(a.arrival_station.name, b.arrival_station.name, isAsc);
        case 'access_track_and_trace': return this.compare(a.access_track_and_trace, b.access_track_and_trace, isAsc);
        case 'access_demurrage': return this.compare(a.access_demurrage, b.access_demurrage, isAsc);
        default: return 0;
      }
    })
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openWagons(laneId) {
    this.router.navigate(['/wagons'], 
      {
        queryParams: { arrival_country: 'DK', lane: laneId },
        queryParamsHandling: 'merge'
      });
  }
}
