import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-dialog-edit-comment',
  templateUrl: './dialog-edit-comment.component.html',
  styleUrls: ['./dialog-edit-comment.component.scss']
})
export class DialogEditCommentComponent implements OnInit {

  @ViewChild('send', { static: false }) sendBtn: ElementRef;
  @ViewChild('cancel', { static: false }) yesBtn: ElementRef;
  data: any;
  comment: string;

  constructor(public strings: StringService,
              private dialogRef: MatDialogRef<DialogEditCommentComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
                this.data = data;
                this.comment = data.comment;
              }

  ngOnInit(): void {
  }

  actionSend() {
    this.dialogRef.close(this.comment);
  }

  actionDelete() {
    this.dialogRef.close('delete-comment');
  }

  actionCancel() {
    this.dialogRef.close();
  }
}
