import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoggedInService } from '../services/logged-in.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private loggedIn: LoggedInService,
              private cookie: CookieService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let accessToken = this.auth.getAccessToken();
      let hasToken: boolean = 
        (accessToken !== undefined && accessToken !== null && accessToken !== "") 
        || 
        (this.cookie.check('loggedin') && this.cookie.get('loggedin') === '1');
      
      if (hasToken) {
        return true;
      }
      this.loggedIn.toggle(false);
      this.router.navigateByUrl('/login');
      
      return false;
  }
  
}
