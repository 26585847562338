import { Component, HostListener } from '@angular/core';
import { StringService } from './services/string.service';
import { AuthService } from './services/auth.service';
import { LoggedInService } from './services/logged-in.service';
import { first, interval, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCookieConsentComponent } from './components/dialog/dialog-cookie-consent/dialog-cookie-consent.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { QueryParamService } from './services/query-param.service';
import { SwipeService } from './services/swipe.service';
import { Location } from '@angular/common';
import { UnderMaintenanceService } from './services/under-maintenance.service';
import { DataService } from './services/data.service';
import { OperationStatus } from './classes/operation-status';
import { DialogConfirmComponent, DialogConfirmData, DialogConfirmType } from './components/dialog/dialog-confirm/dialog-confirm.component';
import { OPERATION_STATUS } from './services/operation-status.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Wagon Information Portal';
  private validationIntervalSub: Subscription;
  private operationStatusIntervalSub: Subscription;
  private loggedInSub: Subscription;
  private displayedOperationStatusIds: number[] = [];
  private viewPort: string;
  private initialLoad: boolean = true;
  private initialLoadSub: Subscription;
  /*@HostListener('window:resize', ['$event'])
  onResize(event) {
    let vp = this.getViewport();
    if (this.viewPort != vp) {
      this.viewPort = vp;
    }
  }*/
  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
    this.swipeService.swipe(event, 'start')
  }
  @HostListener('touchend', ['$event']) onTouchEnd(event: TouchEvent) {
    this.swipeService.swipe(event, 'end')
  }

  constructor(private strings: StringService,
              private auth: AuthService,
              public loggedIn: LoggedInService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private queryParamService: QueryParamService,
              public swipeService: SwipeService,
              private router: Router,
              private location: Location,
              public underMaintenance: UnderMaintenanceService,
              private dataService: DataService) {}

  ngOnInit() {
    this.initialLoadSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigate([], { relativeTo: this.route, queryParamsHandling: 'merge', queryParams: {
          stakeholder: null,
          lane: null,
          receiver: null,
          location: null,
          type: null,
          manual_eta: null,
          arrival_station: null,
          departure_station: null,
          departure_country: null
        } });
        this.initialLoadSub.unsubscribe();
      }
    })
    
    this.strings.getStrings(this.strings.TYPE_CONNECTION);
    this.strings.getStrings(this.strings.TYPE_LANE);
    if (this.location.path().indexOf('create-password') == -1) {
      this.auth.validateSession();
    }
    const validationInterval = interval(1000 * 60 * 3);
    this.validationIntervalSub = validationInterval.subscribe(() => { 
      if (this.loggedIn.loggedIn) {
        this.auth.validateSession();
      }
    });
    const operationStatusInterval = interval(1000 * 60 * 30);
    this.operationStatusIntervalSub = operationStatusInterval.subscribe(() => {
      if (this.loggedIn.loggedIn) {
        this.checkNewOperationStatus();
      }
    })
    if (localStorage.getItem('COOKIES_ACCEPTED') !== '1') {
      const config = new MatDialogConfig();
      config.autoFocus = false;
      config.closeOnNavigation = true;
      config.width = '600px';
      config.disableClose = true;
      config.position = {
        bottom: '3%'
      }
      this.dialog.open(DialogCookieConsentComponent, config);
    }
    this.route.queryParams.subscribe((params) => {
      this.queryParamService.currentQueryParams = params;
    })
    this.loggedInSub = this.loggedIn.change.subscribe((loggedIn: boolean) => {

      if (!loggedIn) this.router.navigate(['/login'])
      else {
        this.checkNewOperationStatus();
      }
    })
  }

  private checkNewOperationStatus() {
    this.dataService.observeUnseenOperationStatus().subscribe((statuses: OperationStatus[]) => {
      if (statuses && statuses.length > 0) {
        const sorted = statuses.sort((a, b) => b.id - a.id);
        for (let status of sorted) {
          if (this.displayedOperationStatusIds.indexOf(status.id) === -1) {
            const config = new MatDialogConfig();
            config.width = '500px';
            const data: DialogConfirmData = {
              title: `${this.strings.appStrings['NEW_OPERATION_STATUS']}:\n${status.title}`,
              message: `${status.description}`,
              state: DialogConfirmType.OK_OPTION,
              hideContactInfo: false,
              linkText: this.strings.appStrings['SEE_MORE_OPERATION_STATUS'],
              linkRoute: '/operation-status'
            }
            config.data = data;
            const dialog = this.dialog.open(DialogConfirmComponent, config);
            dialog.afterClosed().subscribe(() => {
              localStorage.setItem(`${OPERATION_STATUS.PREFIX}${status.id}`, '1');
            })
            this.displayedOperationStatusIds.push(status.id);
          }
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.validationIntervalSub) this.validationIntervalSub.unsubscribe();
    if (this.loggedInSub) this.loggedInSub.unsubscribe();
    if (this.operationStatusIntervalSub) this.operationStatusIntervalSub.unsubscribe();
  }

  private getViewport() {
    let width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (width <= 576) return 'xs'
    if (width <= 768) return 'sm'
    if (width <= 992) return 'md'
    if (width <= 1200) return 'lg'
    if (width <= 1400) return 'xl'
    return 'xxl'
  }
}
