<div class="container text-center border">
    <h2 class="mt-4">Q&A for WIP Portal</h2>
    <h5 class="mt-5 red">1. What is the Portal about?</h5>
    <p>
        The Portal is a communication platform for our business partners. 
        The Portal comprises 2 modules and a notification system: a Track & Trace module and a archive module (past deliveries). 
        A notification system on operational disruptions provides you information about for instance delays.
    </p>
    <h5 class="mt-5 red">2. What is Track & Trace?</h5>
    <p>
        Track & Trace is the set of information determining the current and past locations 
        (and other information) of a specific delivery and an estimated arrival time.
    </p>
    <h5 class="mt-5 red">3. How do I benefit from using the Portal?</h5>
    <p>
        The portal provides you important information about current and past deliveries. 
        <b>You can seek information whenever you have time or need information!</b>
    </p>
    <h5 class="mt-5 red">4. How do I get access?</h5>
    <p>
        You write to 
        <a class="red" href="mailto:wipportal.support@deutschebahn.com">wipportal.support@deutschebahn.com</a> 
        and inform us of your name, phone number and e-mail address. Then we contact you for further steps.
    </p>
    <h5 class="mt-5 red">5. How do I change password?</h5>
    <p>
        You change password on the frontpage. 
        Click ”Create/change password”. You receive an e-mail. 
        Follow the link and you are guided to the portal to recreate your password.
    </p>
    <h5 class="mt-5 red">6. How do I get support?</h5>
    <p>
        You write to 
        <a class="red" href="mailto:wipportal.support@deutschebahn.com">wipportal.support@deutschebahn.com</a> 
        and we support you as quickly as possible.
    </p>
    <h5 class="mt-5 red">7. How often is the information updated?</h5>
    <p>
        The information in the Track & Trace module is updated whenever 
        a new event/change occurs on the single delivery 
        (change in position, Estimated Time of Arrival (ETA) etc.).
    </p>
    <h5 class="mt-5 red">8. For how long do you store data?</h5>
    <p>
        For the time being, we have no expiry date.
    </p>
    <h5 class="mt-5 red">9. How did you get my contact information?</h5>
    <p>
        Customer Service currently provides you with information in daily e-mails. 
        Therefore, we have your e-mail address enabling us to grant you access.
    </p>
    <h5 class="mt-5 red">10. Do you follow the GPDR rule</h5>
    <p>
        Yes of course. We only request use of functional cookies – this is for creating and maintaining your account only.
    </p>
    <h5 class="mt-5 red">11. Can I rely on the information</h5>
    <p>
        We have been very thorough in testing the data and we are convinced that the quality of the data is reliable.
    </p>
    <h5 class="mt-5 red">12. How do I delete my account</h5>
    <p>
        You can write to 
        <a class="red" href="mailto:wipportal.support@deutschebahn.com">wipportal.support@deutschebahn.com</a> 
        and we will delete your user account. 
        Alternatively you can delete your account under settings after logging in to the portal.
    </p>
    <h5 class="mt-5 red">13. How can I select what transport I would like to track?</h5>
    <p>
        For the moment you see all transports.
    </p>
    <h5 class="mt-5 red">14. Who decides who have access to my lanes/site</h5>
    <p>
        You inform us of the user’s e-mail address and we in DB Cargo grant access. 
        Write to 
        <a class="red" href="mailto:wipportal.support@deutschebahn.com">wipportal.support@deutschebahn.com</a> 
    </p>
    <h5 class="mt-5 red">15. How do you ensure only my company see my transports</h5>
    <p>
        This is easy. Your site is based on contract numbers including you only which are unique.
    </p>
</div>