import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subscription } from 'rxjs';
import { OperationStatus } from 'src/app/classes/operation-status';
import { DataService } from 'src/app/services/data.service';
import { PrivilegesService } from 'src/app/services/privileges.service';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-operation-status',
  templateUrl: './operation-status.component.html',
  styleUrls: ['./operation-status.component.scss']
})
export class OperationStatusComponent implements OnInit {

  public status: OperationStatus[] = null;
  private operationStatusSub: Subscription;

  constructor(public data: DataService,
              public strings: StringService,
              public privileges: PrivilegesService) { }

  ngOnInit(): void {
    this.operationStatusSub = this.data.observeOperationStatus().subscribe(() => {
      if (this.data.operationStatus) {
        this.status = this.data.operationStatus;
      } else this.status = [];
    })
  }

  ngOnDestroy() {
    this.operationStatusSub.unsubscribe();
  }

}
