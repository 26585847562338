import { IContact } from "../interfaces/i-contact";
import { Language } from "./language";

export class Contact {
    readonly WEEKLY = "NOTIFICATIONS_WEEKLY";
    readonly DAILY = "NOTIFICATIONS_DAILY";
    readonly HOURLY = "NOTIFICATIONS_HOURLY";
    readonly INSTANT = "NOTIFICATIONS_INSTANT";
    readonly NONE = "NOTIFICATIONS_NONE";

    private _id: number;
    private _name: string;
    private _email: string;
    private _phone_number: string;
    private _notifications: any = {};
    private _portal_access: boolean;
    private _language_id: number;
    private _language: Language;

    constructor(obj?: IContact) {
        if (!obj) return;
        this._id = obj.id;
        this._name = obj.name;
        this._email = obj.email;
        this._phone_number = obj.phone_number;
        this._notifications = obj.notifications || [];
        this._portal_access = (obj.portal_access == 1) ? true : false;
        this._language_id = obj.language_id;
        this._language = new Language(obj.language);
    }

    public notificationState(laneId: number): string {
        if (!this._notifications) return this.NONE;
        if (this._notifications[this.WEEKLY] && this._notifications[this.WEEKLY].indexOf(laneId) !== -1) return this.WEEKLY;
        if (this._notifications[this.DAILY] && this._notifications[this.DAILY].indexOf(laneId) !== -1) return this.DAILY;
        if (this._notifications[this.HOURLY] && this._notifications[this.HOURLY].indexOf(laneId) !== -1) return this.HOURLY;
        if (this._notifications[this.INSTANT] && this._notifications[this.INSTANT].indexOf(laneId) !== -1) return this.INSTANT;
        return this.NONE;
    }

    /**
     * Getter name
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter email
     * @return {string}
     */
	public get email(): string {
		return this._email;
	}

    /**
     * Getter phone_number
     * @return {string}
     */
	public get phone_number(): string {
		return this._phone_number;
    }
    
    /**
     * Getter send_notification
     * @return {any[]}
     */
	public get notifications(): any[] {
		return this._notifications;
    }
    
    /**
     * Getter portal_access
     * @return {boolean}
     */
	public get portal_access(): boolean {
		return this._portal_access;
	}

    /**
     * Getter language_id
     * @return {number}
     */
	public get language_id(): number {
		return this._language_id;
	}

    /**
     * Getter language
     * @return {Language}
     */
	public get language(): Language {
		return this._language;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}
    
    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter email
     * @param {string} value
     */
	public set email(value: string) {
		this._email = value;
	}

    /**
     * Setter phone_number
     * @param {string} value
     */
	public set phone_number(value: string) {
		this._phone_number = value;
    }

    /**
     * Setter send_notification
     * @param {any[]} value
     */
	public set notifications(value: any[]) {
		this._notifications = value;
    }
    
    /**
     * Setter portal_access
     * @param {boolean} value
     */
	public set portal_access(value: boolean) {
		this._portal_access = value;
	}

    /**
     * Setter language_id
     * @param {number} value
     */
	public set language_id(value: number) {
		this._language_id = value;
	}

    /**
     * Setter language
     * @param {Language} value
     */
	public set language(value: Language) {
		this._language = value;
	}
    
    public toString(): string {
        return this._name + ' ' + this._email || '' +  ' ' + this._phone_number || '';
    }

}
