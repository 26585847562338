<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{strings.appStrings['EDIT_COMMENT']}}</h2>
    <span style="font-size: small;" innerHTML="{{data.message}}" mat-dialog-content></span>

    <span style="font-size: small;width:100%;" mat-dialog-content>
        <textarea maxlength="120" id="comment" [(ngModel)]="comment"></textarea>
    </span>

    <mat-dialog-actions>
        <button #send class="mat-raised-button mat-primary p-2" (click)="actionSend()">{{strings.appStrings['SAVE']}}</button>
        <button *ngIf="data.comment" #delete class="mat-raised-button p-2 ms-2" (click)="actionDelete()">{{strings.appStrings['DELETE']}}</button>
        <button #cancel class="mat-raised-button p-2 ms-2" (click)="actionCancel()">{{strings.appStrings['CANCEL']}}</button>
    </mat-dialog-actions>    
</div>