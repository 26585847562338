<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{strings.appStrings['IMPORT']}} {{strings.appStrings['LANES']}}</h2>

    <mat-dialog-content>
        <div class="w-100 text-center" *ngIf="importStatus == IMPORT_SUCCESS || importStatus == IMPORT_FAILURE">
            <span>of {{dataSource?.data?.length}}</span>
        </div>
        
        <div class="w-100 text-center">
            <input 
            type="file" 
            accept=".xls,.xlsx"
            class="mat-raised-button mat-primary p-1" 
            (change)="importFromExcel($event.target.files)"/>
        </div>

        <div class="w-100 text-center" style="font-size: small;color: red;" *ngIf="importStatus == IMPORT_DATA_ERRORS">
            <span>{{strings.appStrings['IMPORT_ROW_ERRORS']}}</span>
        </div>

        <table
            *ngIf="dataSource.data.length > 0"
            mat-table
            [dataSource]="dataSource"
            class="table table-light table-striped">

            <ng-container matColumnDef="agreement_number">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    class="p-1">
                    {{strings.appStrings['AGREEMENT_NUMBER']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.agreement_number}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="departure_station_name">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['DEPARTURE_STATION_SHORT']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.departure_station?.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="departure_station_dium">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['DIUM']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.departure_station?.country_code}}{{lane.departure_station?.station_id}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="arrival_station_name">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['ARRIVAL_STATION_SHORT']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.arrival_station?.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="arrival_station_dium">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['DIUM']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.arrival_station?.country_code}}{{lane.arrival_station?.station_id}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="stakeholders">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['STAKEHOLDERS']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span *ngFor="let stakeholder of lane.stakeholders">
                        {{stakeholder.name}}<br>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span *ngIf="lane.import_errors?.length" class="material-icons" matTooltip="{{lane.import_errors?.join('&#13;')}}">error</span>
                    <span *ngIf="!lane.import_errors?.length">
                        <span
                            *ngIf="lane.import_status == 1"
                            class="material-icons btn-icon">
                            check_circle
                        </span>
                        <span
                            *ngIf="lane.import_status == -1"
                            class="material-icons btn-icon">
                            cancel
                        </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span *ngFor="let stakeholder of lane.stakeholders">
                        {{stakeholder.name}}
                        <span *ngFor="let contact of stakeholder.contacts">
                            {{contact.email}}
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
            <tr
                mat-row 
                style="height: auto;"
                *matRowDef="let row; columns: headers"
                matRipple
                class="element-row"
                [class.expanded]="expandedElement == row"
                (click)="expandedElement = row">
            </tr>
            <tr 
                mat-row 
                *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
                style="overflow: hidden"></tr> 
        </table>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button *ngIf="importStatus != IMPORT_SUCCESS" class="mat-raised-button mat-primary p-1" [disabled]="importStatus != IMPORT_READY && importStatus != IMPORT_FAILURE" (click)="startImport()">
            {{strings.appStrings['IMPORT']}}
        </button>
        <button class="mat-raised-button p-1 ms-2" (click)="close()" [disabled]="importStatus == IMPORT_LOADING">
            {{strings.appStrings['CLOSE']}}
        </button>
    </mat-dialog-actions>
</div>