import { EventEmitter, Injectable, Output } from '@angular/core';
import { Stakeholder } from '../classes/items';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { StakeholderType } from '../classes/stakeholder-type';
import { IStakeholderType } from '../interfaces/i-stakeholder-type';

@Injectable({
  providedIn: 'root'
})
export class StakeholderTypeService {

  private _stakeholderTypes: StakeholderType[] = null;
  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService) { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get stakeholderTypes(): StakeholderType[] {
    if (!this._stakeholderTypes && !this._isLoading && !this._loadFailed) {
      this.get();
    }
    return this._stakeholderTypes;
  }

  public set stakeholderTypes(stakeholder_types: StakeholderType[]) {
    this._stakeholderTypes = stakeholder_types;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public clear() {
    this._stakeholderTypes = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  public get() {
    this._isLoading = true;
    this.http.get<IStakeholderType>(
      `${this.api.LOCATION}/stakeholder-types`,
      this.header.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          this.stakeholderTypes = [];
          for (let elem of response) {
            this._stakeholderTypes.push(new StakeholderType(elem));
          }
          this._isLoading = false;
          this._loadFailed = response.length === 0;
          this.toggle();
        }
      },
      error: () => {
        this._isLoading = false;
        this._loadFailed = true;
      }
    })
  }
}
