<div class="m-2" style="text-align: center;">
    <div *ngIf="data.isLoadingLanes" class="spinner-border text-primary mt-3"></div>

    <div class="mat-elevation-z8 table-response-md" style="text-align: start;">

        <mat-accordion>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                <mat-panel-title>
                    {{strings.appStrings['FILTER']}}
                    <mat-icon>filter_alt</mat-icon>
                </mat-panel-title>
                <mat-panel-description>
                    <strong>{{strings.appStrings['ACTIVE_FILTERS']}}:</strong>
                    &nbsp;
                    <span *ngIf="filterService.lane.departure_country">
                        {{strings.appStrings['DEPARTURE_COUNTRY']}}:
                        {{filterService.lane.departure_country}}
                        <span *ngIf="filterService.lane.arrival_country">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.lane.arrival_country">
                        {{strings.appStrings['ARRIVAL_COUNTRY']}}:
                        {{filterService.lane.arrival_country}}
                        &nbsp;
                    </span>
                </mat-panel-description>
                </mat-expansion-panel-header>

                <section>
                    <mat-form-field>
                        <mat-label>{{strings.appStrings['DEPARTURE_COUNTRY']}}</mat-label>
                        <mat-select [(ngModel)]="filterService.lane.departure_country">
                            <mat-option [value]="null"></mat-option>
                            <mat-option *ngFor="let country of data.countries | async" [value]="country">{{country}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                
                    <mat-form-field class="ms-2">
                        <mat-label>{{strings.appStrings['ARRIVAL_COUNTRY']}}</mat-label>
                        <mat-select [(ngModel)]="filterService.lane.arrival_country">
                            <mat-option [value]="null"></mat-option>
                            <mat-option *ngFor="let country of data.countries | async" [value]="country">{{country}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>

                <button type="button" class="mat-raised-button mat-primary p-1" (click)="exportToExcel()">
                    {{strings.appStrings['EXPORT']}}
                </button>

                <button type="button" class="mat-raised-button mat-primary p-1 ms-2" (click)="openImportDialog()">
                    {{strings.appStrings['IMPORT']}}
                </button>
            </mat-expansion-panel>
        </mat-accordion>

        <div 
            *ngIf="laneService.isLoading" 
            style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner 
                color="primary" 
                mode="indeterminate">
            </mat-progress-spinner>
        </div>

        <table
            mat-table
            matSort
            (matSortChange)="sortTable($event)"
            [dataSource]="dataSource"
            matSortActive="agreement_number"
            matSortDirection="desc"
            class="table table-light table-striped table-hover">
        
            <ng-container matColumnDef="agreement_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="agreement_number" class="p-1">{{strings.appStrings['AGREEMENT_NUMBER']}}</th>
                <td mat-cell *matCellDef="let lane" class="p-1 text-right">
                    <span>{{lane.agreement_number}}</span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="departure_station_name">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header="departure_station_name" 
                    class="p-1"
                    style="width: 15%;">
                    {{strings.appStrings['DEPARTURE_STATION']}} {{strings.appStrings['NAME']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.departure_station?.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="departure_station_station_id">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header="departure_station_station_id" 
                    class="p-1"
                    style="width: 8%;">{{strings.appStrings['DEPARTURE_STATION_SHORT']}} {{strings.appStrings['DIUM']}}</th>
                <td mat-cell *matCellDef="let lane" class="p-1 text-right">
                    <span>{{lane.departure_station?.country_code}}{{lane.departure_station?.station_id}}</span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="arrival_station_name">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header="arrival_station_name" 
                    class="p-1"
                    style="width:15%">
                    {{strings.appStrings['ARRIVAL_STATION']}} {{strings.appStrings['NAME']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.arrival_station?.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="arrival_station_station_id">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header="arrival_station_station_id" 
                    class="p-1"
                    style="width: 8%">
                    {{strings.appStrings['ARRIVAL_STATION_SHORT']}} {{strings.appStrings['DIUM']}}
                </th>
                <td mat-cell *matCellDef="let lane" class="p-1 text-right">
                    <span>{{lane.arrival_station?.country_code}}{{lane.arrival_station?.station_id}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="has_stakeholders">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="has_stakeholders" class="p-1">{{strings.appStrings['STAKEHOLDERS']}}</th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span *ngIf="lane.stakeholders?.length > 0">
                        {{strings.appStrings['YES']}}
                    </span>
                    <span *ngIf="lane.stakeholders?.length <= 0">
                        {{strings.appStrings['NO']}}
                    </span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="track">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="track" class="p-1">{{strings.appStrings['TRACK']}}</th>
                <td mat-cell *matCellDef="let lane" class="p-1">
                    <span>{{lane.track}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="sender" class="p-1">{{strings.appStrings['SENDER']}}</th>
                <td mat-cell *matCellDef="let lane" class="p-1 truncate-cell" matTooltip="{{lane.sender?.name}}">
                    <span>{{lane.sender?.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="receiver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="receiver" class="p-1">{{strings.appStrings['RECEIVER']}}</th>
                <td mat-cell *matCellDef="let lane" class="p-1 truncate-cell" matTooltip="{{lane.receiver?.name}}">
                    <span>{{lane.receiver?.name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="lane_wagons">
                <th mat-header-cell *matHeaderCellDef class="edit-column p-1"></th>
                <td mat-cell *matCellDef="let lane" class="edit-column p-1">
                    <span 
                        class="material-icons edit" 
                        (click)="searchService.clear(); filterService.wagon.stakeholder = null;"
                        matTooltip="{{strings.appStrings['WAGONS']}}"
                        routerLink="/wagons"
                        [queryParams]="{lane: lane.id}">
                        train
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef class="edit-column p-1"></th>
                <td mat-cell *matCellDef="let lane" class="edit-column p-1">
                    <span 
                        class="material-icons edit" 
                        routerLink="/lane/{{lane.id}}"
                        matTooltip="{{strings.appStrings['EDIT']}}">
                        edit
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef class="delete-column p-1"></th>
                <td mat-cell *matCellDef="let lane" class="delete-column p-1">
                    <span 
                        class="material-icons delete" 
                        (click)="deleteLane(lane)"
                        matTooltip="{{strings.appStrings['DELETE']}}">
                        delete
                    </span>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
            <tr
                mat-row style="height: auto;"
                *matRowDef="let row; columns: headers"></tr>
        </table>

        <mat-paginator 
            #paginator
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[10, 20, 30, 40, 50]"
            (page)="onPageChange($event)">
        </mat-paginator>
    </div>
</div>