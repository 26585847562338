import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { Lane } from '../classes/items';
import { FilterService } from './filter.service';
import { ILane } from '../interfaces/i-lane';

@Injectable({
  providedIn: 'root'
})
export class LaneService {

  private _lanes: Array<Lane> = null;
  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;
  private _filtered: Array<Lane> = [];

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService,
              private filterService: FilterService) {
                this.filterService.changeLane.subscribe(() => {
                  if (!this._isLoading && !this._loadFailed) {
                    this.get(
                      this.filterService.getParams(
                        this.filterService.lane
                      )
                    )
                  }
                })
              }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get lanes(): Array<Lane> {
    if (!this._lanes && !this._isLoading && !this._loadFailed) {
      this.get(
        this.filterService.getParams(
          this.filterService.lane
        )
      );
    }
    return this._lanes;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get filtered(): Array<Lane> {
    return this._filtered;
  }

  public set lanes(lanes: Array<Lane>) {
    this._lanes = lanes;
  }

  public clear() {
    this._lanes = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  private get(params?: string) {
    this._isLoading = true;
    this.http.get<ILane[]>(
      `${this.api.LOCATION}/lanes?${params}`,
      this.header.getOptions()
    )
    .subscribe(
      response => {
        if (response && response instanceof Array) {
          this.lanes = [];
          for (let elem of response) {
            this._lanes.push(new Lane(elem));
          }
          this._isLoading = false;
          this._loadFailed = response.length === 0;
          this.toggle();
        }
      }
    ),
    () => {
      this._loadFailed; 
      this._isLoading = false;
    }
  }

  public create(lane: Lane) {
    return this.http.post<any>(
      `${this.api.LOCATION}/lanes`,
      { lane: lane.toJSON() },
      this.header.getOptions()
    )
  }

  public update(lane: Lane) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lanes`,
      { lane: lane.toJSON() },
      this.header.getOptions()
    )
  }

  public delete(lane: Lane) {
    return this.http.delete<any>(
      `${this.api.LOCATION}/lanes/${lane.id}`,
      this.header.getOptions()
    )
  }

  public attachStakeholder(laneId: number, stakeholderId: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lane-stakeholder/attach`,
      { lane_id: laneId, stakeholder_id: stakeholderId },
      this.header.getOptions()    
    )
  }

  public detachStakeholder(laneId: number, stakeholderId: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lane-stakeholder/detach`,
      { lane_id: laneId, stakeholder_id: stakeholderId },
      this.header.getOptions()
    )
  }

  public setStakeholderAccess(laneId: number, stakeholderId: number, accessTrackAndTrace: number, accessDemurrage: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lane-stakeholder/access`,
      { lane_id: laneId, stakeholder_id: stakeholderId, access_track_and_trace: accessTrackAndTrace, access_demurrage: accessDemurrage },
      this.header.getOptions()
    )
  }
}
