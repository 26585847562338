import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteConfigLoadStart } from '@angular/router';
import { StringService } from 'src/app/services/string.service';

export enum DialogConfirmType {
  OK_OPTION = 'OK_OPTION',
  YES_NO_OPTION = 'YES_NO_OPTION'
}

export interface DialogConfirmData {
  title: string;
  message: string;
  state: DialogConfirmType;
  hideContactInfo?: false;
  linkText?: string;
  linkRoute?: string;
}

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

  @ViewChild('ok', { static: false }) okBtn: ElementRef;
  @ViewChild('yes', { static: false }) yesBtn: ElementRef;
  data: DialogConfirmData;

  constructor(public strings: StringService,
              private dialogRef: MatDialogRef<DialogConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) data: DialogConfirmData) {
                this.data = data;
              }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.okBtn) this.okBtn.nativeElement.focus();
    else if (this.yesBtn) this.yesBtn.nativeElement.focus();
  }

  actionYes() {
    this.dialogRef.close(true);
  }

  actionNo() {
    this.dialogRef.close(false);
  }

  actionOk() {
    this.dialogRef.close();
  }
}
