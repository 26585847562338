import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { StringService } from 'src/app/services/string.service';
import { SearchService } from 'src/app/services/search.service';
import { Sort } from '@angular/material/sort';
import { StakeholderService } from 'src/app/services/stakeholder.service';
import { Stakeholder } from 'src/app/classes/items';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-stakeholders',
  templateUrl: './stakeholders.component.html',
  styleUrls: ['./stakeholders.component.scss']
})
export class StakeholdersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  headers = ['name', 'contact_name', 'contact_email', 'contact_phone_number', 'lane_amount', 'stakeholder_wagons', 'edit', 'delete'];
  private inputSub: Subscription;
  private stakeholderSub: Subscription;
  dataSource = new MatTableDataSource<Stakeholder>();
  @HostListener('document:keydown', ['$event'])
    handleKeyBoardEvent(event: KeyboardEvent) {
      switch (event.code) {
        case 'ArrowRight':
          this.paginator?.nextPage();
          break;
        case 'ArrowLeft':
          this.paginator?.previousPage();
          break;
      }
    }

  constructor(public data: DataService,
              public strings: StringService,
              public searchService: SearchService,
              public stakeholderService: StakeholderService,
              public dialog: MatDialog,
              public filterService: FilterService,
              private router: Router,
              private route: ActivatedRoute) {
                this.dataSource = new MatTableDataSource<Stakeholder>([]);
              }

  ngOnInit() {
    this.inputSub = this.searchService.change.subscribe((value) => {
      this.dataSource.filter = value;
    })
    this.strings.change.subscribe(() => {
      if (this.dataSource.paginator) {
        this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
      }
    })
  }

  ngOnDestroy() {
    this.inputSub.unsubscribe();
    this.stakeholderSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
    this.dataSource.filter = this.searchService.searchString;
    this.stakeholderSub = this.data.observeStakeholders().subscribe(() => {
      if (this.data.stakeholders) {
        this.dataSource.data = this.data.stakeholders;
        if (this.route.snapshot.queryParamMap.get('sort')) {
          this.sortTable({
            active: this.route.snapshot.queryParamMap.get('sort'),
            direction: (this.route.snapshot.queryParamMap.get('direction') === 'desc') ? 'desc' : 'asc'
          })
        } else {
          this.sortTable({ active: 'name', direction: 'asc' })
        }
        if (this.route.snapshot.queryParamMap.get('page') && this.dataSource.paginator) {
          try {
            this.dataSource.paginator.pageIndex = Number.parseInt(this.route.snapshot.queryParamMap.get('page'));
          } catch (e) {}
        }
      } else this.dataSource.data = [];
      
    })
  }

  sortTable(sort: Sort) {
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: { sort: sort.active, direction: sort.direction },
        queryParamsHandling: 'merge'
      })
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'contact_name': return this.compare(a.contacts[0]?.name, b.contacts[0]?.name, isAsc);
        case 'contact_email': return this.compare(a.contacts[0]?.email, b.contacts[0]?.email, isAsc);
        case 'contact_phone_number': return this.compare(a.contacts[0]?.phone_number, b.contacts[0]?.phone_number, isAsc);
        case 'lane_amount': return this.compare(a.lanes?.length, b.lanes?.length, isAsc);
        default: return 0;
      }
    })
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteStakeholder(stakeholder: Stakeholder) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['DELETE'] + ' ' + this.strings.appStrings['STAKEHOLDER'],
      message: this.strings.appStrings['DELETE'] + ' ' + stakeholder.name + '?',
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.stakeholderService.delete(stakeholder).subscribe(() => {
          this.data.clearAll();
        })
      }
    })
  }

  onPageChange(event: PageEvent) {
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: { page: event.pageIndex },
        queryParamsHandling: 'merge'
      })
  }

  exportToExcel()
  {
    let data = this.dataSource.data.map((elem) => {
      let idx = 0;
      let contacts = elem.contacts.map((contact) => {
        idx++;
        let keyName = "Contact_Name_" + idx;
        let keyEmail = "Contact_Email_" + idx;
        let keyPhone = "Contact_Phone_" + idx;
        let keyPortalAccess = "Contact_Portal_Access_" + idx;
        let contactObj = {};
        contactObj[keyName] = contact.name;
        contactObj[keyEmail] = contact.email;
        contactObj[keyPhone] = contact.phone_number;
        contactObj[keyPortalAccess] = contact.portal_access;
        return contactObj;
      })
      contacts = Object.assign({}, ...contacts);
      let stakeholder = { Name: elem.name }
      return { ...stakeholder, ...contacts };
    })
    if (data.length == 0) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.height = '300px';
      config.data = {
        title: this.strings.appStrings['AN_ERROR_OCCURRED'],
        message: this.strings.appStrings['NO_DATA_TO_EXPORT'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'WIP_EXPORT');
    XLSX.writeFile(wb, `WIP_STAKEHOLDER_EXPORT_${moment().local().format('YYYY_MM_DD_HH_MM_SS')}.xlsx`);
  }
}
