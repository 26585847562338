<div class="dialog-container w-100 h-100" style="position:relative;">
    <h2 mat-dialog-title style="white-space: pre-wrap;" innerHTML="{{data.title}}"></h2>
    <span style="font-size: small;white-space: pre-wrap;" innerHTML="{{data.message}}" mat-dialog-content>
        
        <br><br>
    </span>
    <span *ngIf="data.linkText && data.linkRoute" style="font-size: small;">
        <span *ngIf="data.linkText && data.linkRoute">
            <a style="color:blue" routerLink="{{data.linkRoute}}" (click)="actionOk()">{{data.linkText}}</a>
        </span>
    </span>

    <div style="position:relative;bottom:0;width:100%;">
        <div>
            <mat-dialog-actions>
                <div *ngIf="data.state==='YES_NO_OPTION'">
                    <button #yes class="mat-raised-button mat-primary p-2" (click)="actionYes()">{{strings.appStrings['YES']}}</button>
                    <button #no class="mat-raised-button p-2" (click)="actionNo()">{{strings.appStrings['NO']}}</button>
                </div>
                <div *ngIf="data.state==='OK_OPTION'">
                    <button #ok class="mat-raised-button mat-primary p-2" (click)="actionOk()">{{strings.appStrings['OK'] || 'OK'}}</button>
                </div>
            </mat-dialog-actions>
        </div>
        <div style="font-size: small;margin-top: 20px">
            <span>
                {{strings.appStrings['CONTACT_ON_ERROR']}} 
                <a style="color:blue" href="mailto:wipportal.support@deutschebahn.com">wipportal.support@deutschebahn.com</a>
            </span>
        </div>
    </div>
</div>