import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/classes/contact';
import { Lane, Stakeholder } from 'src/app/classes/items';
import { StakeholderType } from 'src/app/classes/stakeholder-type';
import { DataService } from 'src/app/services/data.service';
import { LaneService } from 'src/app/services/lane.service';
import { StakeholderService } from 'src/app/services/stakeholder.service';
import { StringService } from 'src/app/services/string.service';
import * as XLSX from 'xlsx';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';

export interface LaneImportRow {
  KA_Number: string;
  Depart_Station?: string;
  Depart_Station_DIUM: string;
  Arrival_Station?: string;
  Arrival_Station_DIUM: string;
  Consigner_Name?: string;
  Consigner_Customer_No?: string;
  Consignee_Name?: string;
  Consignee_Customer_No?: string;
  Wagon_Receiver_Name_1?: string;
  Wagon_Receiver_Email_1?: string;
  Wagon_Receiver_Name_2?: string;
  Wagon_Receiver_Email_2?: string;
  Wagon_Receiver_Name_3?: string;
  Wagon_Receiver_Email_3?: string;
  Last_Mile_Warehouse_Name_1?: string;
  Last_Mile_Warehouse_Email_1?: string;
  Last_Mile_Warehouse_Name_2?: string;
  Last_Mile_Warehouse_Email_2?: string;
  Last_Mile_Warehouse_Name_3?: string;
  Last_Mile_Warehouse_Email_3?: string;
  Goods_Recipient_Name_1?: string;
  Goods_Recipient_Email_1?: string;
  Goods_Recipient_Name_2?: string;
  Goods_Recipient_Email_2?: string;
  Goods_Recipient_Name_3?: string;
  Goods_Recipient_Email_3?: string;
  Goods_Sender_Name_1?: string;
  Goods_Sender_Email_1?: string;
  Goods_Sender_Name_2?: string;
  Goods_Sender_Email_2?: string;
  Goods_Sender_Name_3?: string;
  Goods_Sender_Email_3?: string;
  Other_Stakeholder_Name_1?: string;
  Other_Stakeholder_Email_1?: string;
  Other_Stakeholder_Name_2?: string;
  Other_Stakeholder_Email_2?: string;
  Other_Stakeholder_Name_3?: string;
  Other_Stakeholder_Email_3?: string;
}

@Component({
  selector: 'app-dialog-import-lanes',
  templateUrl: './dialog-import-lanes.component.html',
  styleUrls: ['./dialog-import-lanes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DialogImportLanesComponent implements OnInit {

  readonly IMPORT_AWAITING = 0;
  readonly IMPORT_DATA_ERRORS = 1;
  readonly IMPORT_READY = 2;
  readonly IMPORT_LOADING = 3;
  readonly IMPORT_SUCCESS = 4;
  readonly IMPORT_FAILURE = 5;
  public headers: string[] = [
    'agreement_number', 'departure_station_name', 'departure_station_dium', 
    'arrival_station_name', 'arrival_station_dium', 'stakeholders', 'status'
  ];
  private stakeholderSub: Subscription;
  private stakeholderTypeSub: Subscription;
  private stakeholderTypes: StakeholderType[];
  private stakeholders: Stakeholder[];
  public dataSource = new MatTableDataSource<Lane>();
  public importStatus: number = this.IMPORT_AWAITING;
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

  constructor(private dialog: MatDialog,
              public strings: StringService,
              private data: DataService,
              private dialogRef: MatDialogRef<DialogImportLanesComponent>,
              private laneService: LaneService,
              private stakeholderService: StakeholderService) { }

  ngOnInit(): void {
    this.stakeholderSub = this.data.observeStakeholders().subscribe(() => {
      this.stakeholders = this.data.stakeholders;
    })
    this.stakeholderTypeSub = this.data.stakeholderTypes.subscribe((stakeholderTypes) => {
      this.stakeholderTypes = stakeholderTypes;
    })
  }

  ngOnDestroy() {
    if (this.stakeholderSub) this.stakeholderSub.unsubscribe();
    if (this.stakeholderTypeSub) this.stakeholderTypeSub.unsubscribe();
  }

  importFromExcel(files: FileList) {
    try {
      const file = files.item(0);
      file.arrayBuffer().then((arrayBuffer) => {
        const arr = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(arr, { type: 'array' })
        const data: LaneImportRow[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        this.validateRawImportData(data);
        const lanes = this.convertImportData(data);
        this.validateLanes(lanes);
        this.dataSource.data = lanes;

        this.importStatus = this.IMPORT_READY;
      })
      .catch((e) => {
        this.showErrorDialog(e);
      })      
    } catch (e) {
      this.showErrorDialog(e);
      return;
    }
  }

  async startImport() {
    this.importStatus = this.IMPORT_LOADING
    const promises = [];
    for (let lane of this.dataSource.data) {
      if (lane.import_errors.length == 0) {
        promises.push(
          this.laneService.create(lane).toPromise().then((res) => {
            if (res && res.insert_id) {
              const innerPromises = [];
              for (let stakeholder of lane.stakeholders) {
                if (stakeholder.id) {
                  innerPromises.push(
                    this.laneService.attachStakeholder(res.insert_id, stakeholder.id)
                    .toPromise()
                    .catch((e) => { throw e })
                  )
                  return Promise.all(innerPromises).then(() => {
                    lane.import_status = 1;
                  })
                  .catch((e) => {
                    lane.import_status = -1;
                    lane.import_errors.push(e);
                  })
                } else {
                  innerPromises.push(
                    this.stakeholderService.create(stakeholder).toPromise().then((stakeholderRes) => {
                      if (stakeholderRes && stakeholderRes.insert_id) {
                        return this.laneService.attachStakeholder(res.insert_id, stakeholderRes.insert_id)
                        .toPromise()
                        .catch((e) => { throw e })
                      }
                    })
                  )
                }
              }
            }
          })
          .catch((e) => { throw e })
        )
      }
    }
    return Promise.all(promises)
    .then(() => {
      this.importStatus = this.IMPORT_SUCCESS;
    })
    .catch((e) => {
      this.importStatus = this.IMPORT_FAILURE;
    })
  }

  private showErrorDialog(message: string) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.height = '300px';
    config.data = {
      title: this.strings.appStrings['AN_ERROR_OCCURRED'],
      message: message,
      state: 'OK_OPTION'
    };
    this.dialog.open(DialogConfirmComponent, config);
  }

  private validateRawImportData(data: LaneImportRow[]): boolean {
    if (!data || data instanceof Array == false || data.length == 0) {
      throw new Error("Did not find any rows in data");
    }
    for (let row of data) {
      if (!row.KA_Number) throw new Error("'KA_Number' column is missing");
      //if (!row['Afgangsstation']) throw new Error("'Afgangsstation' column is missing");
      if (!row.Depart_Station_DIUM) throw new Error("'Departure_Station_DIUM' column is missing");
      //if (!row['Ankomstsstation']) throw new Error("'Ankomstsstation' column is missing");
      if (!row.Arrival_Station_DIUM) throw new Error("'Arrival_Station_DIUM' column is missing");
      /*if (!row['Afsender Navn']) throw new Error("'Afsender Navn' column is missing");
      if (!row['Afsender Reference']) throw new Error("'Afsender Reference' column is missing");
      if (!row['Modtager Navn']) throw new Error("'Modtager Navn' column is missing");
      if (!row['Modtager Reference']) throw new Error("'Modtager Reference' column is missing");
      if (!row['Vognmodtager navn']) throw new Error("'Vognmodtager navn' column is missing");
      if (!row['Last Mile Warehouse navn']) throw new Error("'Last Mile Warehouse navn' column is missing");
      if (!row['Gods Modtager navn']) throw new Error("'Gods Modtager navn' column is missing");
      if (!row['Gods Afsender navn']) throw new Error("'Gods Afsender navn' column is missing");
      if (!row['Andre Interessenter navn 1']) throw new Error("'Andre Interessenter navn 1' column is missing");
      if (!row['Andre Interessenter navn 2']) throw new Error("'Andre Interessenter navn 2' column is missing");
      if (!row['Andre Interessenter navn 3']) throw new Error("'Andre Interessenter navn 3' column is missing");*/
    }
    return true;
  }

  private validateLanes(lanes: Lane[]) {
    for (let lane of lanes) {
      let duplicates = lanes.filter(l => {
        return l.agreement_number == lane.agreement_number &&
          l.departure_station.country_code == lane.departure_station.country_code && 
          l.departure_station.station_id == lane.departure_station.station_id && 
          l.arrival_station.country_code == lane.arrival_station.country_code && 
          l.arrival_station.station_id == lane.arrival_station.station_id && 
          l.receiver.customer_number == lane.receiver.customer_number
      });
      if (duplicates.length > 1) {
        lane.import_errors.push("This lane exists in mutiple rows of the import");
        this.importStatus = this.IMPORT_DATA_ERRORS;
      }
      let alreadyExists = this.data.lanes.findIndex(l => {
        let found = l.agreement_number == lane.agreement_number &&
          l.departure_station.country_code == lane.departure_station.country_code && 
          l.departure_station.station_id == lane.departure_station.station_id && 
          l.arrival_station.country_code == lane.arrival_station.country_code && 
          l.arrival_station.station_id == lane.arrival_station.station_id
        if (found && l.is_receiver_id_key) {
          found = (!l.receiver?.customer_number && !lane.receiver?.customer_number) || l.receiver?.customer_number == lane.receiver?.customer_number;
        }
        return found;
      })
      if (alreadyExists != -1) {
        lane.import_errors.push("This lane already exists in the system");
        this.importStatus = this.IMPORT_DATA_ERRORS;
      }
    }
  }

  private convertImportData(data: LaneImportRow[]): Lane[] {
    const lanes: Lane[] = [];
    for (let row of data) {
      const departureStationCode = row.Depart_Station_DIUM.toString().slice(2);
      const departureStationCountryCode = row.Depart_Station_DIUM.toString().slice(0, 2);
      const arrivalStationCode = row.Arrival_Station_DIUM.toString().slice(2);
      const arrivalStationCountryCode = row.Arrival_Station_DIUM.toString().slice(0, 2);
      const departureStation = this.data.stations.find(s => {
        return s.station_id == departureStationCode && s.country_code == departureStationCountryCode
      })
      const arrivalStation = this.data.stations.find(s => {
        return s.station_id == arrivalStationCode && s.country_code == arrivalStationCountryCode
      })
      const lane = new Lane(
        {
          id: null,
          agreement_number: row.KA_Number,
          departure_station_id: departureStation?.id || null,
          departure_station: departureStation || {
            id: null,
            station_id: row.Depart_Station_DIUM.toString().slice(2),
            name: row.Depart_Station,
            country_code: row.Depart_Station_DIUM.toString().slice(0, 2)
          },
          arrival_station_id: arrivalStation?.id || null,
          arrival_station: arrivalStation || {
            id: null,
            station_id: row.Arrival_Station_DIUM.toString().slice(2),
            name: row.Arrival_Station,
            country_code: row.Arrival_Station_DIUM.toString().slice(0, 2)
          },
          sender: {
            id: null,
            name: row.Consigner_Name,
            reference: null,
            customer_number: row.Consigner_Customer_No ? Number.parseInt(row.Consigner_Customer_No) : null
          },
          receiver: {
            id: null,
            name: row.Consignee_Name,
            reference: null,
            customer_number: row.Consignee_Customer_No ? Number.parseInt(row.Consignee_Customer_No) : null
          }
        }
      )
      lane.import_errors = [];
      if (row.Wagon_Receiver_Name_1) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Wagon_Receiver_Name_1);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Wagon_Receiver_Email_1);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Wagon_Receiver_Name_1;
          const newContact = new Contact();
          newContact.name = row.Wagon_Receiver_Name_1;
          newContact.email = row.Wagon_Receiver_Email_1;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Wagon_Receiver_Name_2) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Wagon_Receiver_Name_2);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Wagon_Receiver_Email_2);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Wagon_Receiver_Name_2;
          const newContact = new Contact();
          newContact.name = row.Wagon_Receiver_Name_2;
          newContact.email = row.Wagon_Receiver_Email_2;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Wagon_Receiver_Name_3) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Wagon_Receiver_Name_3);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Wagon_Receiver_Email_3);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Wagon_Receiver_Name_3;
          const newContact = new Contact();
          newContact.name = row.Wagon_Receiver_Name_3;
          newContact.email = row.Wagon_Receiver_Email_3;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Last_Mile_Warehouse_Name_1) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Last_Mile_Warehouse_Name_1);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Last_Mile_Warehouse_Email_1);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Last_Mile_Warehouse_Name_1;
          const newContact = new Contact();
          newContact.name = row.Last_Mile_Warehouse_Name_1;
          newContact.email = row.Last_Mile_Warehouse_Email_1;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Last_Mile_Warehouse_Name_2) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Last_Mile_Warehouse_Name_2);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Last_Mile_Warehouse_Email_2);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Last_Mile_Warehouse_Name_2;
          const newContact = new Contact();
          newContact.name = row.Last_Mile_Warehouse_Name_2;
          newContact.email = row.Last_Mile_Warehouse_Email_2;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Last_Mile_Warehouse_Name_3) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Last_Mile_Warehouse_Name_3);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Last_Mile_Warehouse_Email_3);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Last_Mile_Warehouse_Name_3;
          const newContact = new Contact();
          newContact.name = row.Last_Mile_Warehouse_Name_3;
          newContact.email = row.Last_Mile_Warehouse_Email_3;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Goods_Recipient_Name_1) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Goods_Recipient_Name_1);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Goods_Recipient_Email_1);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Goods_Recipient_Name_1;
          const newContact = new Contact();
          newContact.name = row.Goods_Recipient_Name_1;
          newContact.email = row.Goods_Recipient_Email_1;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Goods_Recipient_Name_2) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Goods_Recipient_Name_2);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Goods_Recipient_Email_2);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Goods_Recipient_Name_2;
          const newContact = new Contact();
          newContact.name = row.Goods_Recipient_Name_2;
          newContact.email = row.Goods_Recipient_Email_2;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Goods_Recipient_Name_3) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Goods_Recipient_Name_3);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Goods_Recipient_Email_3);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Goods_Recipient_Name_3;
          const newContact = new Contact();
          newContact.name = row.Goods_Recipient_Name_3;
          newContact.email = row.Goods_Recipient_Email_3;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Goods_Sender_Name_1) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Goods_Sender_Name_1);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Goods_Sender_Email_1);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Goods_Sender_Name_1;
          const newContact = new Contact();
          newContact.name = row.Goods_Sender_Name_1;
          newContact.email = row.Goods_Sender_Email_1;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Goods_Sender_Name_2) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Goods_Sender_Name_2);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Goods_Sender_Email_2);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Goods_Sender_Name_2;
          const newContact = new Contact();
          newContact.name = row.Goods_Sender_Name_2;
          newContact.email = row.Goods_Sender_Email_2;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Goods_Sender_Name_3) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Goods_Sender_Name_3);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Goods_Sender_Email_3);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Goods_Sender_Name_3;
          const newContact = new Contact();
          newContact.name = row.Goods_Sender_Name_3;
          newContact.email = row.Goods_Sender_Email_3;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Other_Stakeholder_Name_1) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Other_Stakeholder_Name_1);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Other_Stakeholder_Email_1);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Other_Stakeholder_Name_1;
          const newContact = new Contact();
          newContact.name = row.Other_Stakeholder_Name_1;
          newContact.email = row.Other_Stakeholder_Email_1;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Other_Stakeholder_Name_2) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Other_Stakeholder_Name_2);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Other_Stakeholder_Email_2);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Other_Stakeholder_Name_2;
          const newContact = new Contact();
          newContact.name = row.Other_Stakeholder_Name_2;
          newContact.email = row.Other_Stakeholder_Email_2;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      if (row.Other_Stakeholder_Name_3) {
        let stakeholder = this.stakeholders.find(s => s.name == row.Other_Stakeholder_Name_3);
        if (!stakeholder) stakeholder = this.stakeholders.find(s => {
          return s.contacts.find(c => c.email == row.Other_Stakeholder_Email_3);
        })
        if (stakeholder) lane.stakeholders.push(stakeholder);
        else {
          const newStakeholder = new Stakeholder();
          newStakeholder.name = row.Other_Stakeholder_Name_3;
          const newContact = new Contact();
          newContact.name = row.Other_Stakeholder_Name_3;
          newContact.email = row.Other_Stakeholder_Email_3;
          newStakeholder.contacts.push(newContact);
          lane.stakeholders.push(newStakeholder);
        }
      }
      lanes.push(lane)
    }
    return lanes;
  }

  close() {
    this.dialogRef.close();
  }

}
