import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogConfirmComponent } from '../components/dialog/dialog-confirm/dialog-confirm.component';
import { ComponentCanDeactivate } from '../interfaces/component-can-deactivate';
import { DataService } from '../services/data.service';
import { StringService } from '../services/string.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DiscardChangesGuard implements CanDeactivate<unknown> {
  constructor(private strings: StringService,
              private dialog: MatDialog,
              private data: DataService,
              private location: Location) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!component.canDeactivate()) {
        return true;
      } else {
        const config = new MatDialogConfig();
        config.width = '500px';
        config.data = {
          title: this.strings.appStrings['UNSAVED_CHANGES'],
          message: this.strings.appStrings['DISCARD_UNSAVED_CHANGES'] + '?',
          state: 'YES_NO_OPTION'
        };
        const dialog = this.dialog.open(DialogConfirmComponent, config);
        return dialog.afterClosed().pipe(map(response => { 
          if (response) { 
            this.data.clearAll(); 
          } else {
            this.location.go(currentRoute.url[0].path);
          }
          return response 
        }))
      }
    
  }
  
}
