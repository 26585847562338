import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Lane, Stakeholder } from 'src/app/classes/items';
import { DataService } from 'src/app/services/data.service';
import { StakeholderService } from 'src/app/services/stakeholder.service';
import { StringService } from 'src/app/services/string.service';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { Contact } from '../../classes/contact';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PrivilegesService } from 'src/app/services/privileges.service';
import { FilterService } from 'src/app/services/filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  readonly WEEKLY = "NOTIFICATIONS_WEEKLY";
  readonly DAILY = "NOTIFICATIONS_DAILY";
  readonly HOURLY = "NOTIFICATIONS_HOURLY";
  readonly INSTANT = "NOTIFICATIONS_INSTANT";
  readonly NONE = "NOTIFICATIONS_NONE";
  username: string = localStorage.getItem('username');
  headers = ['agreement_number', 'departure_station_name', 'departure_station_station_id', 
    'arrival_station_name', 'arrival_station_station_id', 'track'];
  settingsForm: FormGroup;
  dataSource = new MatTableDataSource<Lane>();
  private laneSub: Subscription;

  constructor(public data: DataService,
              public strings: StringService,
              private stakeholderService: StakeholderService,
              private dialog: MatDialog,
              private filterService: FilterService,
              public privileges: PrivilegesService,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.filterService.lane = { departure_country: null, arrival_country: null }
    this.laneSub = this.data.observeLanes().subscribe(() => {
      this.dataSource.data = this.data.lanes?.filter((lane: Lane) => {
        return lane?.stakeholders?.findIndex((stakeholder: Stakeholder) => {
          return stakeholder?.contacts?.findIndex((contact: Contact) => {
            return contact?.email?.toLowerCase() === this.username?.toLowerCase()
          }) !== -1
        }) !== -1
      });
    })
  }

  ngOnDestroy() {
    this.laneSub.unsubscribe();
  }

  sortTable(sort: Sort) {
    const data = this.data.lanes.slice();
    if (!sort.active || sort.direction === '') {
      this.data.lanes = data;
      return;
    }
    this.data.lanes = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'agreement_number': return this.compare(a.agreement_number, b.agreement_number, isAsc);
        case 'departure_station_name': return this.compare(a.departure_station.name, b.departure_station.name, isAsc);
        case 'departure_station_station_id': return this.compare(a.departure_station.station_id, b.departure_station.station_id, isAsc);
        case 'departure_station_zipcode': return this.compare(a.departure_station.zipcode, b.departure_station.zipcode, isAsc);
        case 'arrival_station_name': return this.compare(a.arrival_station.name, b.arrival_station.name, isAsc);
        case 'arrival_station_station_id': return this.compare(a.arrival_station.station_id, b.arrival_station.station_id, isAsc);
        case 'arrival_station_zipcode': return this.compare(a.arrival_station.zipcode, b.arrival_station.zipcode, isAsc);
        case 'track': return this.compare(a.track, b.track, isAsc);
        default: return 0;
      }
    })
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  setNotifications(lane: Lane, setNotifications: number) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['NOTIFICATIONS'],
      message: (setNotifications) ? this.strings.appStrings['SEND_NOTIFICATIONS_FOR_LANE'] : this.strings.appStrings['STOP_NOTIFICATIONS'],
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.stakeholderService.setNotifications(lane.id, setNotifications).subscribe(() => {
          this.data.clearAll();
        })
      } else {
        this.data.clearAll();
      }
    })
  }

  deleteAccount() {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['DELETE_ACCOUNT'],
      message: this.strings.appStrings['ARE_YOU_SURE'],
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.auth.deleteContactAccount().subscribe(() => {
          location.reload()
        })
      }
    })
  }
}
