<div class="container d-flex justify-content-center form-holder">
    <div class="row text-center justify-content-center w-100">
        <form [formGroup]="form" style="width:100%;font-size: medium;">
            <h4 *ngIf="editId">{{stakeholder?.name}}</h4>
            <h4 *ngIf="!editId">{{strings.appStrings['STAKEHOLDER']}}</h4>
            <p *ngIf="form.dirty" style="color:red">{{strings.appStrings['UNSAVED_CHANGES']}}</p>
            <div class="form-group row justify-content-center w-100">
                <mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['NAME']}}</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="name"
                        name="name"
                        class="form-control"
                        maxlength="30"
                        required>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{strings.appStrings['TYPE']}}</mat-label>
                    <mat-select formControlName="stakeholder_type">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let stakeholderType of data.stakeholderTypes | async" [value]="stakeholderType">{{strings.appStrings[stakeholderType.type]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <h6>{{strings.appStrings['CONTACT']}}</h6>
            <div *ngFor="let contact of stakeholder?.contacts; let i = index" class="form-group row justify-content-center w-100">
                <mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['NAME']}}</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="contact_name_{{contact.id}}"
                        name="contact_name_"
                        class="form-control"
                        maxlength="50"
                        required>
                </mat-form-field>
                <mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['EMAIL']}}</mat-label>
                    <input
                        matInput
                        type="email"
                        formControlName="contact_email_{{contact.id}}"
                        name="email"
                        class="form-control"
                        maxlength="120"
                        required>
                </mat-form-field>
                <mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['PHONE_NUMBER']}}</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="contact_phone_number_{{contact.id}}"
                        name="phone_number"
                        class="form-control"
                        maxlength="40">
                </mat-form-field>
                <mat-form-field class="form-group col-sm mt-1" appearance="fill">
                    <mat-label>{{strings.appStrings['LANGUAGE']}}</mat-label>
                    <mat-select formControlName="contact_language_{{contact.id}}">
                        <mat-option *ngFor="let language of data.languages | async" [value]="language.id">{{strings.appStrings[language.language]}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-sm" floatLabel="always" appearance="none">
                    <mat-label>{{strings.appStrings['PORTAL_ACCESS']}}</mat-label>
                    <mat-slide-toggle 
                        formControlName="contact_portal_access_{{contact.id}}"
                        color="primary">
                    </mat-slide-toggle>
                    <textarea matInput hidden></textarea>
                </mat-form-field>
                    <!--<mat-slide-toggle
                        formControlName="contact_portal_access_{{contact.id}}"
                        [(ngModel)]="contact.portal_access"
                        color="primary">
                        {{strings.appStrings['PORTAL_ACCESS']}}
                    </mat-slide-toggle>-->
                <!--<mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['PORTAL_ACCESS']}}</mat-label>
                    <mat-select
                        [(ngModel)]="contact.portal_access"
                        formControlName="contact_portal_access_{{contact.id}}"
                        name="portal_access"
                        class="form-control">
                        <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                        <mat-option [value]="0">{{strings.appStrings['NO']}}</mat-option>
                    </mat-select>
                </mat-form-field>-->
                <span class="material-icons delete mb-4" style="width:20px;" (click)="removeContact(contact.id)">delete</span>
            </div>
            <button type="button" class="mat-raised-button mat-primary p-2" (click)="createStakeholder()">
                <div *ngIf="loading"><span class="spinner-border spinner-border-sm"></span>&nbsp;</div>
                <span *ngIf="!editId">{{strings.appStrings['ADD']}}</span>
                <span *ngIf="editId">{{strings.appStrings['UPDATE']}}</span>
            </button>
            <button type="button" class="mat-raised-button p-2 ms-2" (click)="addContact()">{{strings.appStrings['ADD']}} {{strings.appStrings['CONTACT']}}</button>
        </form>
    </div>
</div>

<div *ngIf="editId" class="container d-flex justify-content-center mt-5">
    <button mat-button-raised (click)="addLane()" class="mat-raised-button p-1">{{strings.appStrings['ADD']}} {{strings.appStrings['LANE']}}</button>
</div>

<div *ngIf="stakeholder?.lanes.length > 0" class="container d-flex justify-content-center mt-5">
    <div class="text-center justify-content-center w-100">
        <div class="mat-elevation-z8 table-response-md" style="text-align:start">
            <table
                mat-table
                matSort
                (matSortChange)="sortTable($event)"
                [dataSource]="stakeholder?.lanes"
                matSortActive="agreement_number"
                matSortDirection="desc"
                class="table table-light table-striped table-hover">
            
                <ng-container matColumnDef="agreement_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="agreement_number">{{strings.appStrings['AGREEMENT_NUMBER']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.agreement_number}}</span>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="departure_station">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="departure_station_name">{{strings.appStrings['DEPARTURE_STATION']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.departure_station?.name}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="arrival_station">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="arrival_station_name">{{strings.appStrings['ARRIVAL_STATION']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.arrival_station?.name}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="access_track_and_trace">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="access_track_and_trace">{{strings.appStrings['ACCESS_TRACK_AND_TRACE']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <mat-select (selectionChange)="setAccess(lane, $event.target.value)" [(ngModel)]="lane.access_track_and_trace">
                            <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                            <mat-option [value]="0">{{strings.appStrings['NO']}}</mat-option>
                        </mat-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="access_demurrage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="access_demurrage">{{strings.appStrings['ACCESS_DEMURRAGE']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <mat-select (selectionChange)="setAccess(lane, $event.value)" [(ngModel)]="lane.access_demurrage">
                            <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                            <mat-option [value]="0">{{strings.appStrings['NO']}}</mat-option>
                        </mat-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lane_wagons">
                    <th mat-header-cell *matHeaderCellDef class="edit-column"></th>
                    <td mat-cell *matCellDef="let lane" class="edit-column">
                        <span class="material-icons edit" (click)="openWagons(lane.id)">train</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="open_lane">
                    <th mat-header-cell *matHeaderCellDef class="edit-column"></th>
                    <td mat-cell *matCellDef="let lane" class="edit-column">
                        <span class="material-icons edit" routerLink="/lane/{{lane.id}}">open_in_browser</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="delete-column"></th>
                    <td mat-cell *matCellDef="let lane" class="delete-column">
                        <span class="material-icons delete" (click)="detachLane(lane)">delete</span>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="headers"></tr>
                <tr
                mat-row style="height: auto;"
                *matRowDef="let row; columns: headers"></tr>
            </table>
        </div>
    </div>
</div>

<!--<div #alert class="alert alert-warning hide">
    <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
        <span aria-hidden="true">&times;</span>
        <strong style="position:relative;">{{alertString}}</strong>
    </button>
</div>-->