import * as moment from 'moment';
import { IArrivalHistory } from '../interfaces/i-arrival-history';
import { Lane } from './items';
import { Registration } from './registration';

export class ArrivalHistory {

    private _id: number;
    private _wagon_number: string;
    private _wagon_type: string;
    private _lane_id: number;
    private _departure_time: Date;
    private _arrival_time: Date;
    private _description: string;
    private _sender_ref: string;
    private _itu_number: string;
    private _expected_arrival: Date;
    private _date_when_eta_set: Date;
    private _netto_weight: number;
    private _brutto_weight: number;
    private _rid: number;
    private _size: number;
    private _comment: string;
    private _train_number: string;
    private _lane: Lane = new Lane();
    private _registrations: Registration[] = [];
    private _selected: boolean = false;

    constructor(obj?: IArrivalHistory) {
        if (!obj) return;
        this._id = obj.id;
        this._wagon_number = obj.wagon_number;
        this._wagon_type = obj.wagon_type;
        this._lane_id = obj.lane_id;
        this._departure_time = moment(obj.departure_time).local().toDate();
        this._arrival_time = moment(obj.arrival_time).local().toDate();
        this._description = obj.description;
        this._sender_ref = obj.sender_ref;
        this._itu_number = obj.itu_number;
        this._expected_arrival = (obj.expected_arrival) ? moment(obj.expected_arrival).local().toDate() : null;
        this._date_when_eta_set = (obj.date_when_eta_set) ? moment(obj.date_when_eta_set).local().toDate() : null;
        this._netto_weight = obj.netto_weight;
        this._brutto_weight = obj.brutto_weight;
        this._rid = obj.rid;
        this._size = obj.length_code;
        this._comment = obj.comment;
        this._train_number = obj.train_number;
        this._lane = new Lane(obj.lane);
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter wagon_number
     * @return {string}
     */
	public get wagon_number(): string {
		return this._wagon_number;
	}

    /**
     * Getter wagon_type
     * @return {string}
     */
	public get wagon_type(): string {
		return this._wagon_type;
	}

    /**
     * Getter lane_id
     * @return {number}
     */
	public get lane_id(): number {
		return this._lane_id;
	}

    /**
     * Getter departure_time
     * @return {Date}
     */
	public get departure_time(): Date {
		return this._departure_time;
	}

    /**
     * Getter arrival_time
     * @return {Date}
     */
	public get arrival_time(): Date {
		return this._arrival_time;
	}

    /**
     * Getter description
     * @return {string}
     */
	public get description(): string {
		return this._description;
	}

    /**
     * Getter sender_ref
     * @return {string}
     */
	public get sender_ref(): string {
		return this._sender_ref;
	}

    /**
     * Getter itu_number
     * @return {string}
     */
	public get itu_number(): string {
		return this._itu_number;
	}

    /**
     * Getter expected_arrival
     * @return {Date}
     */
	public get expected_arrival(): Date {
		return this._expected_arrival;
	}

    /**
     * Getter date_when_eta_set
     * @return {Date}
     */
	public get date_when_eta_set(): Date {
		return this._date_when_eta_set;
	}

    /**
     * Getter netto_weight
     * @return {number}
     */
	public get netto_weight(): number {
		return this._netto_weight;
	}

    /**
     * Getter brutto_weight
     * @return {number}
     */
	public get brutto_weight(): number {
		return this._brutto_weight;
	}

    /**
     * Getter rid
     * @return {number}
     */
	public get rid(): number {
		return this._rid;
	}

    /**
     * Getter size
     * @return {number}
     */
	public get size(): number {
		return this._size;
	}

    /**
     * Getter comment
     * @return {string}
     */
	public get comment(): string {
		return this._comment;
    }
    
    /**
     * Getter train_number
     * @return {string}
     */
	public get train_number(): string {
		return this._train_number;
	}

    /**
     * Getter lane
     * @return {Lane }
     */
	public get lane(): Lane  {
		return this._lane;
	}

    /**
     * Getter registrations
     * @return {Registration[] }
     */
	public get registrations(): Registration[]  {
		return this._registrations;
    }
    
    /**
     * Getter selected
     * @return {boolean}
     */
	public get selected(): boolean  {
		return this._selected;
    }

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter wagon_number
     * @param {string} value
     */
	public set wagon_number(value: string) {
		this._wagon_number = value;
	}

    /**
     * Setter wagon_type
     * @param {string} value
     */
	public set wagon_type(value: string) {
		this._wagon_type = value;
	}

    /**
     * Setter lane_id
     * @param {number} value
     */
	public set lane_id(value: number) {
		this._lane_id = value;
	}

    /**
     * Setter departure_time
     * @param {Date} value
     */
	public set departure_time(value: Date) {
		this._departure_time = value;
	}

    /**
     * Setter arrival_time
     * @param {Date} value
     */
	public set arrival_time(value: Date) {
		this._arrival_time = value;
	}

    /**
     * Setter description
     * @param {string} value
     */
	public set description(value: string) {
		this._description = value;
	}

    /**
     * Setter sender_ref
     * @param {string} value
     */
	public set sender_ref(value: string) {
		this._sender_ref = value;
	}

    /**
     * Setter itu_number
     * @param {string} value
     */
	public set itu_number(value: string) {
		this._itu_number = value;
	}

    /**
     * Setter expected_arrival
     * @param {Date} value
     */
	public set expected_arrival(value: Date) {
		this._expected_arrival = value;
	}

    /**
     * Setter date_when_eta_set
     * @param {Date} value
     */
	public set date_when_eta_set(value: Date) {
		this._date_when_eta_set = value;
	}

    /**
     * Setter netto_weight
     * @param {number} value
     */
	public set netto_weight(value: number) {
		this._netto_weight = value;
	}

    /**
     * Setter brutto_weight
     * @param {number} value
     */
	public set brutto_weight(value: number) {
		this._brutto_weight = value;
	}

    /**
     * Setter rid
     * @param {number} value
     */
	public set rid(value: number) {
		this._rid = value;
	}

    /**
     * Setter size
     * @param {number} value
     */
	public set size(value: number) {
		this._size = value;
	}

    /**
     * Setter comment
     * @param {string} value
     */
	public set comment(value: string) {
		this._comment = value;
    }
    
    /**
     * Setter train_number
     * @param {string} value
     */
	public set train_number(value: string) {
		this._train_number = value;
	}

    /**
     * Setter lane
     * @param {Lane } value
     */
	public set lane(value: Lane ) {
		this._lane = value;
	}

    /**
     * Setter registrations
     * @param {Registration[] } value
     */
	public set registrations(value: Registration[] ) {
		this._registrations = value;
    }
    
    /**
     * Setter selected
     * @param {boolean} value
     */
	public set selected(value: boolean) {
		this._selected = value;
    }

}
