import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  public getOptions() {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Content-Type', 'application/json; charset=utf-8')

    return { headers: headers, withCredentials: true }
  }
}
