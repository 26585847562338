import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { StringService } from 'src/app/services/string.service';
import { SearchService } from 'src/app/services/search.service';
import { Subscription } from 'rxjs';
import { Lane } from 'src/app/classes/items';
import { DataService } from 'src/app/services/data.service';
import { LaneService } from 'src/app/services/lane.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FilterRoutes, FilterService } from 'src/app/services/filter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { DialogImportLanesComponent } from '../dialog-import-lanes/dialog-import-lanes.component';

@Component({
  selector: 'app-lanes',
  templateUrl: './lanes.component.html',
  styleUrls: ['./lanes.component.scss']
})
export class LanesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  headers = ['agreement_number', 'departure_station_name', 'departure_station_station_id', 
              'arrival_station_name', 'arrival_station_station_id', 'track', 'sender', 'receiver',
              'has_stakeholders', 'lane_wagons', 'edit', 'delete'];
  private inputSub: Subscription;
  private laneSub: Subscription;
  dataSource = new MatTableDataSource<Lane>();
  @HostListener('document:keydown', ['$event'])
    handleKeyBoardEvent(event: KeyboardEvent) {
      switch (event.code) {
        case 'ArrowRight':
          this.paginator?.nextPage();
          break;
        case 'ArrowLeft':
          this.paginator?.previousPage();
          break;
      }
    }

  constructor(public data: DataService,
              public strings: StringService,
              public searchService: SearchService,
              public laneService: LaneService,
              public dialog: MatDialog,
              private router: Router,
              public filterService: FilterService,
              private route: ActivatedRoute) {
                this.dataSource = new MatTableDataSource<Lane>([]);
              }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      this.router.navigate([],
        {
          relativeTo: this.route,
          queryParams: { arrival_country: 'DK' },
          queryParamsHandling: 'merge'
        })
    }
    this.inputSub = this.searchService.change.subscribe((value) => { this.dataSource.filter = value; })
    this.strings.change.subscribe(() => {
      if (this.dataSource.paginator) {
        this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
      }
    })
  }

  ngOnDestroy() {
    this.inputSub.unsubscribe();
    this.laneSub.unsubscribe();
  }

  ngDoCheck() {
    const laneChanges = this.filterService.differ.lane.diff(this.filterService.lane);
    if (laneChanges) {
      this.filterService.updateFilter(FilterRoutes.Lane, this.filterService.lane);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
    this.dataSource.filter = this.searchService.searchString;
    this.dataSource.filterPredicate = (data, filter: string)  => {
      const accumulator = (currentTerm, key) => {
        switch(key) {
          case '_arrival_station': return `${currentTerm}${data.arrival_station.name}${data.arrival_station.station_id}`;
          case '_departure_station': return `${currentTerm}${data.departure_station.name}${data.departure_station.station_id}`;
          default: return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.laneSub = this.data.observeLanes().subscribe(() => {
      if (this.data.lanes) {
        this.dataSource.data = this.data.lanes;
        if (this.route.snapshot.queryParamMap.get('sort')) {
          this.sortTable({
            active: this.route.snapshot.queryParamMap.get('sort'),
            direction: (this.route.snapshot.queryParamMap.get('direction') === 'desc') ? 'asc' : 'desc'
          })
        } else {
          this.sortTable({ active: 'agreement_number', direction: 'asc' })    
        }
      } else this.dataSource.data = [];
      if (this.route.snapshot.queryParamMap.get('page') && this.dataSource.paginator) {
        try {
          this.dataSource.paginator.pageIndex = Number.parseInt(this.route.snapshot.queryParamMap.get('page'))
        } catch(e) {}
      }
    })
  }

  sortTable(sort: Sort) {
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: { sort: sort.active, direction: sort.direction },
        queryParamsHandling: 'merge'
      })
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'agreement_number': return this.compare(a.agreement_number, b.agreement_number, isAsc);
        case 'departure_station_name': return this.compare(a.departure_station.name, b.departure_station.name, isAsc);
        case 'departure_station_station_id': return this.compare(a.departure_station.station_id, b.departure_station.station_id, isAsc);
        case 'arrival_station_name': return this.compare(a.arrival_station.name, b.arrival_station.name, isAsc);
        case 'arrival_station_station_id': return this.compare(a.arrival_station.station_id, b.arrival_station.station_id, isAsc);
        case 'track': return this.compare(a.track, b.track, isAsc);
        case 'sender': return this.compare(a.sender?.name, b.sender.name, isAsc);
        case 'receiver': return this.compare(a.receiver?.name, b.receiver?.name, isAsc);
        case 'has_stakeholders': return this.compare(a.stakeholders.length > 0, b.stakeholders.length > 0, isAsc);
        default: return 0;
      }
    })
  }

  private compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteLane(lane: Lane) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['DELETE'] + ' ' + this.strings.appStrings['LANE'],
      message: this.strings.appStrings['DELETE'] + ' ' + lane.toString() + '?\n' + this.strings.appStrings['DELETE_LANE_DESC'],
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.laneService.delete(lane).subscribe(() => {
          this.data.clearAll();
        })
      }
    })
  }

  openWagons(laneId) {
    this.router.navigate(['/wagons'], 
      {
        queryParams: { arrival_country: 'DK', lane: laneId },
        queryParamsHandling: 'merge'
      });
  }

  onPageChange(event: PageEvent) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: event.pageIndex },
      queryParamsHandling: 'merge'
    })
  }

  exportToExcel()
  {
    let data = this.dataSource.data.map((elem) => {
      return {
        KA_Number: elem.agreement_number,
        Departure_Station_Name: elem.departure_station?.name,
        Departure_Station_DIUM: elem.departure_station?.country_code + elem.departure_station?.station_id,
        Arrival_Station_Name: elem.arrival_station?.name,
        Arrival_Station_DIUM: elem.arrival_station?.country_code + elem.arrival_station?.station_id,
        Track: elem.track,
        Sender: elem.sender?.name,
        Receiver: elem.receiver?.name,
        Has_Stakeholders: elem.stakeholders?.length > 0 ? "Yes" : "No"
      }
    })
    if (data.length == 0) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.height = '300px';
      config.data = {
        title: this.strings.appStrings['AN_ERROR_OCCURRED'],
        message: this.strings.appStrings['NO_DATA_TO_EXPORT'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'WIP_EXPORT');
    XLSX.writeFile(wb, `WIP_STAKEHOLDER_EXPORT_${moment().local().format('YYYY_MM_DD_HH_MM_SS')}.xlsx`);
  }

  openImportDialog() {
    const config = new MatDialogConfig();
      config.autoFocus = false;
      config.closeOnNavigation = true;
      config.width = '850px';
      config.disableClose = true;
      this.dialog.open(DialogImportLanesComponent, config);
  }
}
