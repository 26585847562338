import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-oauth2',
  templateUrl: './oauth2.component.html',
  styleUrls: ['./oauth2.component.scss']
})
export class Oauth2Component implements OnInit {

  loading: boolean = false;

  constructor(private route: ActivatedRoute,
              private auth: AuthService) { }

  ngOnInit(): void {
    let code = this.route.snapshot.paramMap.get('code');
    let state = this.route.snapshot.paramMap.get('state');
    if (code && state) {
      try {
        this.loading = true;
        if (state.length % 4 !== 0) {
          const len = state.length % 4;
          for (let i=0; i<len; i++) {
            state += '='
          }
        }
        if (state === this.auth.getState()) {
          let savedStateObj = JSON.parse(atob(this.auth.getState()));
          this.auth.requestAccessToken(code, savedStateObj.redirect)
        }
      } catch (e) {
        this.loading = false;
      }
    }
  }

}
