import * as moment from 'moment';
import { IOperationStatus } from '../interfaces/i-operation-status';
import { Lane } from './items';

export class OperationStatus {

    private _id: number;
    private _time: Date;
    private _active: number;
    private _title: string;
    private _description: string;
    private _expected_end_time: Date;
    private _send_email: boolean;
    private _lanes: Lane[] = [];

    constructor(obj?: IOperationStatus) {
        if (!obj) return;
        this._id = obj.id;
        this._time = moment(obj.time).local().toDate();
        this._active = obj.active;
        this._title = obj.title;
        this._description = obj.description;
        this._expected_end_time = (obj.expected_end_time) ? 
            moment(obj.expected_end_time).local().toDate() : 
            null;
    }

    public toJSON() {
        return {
            id: this._id,
            time: moment(this._time).local().format('YYYY-MM-DD HH:mm:ss'),
            active: this._active,
            title: this._title,
            description: this._description,
            expected_end_time: (this._expected_end_time && this._expected_end_time.getTime()) ? moment(this._expected_end_time).local().format('YYYY-MM-DD HH:mm:ss') : null,
            send_email: this._send_email,
            lanes: this._lanes.map(lane => lane.id)
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter time
     * @return {Date}
     */
	public get time(): Date {
		return this._time;
	}

    /**
     * Getter active
     * @return {number}
     */
	public get active(): number {
		return this._active;
	}

    /**
     * Getter title
     * @return {string}
     */
	public get title(): string {
		return this._title;
	}

    /**
     * Getter description
     * @return {string}
     */
	public get description(): string {
		return this._description;
	}

    /**
     * Getter expected_end_time
     * @return {Date}
     */
	public get expected_end_time(): Date {
		return this._expected_end_time;
	}

    /**
     * Getter send_email
     * @return {boolean}
     */
	public get send_email(): boolean {
		return this._send_email;
	}

    /**
     * Getter lanes
     * @return {Lane[] }
     */
	public get lanes(): Lane[]  {
		return this._lanes;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter time
     * @param {Date} value
     */
	public set time(value: Date) {
		this._time = value;
	}

    /**
     * Setter active
     * @param {number} value
     */
	public set active(value: number) {
		this._active = value;
	}

    /**
     * Setter title
     * @param {string} value
     */
	public set title(value: string) {
		this._title = value;
	}

    /**
     * Setter description
     * @param {string} value
     */
	public set description(value: string) {
		this._description = value;
	}

    /**
     * Setter expected_end_time
     * @param {Date} value
     */
	public set expected_end_time(value: Date) {
		this._expected_end_time = value;
	}

    /**
     * Setter send_email
     * @param {boolean} value
     */
	public set send_email(value: boolean) {
		this._send_email = value;
	}

    /**
     * Setter lanes
     * @param {Lane[] } value
     */
	public set lanes(value: Lane[] ) {
		this._lanes = value;
	}
}
