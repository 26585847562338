import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Lane } from 'src/app/classes/items';
import { StringService } from 'src/app/services/string.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Station } from 'src/app/classes/station';
import { startWith, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { LaneService } from '../../../services/lane.service';
import { Sort } from '@angular/material/sort';
import { Stakeholder } from 'src/app/classes/items';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DialogAttachStakeholderComponent } from '../dialog-attach-stakeholder/dialog-attach-stakeholder.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { QueryParamService } from 'src/app/services/query-param.service';
import { SenderReceiver } from 'src/app/classes/sender-receiver';


@Component({
  selector: 'app-lane',
  templateUrl: './lane.component.html',
  styleUrls: ['./lane.component.scss']
})
export class LaneComponent  implements OnInit {

  loading: boolean = false;
  lane: Lane = new Lane();
  form: FormGroup;
  filteredDepartureStations: Observable<Station[]>;
  filteredArrivalStations: Observable<Station[]>;
  filteredReceiversByName: Observable<SenderReceiver[]>;
  filteredReceiversByCustomerNumber: Observable<SenderReceiver[]>;
  editId: number;
  headers: string[] = [
    'name', 'contacts', 'access_track_and_trace', 'access_demurrage', 'open_stakeholder', 'delete'
  ]
  private laneSub: Subscription;

  constructor(public strings: StringService,
              public data: DataService,
              private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private laneService: LaneService,
              public dialog: MatDialog,
              private queryParamService: QueryParamService) { }

  ngOnInit(): void {
    this.setupForm();
    if (this.route.snapshot.paramMap.get('laneId')) {
      try { 
        this.editId = Number.parseInt(this.route.snapshot.paramMap.get('laneId')); 
        this.laneSub = this.data.lane(this.editId).subscribe((lane: Lane) => {
          this.lane = lane;
          this.setupForm(lane);
        })
      }
      catch(e) { this.setupForm(); }
    }
  }

  ngOnDestroy() {
    if (this.laneSub) this.laneSub.unsubscribe();
  }

  canDeactivate() {
    return this.form.dirty;
  }

  stationSelected(type: string, name: string) {
    let station = this.data.stations.find((elem) => { return elem.name.toLowerCase() === name.toLowerCase() });
    if (station) {
      switch (type) {
        case 'departure':
          this.form.controls.departure_station_station_id.setValue(station.station_id);
          this.form.controls.departure_station_zipcode.setValue(station.zipcode);
          this.form.controls.departure_station_country_code.setValue(station.country_code);
          this.form.controls.departure_station_country_code_iso.setValue(station.country_code_iso);
          break;
        case 'arrival':
          this.form.controls.arrival_station_station_id.setValue(station.station_id);
          this.form.controls.arrival_station_zipcode.setValue(station.zipcode);
          this.form.controls.arrival_station_country_code.setValue(station.country_code);
          this.form.controls.arrival_station_country_code_iso.setValue(station.country_code_iso);
          break;
      }
    }
  }

  receiverSelected(name?: string, customerNumber?: number) {
    const receiver = this.data.sendersReceivers.find((elem) => { return name ? elem.name.toLowerCase() == name.toLowerCase() : customerNumber ? elem.customer_number == customerNumber : null });
    if (receiver) {
      this.form.controls.receiver_name.setValue(receiver.name);
      this.form.controls.receiver_customer_number.setValue(receiver.customer_number);
    }
  }

  private setupForm(lane?: Lane) {
    this.form = this.fb.group({
      agreement_number: new FormControl(lane?.agreement_number || '', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      track: new FormControl(lane?.track || '', [
        Validators.maxLength(30)
      ]),
      is_receiver_id_key: new FormControl(lane?.is_receiver_id_key || 0, [

      ]),
      departure_station_name: new FormControl(lane?.departure_station?.name || '', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      departure_station_station_id: new FormControl(lane?.departure_station?.station_id || '', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      departure_station_zipcode: new FormControl(lane?.departure_station?.zipcode || '', [
        Validators.maxLength(12)
      ]),
      departure_station_country_code: new FormControl(lane?.departure_station?.country_code || '', [
        Validators.required,
        Validators.maxLength(2)
      ]),
      departure_station_country_code_iso: new FormControl(lane?.departure_station?.country_code_iso || '', [
        Validators.maxLength(2)
      ]),
      arrival_station_name: new FormControl(lane?.arrival_station?.name || '', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      arrival_station_station_id: new FormControl(lane?.arrival_station?.station_id || '', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      arrival_station_zipcode: new FormControl(lane?.arrival_station?.zipcode || '', [
        Validators.maxLength(12)
      ]),
      arrival_station_country_code: new FormControl(lane?.arrival_station?.country_code || '', [
        Validators.required,
        Validators.maxLength(2)
      ]),
      arrival_station_country_code_iso: new FormControl(lane?.arrival_station?.country_code_iso || '', [
        Validators.maxLength(2)
      ]),
      receiver_name: new FormControl(lane?.receiver?.name || '', [
        Validators.maxLength(80)
      ]),
      receiver_customer_number: new FormControl(lane?.receiver?.customer_number || '')
    })
    this.filteredDepartureStations = this.form.controls.departure_station_name.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        return this.data?.stations?.filter((station) => {
          return station.name.toLowerCase().trim().indexOf(value.toLowerCase().trim()) !== -1
        })
      })
    )
    this.filteredArrivalStations = this.form.controls.arrival_station_name.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        return this.data?.stations?.filter((station) => {
          return station.name.toLowerCase().trim().indexOf(value.toLowerCase().trim()) !== -1
        })
      })
    )
    this.filteredReceiversByName = this.form.controls.receiver_name.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        return this.data?.sendersReceivers?.filter((senderReceiver) => {
          return senderReceiver.name.toLowerCase().trim().indexOf(value.toLowerCase().trim()) !== -1
        })
      })
    )
    this.filteredReceiversByCustomerNumber = this.form.controls.receiver_customer_number.valueChanges.pipe(
      startWith(''),
      map((value: number) => {
        return this.data?.sendersReceivers?.filter((senderReceiver) => {
          return senderReceiver.customer_number.toString().indexOf(value.toString()) !== -1
        })
      })
    )
  }

  createLane() {
    if (!this.form.valid) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['FILL_IN_REQUIRED'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
      return;
    }
    const existingDep = this.data.stations.find((elem) => { return elem.station_id === this.form.value.departure_station_station_id && elem.country_code === this.form.value.departure_station_country_code });
    const existingArr = this.data.stations.find((elem) => { return elem.station_id === this.form.value.arrival_station_station_id && elem.country_code === this.form.value.arrival_station_country_code });
    const existingReceiver = this.data.sendersReceivers.find((elem) => { return elem.name === this.form.value.receiver_name && elem.customer_number === this.form.value.customer_number });
    if (existingDep && existingArr && !this.editId) {
      for (let lane of this.data.lanes) {
        if (lane.agreement_number === this.form.value.agreement_number && 
            lane.departure_station.id === existingDep.id && 
            lane.arrival_station.id === existingArr.id && 
            lane.receiver?.id || null === existingReceiver?.id || null)
          {
            const config = new MatDialogConfig();
            config.width = '500px';
            config.data = {
              title: this.strings.conStrings['AN_ERROR_OCCURRED'],
              message: this.strings.conStrings['LANE_NOT_UNIQUE'],
              state: 'OK_OPTION'
            };
            this.dialog.open(DialogConfirmComponent, config);
          }
      }
    }
    this.loading = true;
    const departureId: number = (existingDep) ? existingDep.id : null;
    const arrivalId: number = (existingArr) ? existingArr.id : null;
    const receiverId: number = (existingReceiver) ? existingReceiver.id : null;
    const departure = new Station({
      id: departureId,
      name: this.form.value.departure_station_name,
      station_id: this.form.value.departure_station_station_id,
      zipcode: this.form.value.departure_station_zipcode || null,
      country_code: this.form.value.departure_station_country_code,
      country_code_iso: this.form.value.departure_station_country_code_iso || null
    });
    const arrival = new Station({
      id: arrivalId,
      name: this.form.value.arrival_station_name,
      station_id: this.form.value.arrival_station_station_id,
      zipcode: this.form.value.arrival_station_zipcode,
      country_code: this.form.value.arrival_station_country_code,
      country_code_iso: this.form.value.arrival_station_country_code_iso || null
    });
    const receiver = new SenderReceiver({
      id: receiverId || null,
      name: this.form.value.receiver_name,
      customer_number: this.form.value.receiver_customer_number,
      reference: null
    });
    const lane = new Lane({
      id: this.editId || null,
      agreement_number: this.form.value.agreement_number,
      track: this.form.value.track || null,
      is_receiver_id_key: this.form.value.is_receiver_id_key || 0,
      departure_station_id: departureId,
      arrival_station_id: arrivalId,
      departure_station: departure,
      arrival_station: arrival,
      receiver: receiver.id ? receiver : null
    });
    if (this.editId) {
      this.laneService.update(lane).subscribe((response) => {
        this.loading = false;
        this.form.markAsPristine();
        this.laneService.clear();
        this.router.navigate(['/lanes'], {
          queryParams: this.queryParamService.previousQueryParams
        })
      })
    } else {
      this.laneService.create(lane).subscribe((response) => {
        this.loading = false;
        this.form.markAsPristine();
        this.laneService.clear();
        this.router.navigate(['/lanes'], {
          queryParams: this.queryParamService.previousQueryParams
        })
      })
    }
  }

  setAccess(stakeholder: Stakeholder, access: number) {
    this.laneService.setStakeholderAccess(this.editId, stakeholder.id, stakeholder.access_track_and_trace, access).subscribe();
  }

  sortTable(sort: Sort) {
    const data = this.data.stakeholders.slice();
    if (!sort.active || sort.direction === '') {
      this.data.stakeholders = data;
      return;
    }
    this.data.stakeholders = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'contacts': return this.compare(a.contacts['name'], b.contacts['name'], isAsc);
        default: return 0;
      }
    })
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  addStakeholder() {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '850px';
    config.data = { id: this.editId }
    const dialog = this.dialog.open(DialogAttachStakeholderComponent, config);
    dialog.afterClosed().subscribe((stakeholder: Stakeholder) => {
      if (stakeholder) {
        this.data.clearAll();
      }
    })
  }

  detachStakeholder(stakeholder: Stakeholder) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['DELETE'] + ' ' + this.strings.appStrings['LANE'],
      message: this.strings.appStrings['DELETE'] + ' ' + stakeholder.name + '?',
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.laneService.detachStakeholder(this.editId, stakeholder.id).subscribe(() => {
          this.data.clearAll();
        })
      }
    })
  }
}
