import * as moment from 'moment';
import { IWagon } from '../interfaces/i-wagon';
import { Container } from './container';
import { Lane } from './items';
import { Registration } from './registration';

export class Wagon {

    private _id: number;
    private _wagon_number: string;
    private _wagon_type: string;
    private _container: string;
    private _size: number;
    private _lane_id: number;
    private _expected_departure: Date;
    private _expected_arrival: Date;
    private _netto_weight: number;
    private _brutto_weight: number;
    private _tare: number;
    private _rid: number;
    private _is_empty: string;
    private _sender_ref: string;
    private _plan: Array<Object>;
    private _send_notification: number;
    private _train_number: string;
    private _newest_registration_with_eta: Registration;
    private _lane: Lane;
    private _editing: boolean = false;
    private _registrations: Registration[] = [];
    private _containers: Container[] = [];
    private _selected: boolean = false;

    constructor(obj?: IWagon, containerIdx?: number) {
        if (!obj) return;
        this._id = obj.id;
        this._wagon_number = obj.wagon_number;
        this._wagon_type = obj.wagon_type;
        this._container = obj.container;
        this._size = obj.size;
        this._lane_id = obj.lane_id;
        this._expected_departure = (obj.expected_departure) ? moment(obj.expected_departure).toDate() : null;
        this._expected_arrival = (obj.expected_arrival) ? moment(obj.expected_arrival).toDate() : null;
        this._netto_weight = obj.netto_weight;
        this._brutto_weight = obj.brutto_weight;
        this._tare = obj.tare;
        this._rid = obj.rid;
        this._is_empty = obj.is_empty;
        this._sender_ref = obj.sender_ref;
        this._plan = obj.plan;
        this._train_number = obj.train_number;
        if (obj.newest_registration_with_eta) {
            this._newest_registration_with_eta = new Registration(obj.newest_registration_with_eta);
        }
        this._lane = new Lane(obj.lane);
        if (obj.registrations && obj.registrations instanceof Array) {
            for (let registration of obj.registrations) {
                this._registrations.push(new Registration(registration));
            }
        } else if (obj.newest_registration) {
            this._registrations.push(new Registration(obj.newest_registration));
        }
        if (obj.containers && obj.containers instanceof Array) {
            if (containerIdx) {
                this._containers.push(new Container(obj.containers[containerIdx]))
            } else {
                for (let container of obj.containers) {
                    this._containers.push(new Container(container));
                }
            }
        }
    }

    isDifferent(wagon: Wagon): boolean {
        return (
            this._registrations.length != wagon.registrations.length || 
            this._containers[0]?.comment != wagon.containers[0]?.comment || 
            this._train_number != wagon.train_number || 
            this._expected_arrival?.getTime() != wagon.expected_arrival?.getTime()
        )
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter wagon_number
     * @return {string}
     */
	public get wagon_number(): string {
		return this._wagon_number;
    }
    
    /**
     * Getter wagon_type
     * @return {string}
     */
	public get wagon_type(): string {
		return this._wagon_type;
	}

    /**
     * Getter container
     * @return {string}
     */
	public get container(): string {
		return this._container;
	}

    /**
     * Getter size
     * @return {number}
     */
	public get size(): number {
		return this._size;
	}

    /**
     * Getter lane_id
     * @return {number}
     */
	public get lane_id(): number {
		return this._lane_id;
	}

    /**
     * Getter expected_departure
     * @return {Date}
     */
	public get expected_departure(): Date {
		return this._expected_departure;
	}

    /**
     * Getter expected_arrival
     * @return {Date}
     */
	public get expected_arrival(): Date {
		return this._expected_arrival;
	}

    /**
     * Getter netto_weight
     * @return {number}
     */
	public get netto_weight(): number {
		return this._netto_weight;
	}

    /**
     * Getter brutto_weight
     * @return {number}
     */
	public get brutto_weight(): number {
		return this._brutto_weight;
	}

    /**
     * Getter tare
     * @return {number}
     */
	public get tare(): number {
		return this._tare;
	}

    /**
     * Getter rid
     * @return {number}
     */
	public get rid(): number {
		return this._rid;
	}

    /**
     * Getter is_empty
     * @return {string}
     */
	public get is_empty(): string {
		return this._is_empty;
	}

    /**
     * Getter sender_ref
     * @return {string}
     */
	public get sender_ref(): string {
		return this._sender_ref;
	}

    /**
     * Getter plan
     * @return {Array<Object>}
     */
	public get plan(): Array<Object> {
		return this._plan;
	}

    /**
     * Getter send_notification
     * @return {number}
     */
	public get send_notification(): number {
		return this._send_notification;
    }
    
    /**
     * Getter train_number
     * @return {string}
     */
	public get train_number(): string {
		return this._train_number;
    }

    /**
     * Getter lane
     * @return {Lane}
     */
	public get lane(): Lane {
		return this._lane;
	}

    /**
     * Getter editing
     * @return {boolean }
     */
	public get editing(): boolean  {
		return this._editing;
	}

    /**
     * Getter registrations
     * @return {Registration[] }
     */
	public get registrations(): Registration[]  {
		return this._registrations;
	}

    /**
     * Getter containers
     * @return {Container[] }
     */
	public get containers(): Container[]  {
		return this._containers;
    }
    
    /**
     * Getter selected
     * @return {boolean}
     */
	public get selected(): boolean  {
		return this._selected;
    }

    /**
     * Getter newest_registration_with_eta
     * @return {Registration}
     */
	public get newest_registration_with_eta(): Registration  {
		return this._newest_registration_with_eta;
    }

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter wagon_number
     * @param {string} value
     */
	public set wagon_number(value: string) {
		this._wagon_number = value;
    }
    
    /**
     * Setter wagon_type
     * @param {string} value
     */
	public set wagon_type(value: string) {
		this._wagon_type = value;
	}

    /**
     * Setter container
     * @param {string} value
     */
	public set container(value: string) {
		this._container = value;
	}

    /**
     * Setter size
     * @param {number} value
     */
	public set size(value: number) {
		this._size = value;
	}

    /**
     * Setter lane_id
     * @param {number} value
     */
	public set lane_id(value: number) {
		this._lane_id = value;
	}

    /**
     * Setter expected_departure
     * @param {Date} value
     */
	public set expected_departure(value: Date) {
		this._expected_departure = value;
	}

    /**
     * Setter expected_arrival
     * @param {Date} value
     */
	public set expected_arrival(value: Date) {
		this._expected_arrival = value;
	}

    /**
     * Setter netto_weight
     * @param {number} value
     */
	public set netto_weight(value: number) {
		this._netto_weight = value;
	}

    /**
     * Setter brutto_weight
     * @param {number} value
     */
	public set brutto_weight(value: number) {
		this._brutto_weight = value;
	}

    /**
     * Setter tare
     * @param {number} value
     */
	public set tare(value: number) {
		this._tare = value;
	}

    /**
     * Setter rid
     * @param {number} value
     */
	public set rid(value: number) {
		this._rid = value;
	}

    /**
     * Setter is_empty
     * @param {string} value
     */
	public set is_empty(value: string) {
		this._is_empty = value;
	}

    /**
     * Setter sender_ref
     * @param {string} value
     */
	public set sender_ref(value: string) {
		this._sender_ref = value;
	}

    /**
     * Setter plan
     * @param {Array<Object>} value
     */
	public set plan(value: Array<Object>) {
		this._plan = value;
	}

    /**
     * Setter send_notification
     * @param {number} value
     */
	public set send_notification(value: number) {
		this._send_notification = value;
    }
    
    /**
     * Setter train_number
     * @param {string} value
     */
	public set train_number(value: string) {
		this._train_number = value;
    }

    /**
     * Setter lane
     * @param {Lane} value
     */
	public set lane(value: Lane) {
		this._lane = value;
	}

    /**
     * Setter editing
     * @param {boolean } value
     */
	public set editing(value: boolean ) {
		this._editing = value;
	}

    /**
     * Setter registrations
     * @param {Registration[] } value
     */
	public set registrations(value: Registration[] ) {
		this._registrations = value;
	}

    /**
     * Setter containers
     * @param {Container[] } value
     */
	public set containers(value: Container[] ) {
		this._containers = value;
    }
    
    /**
     * Setter selected
     * @param {boolean} value
     */
	public set selected(value: boolean) {
		this._selected = value;
    }

    /**
     * Setter newest_registration_with_eta
     * @param {Registration} value
     */
	public set newest_registration_with_eta(value: Registration) {
		this._newest_registration_with_eta = value;
    }

}
