<div class="container" style="text-align: center;">
    <div
        *ngIf="data.isLoadingOperationStatus"
        class="spinner-border text-primary mt-3">
    </div>

    <div class="mat-elevation-z8 table-response-md">
        <h4>{{strings.appStrings['OPERATION_STATUS']}}</h4>
        <span class="material-icons add" style="font-size: xx-large;" (click)="addOperationStatus()">
            add_circle
        </span>

        <table
            mat-table
            [dataSource]="dataSource"
            class="table table-light table-striped table-hover"
            style="text-align: left;">

            <ng-container matColumnDef="title">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['TITLE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span *ngIf="elem.title?.length <= 20">{{elem.title}}</span>
                    <span *ngIf="elem.title?.length > 20" matTooltip="{{elem.title}}">{{elem.title.slice(0,20)}}</span>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="time">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    class="p-1">
                    {{strings.appStrings['TIME']}}
                </th>
                <td 
                    mat-cell 
                    *matCellDef="let elem" 
                    class="p-1 text right">
                    <span>{{elem.time | date : 'yyyy-MM-dd HH:mm'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['DESCRIPTION']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span *ngIf="elem.description.length <= 40">{{elem.description}}</span>
                    <span *ngIf="elem.description.length > 40" matTooltip="{{elem.description}}">{{elem.description.slice(0,40)}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="expected_end">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['EXPECTED_END_TIME']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span *ngIf="!elem.expected_end_time">{{strings.appStrings['UNKNOWN']}}</span>
                    <span *ngIf="elem.expected_end_time">{{elem.expected_end_time | date : 'yyyy-MM-dd HH:mm'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef class="delete-column"></th>
                <td mat-cell *matCellDef="let item" class="delete-column">
                    <span class="material-icons delete" (click)="deactivateStatus(item)">delete</span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="headers; sticky: true"></tr>
            <tr
                mat-row
                style="height:auto;"
                *matRowDef="let row; columns: headers">
            </tr>
        </table>
    </div>
</div>