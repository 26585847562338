import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-magic-login',
  templateUrl: './magic-login.component.html',
  styleUrls: ['./magic-login.component.scss']
})
export class MagicLoginComponent implements OnInit {

  constructor(public strings: StringService,
              private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    let username = this.route.snapshot.paramMap.get('username');
    let token = this.route.snapshot.paramMap.get('token');
    if (username && token) {
      this.auth.magicLogin(username, token);
    } else {
      this.router.navigate(['/']);
    }
  }

}
