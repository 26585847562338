import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { OperationStatus } from '../classes/operation-status';
import { IOperationStatus } from '../interfaces/i-operation-status';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class OperationStatusService {

  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;
  private _operationStatus: OperationStatus[] = null;

  constructor(private http: HttpClient,
              private headers: HeaderService,
              private api: ApiService) { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get operationStatus(): OperationStatus[] {
    if (!this._operationStatus && !this._isLoading && !this._loadFailed) {
      this.get();
    }
    return this._operationStatus;
  }

  public set operation_status(operation_status: OperationStatus[]) {
    this._operationStatus = operation_status;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public clear() {
    this._operationStatus = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  private get() {
    this._isLoading = true;
    this.http.get<IOperationStatus>(
      `${this.api.LOCATION}/operation-status`,
      this.headers.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          this._operationStatus = [];
          for (let operationStatus of response) {
            this._operationStatus.push(new OperationStatus(operationStatus));
          }
        }
        this._isLoading = false;
        this._loadFailed = false;
        this.toggle();
      },
      error: () => {
        this._loadFailed = true;
        this._isLoading = false;
      }
    })
  }

  public close(operationStatus: OperationStatus) {
    return this.http.put<any>(
      `${this.api.LOCATION}/operation-status/${operationStatus.id}`,
      this.headers.getOptions()
    )
  }

  public create(operationStatus: OperationStatus) {
    return this.http.post<any>(
      `${this.api.LOCATION}/operation-status`,
      { status: operationStatus.toJSON() },
      this.headers.getOptions()
    )
  }
}

export enum OPERATION_STATUS {
  PREFIX = "OP_STAT_"
}
