import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Contact } from '../classes/contact';
import { Lane, Stakeholder } from '../classes/items';

@Pipe({
  name: 'userLanes'
})
export class UserLanesPipe implements PipeTransform {

  transform(source: MatTableDataSource<Lane>, filter: string): Lane[] {
    if (!source || !source.data || !source.data['value']) return;
    if (!filter) return source.data;
    filter = filter.toLowerCase().trim();
    return source.data.filter(item => {
      return item.stakeholders.findIndex((stakeholder: Stakeholder) => {
        return stakeholder.contacts.findIndex((contact: Contact) => {
          return contact.email.toLowerCase() === filter.toLowerCase();
        }) === -1;
      })
    })
  }
}
