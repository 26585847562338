import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-dialog-cookie-consent',
  templateUrl: './dialog-cookie-consent.component.html',
  styleUrls: ['./dialog-cookie-consent.component.scss']
})
export class DialogCookieConsentComponent implements OnInit {

  data: any

  constructor(public strings: StringService,
              private dialogRef: MatDialogRef<DialogCookieConsentComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
                this.data = data;
              }

  ngOnInit(): void {
  }

  accept() {
    localStorage.setItem('COOKIES_ACCEPTED', '1');
    this.dialogRef.close();
  }
}
