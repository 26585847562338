<div class="container">
    <div class="row">
        <div 
            style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner 
                color="primary" 
                mode="indeterminate">
            </mat-progress-spinner>
        </div>
    </div>
</div>
