import { EventEmitter, Injectable, KeyValueDiffer, KeyValueDiffers, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoggedInService } from './logged-in.service';
import { PrivilegesService } from './privileges.service';

export enum FilterRoutes {
  Wagon = '/wagons',
  Lane = '/lanes',
  ArrivalHistory = '/arrival-history'
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _wagon: IWagonFilter = {};
  private _lane: ILaneFilter = {};
  private _arrivalHistory: IArrivalHistoryFilter = {};
  public differ: Differ;

  constructor(private router: Router,
              private differs: KeyValueDiffers,
              private privileges: PrivilegesService,
              private loggedIn: LoggedInService) {
    this.resetWagonFilter();
    this.resetLaneFilter();
    this.resetArrivalHistoryFilter();
    
    this._wagon.arrival_country = this.getDefaultCountry();
    this._arrivalHistory.arrival_country = this.getDefaultCountry();
    
    if (this.privileges.isAdmin) {
      this._arrivalHistory.arrival_from = moment().local().subtract(7, 'days').format('YYYY-MM-DD');
    } else {
      this._arrivalHistory.arrival_from = moment().local().subtract(1, 'month').format('YYYY-MM-DD')
    }
    this._arrivalHistory.arrival_to = moment().local().format('YYYY-MM-DD');
    this.differ = {
      wagon: this.differs.find(this._wagon).create(),
      lane: this.differs.find(this._lane).create(),
      arrivalHistory: this.differs.find(this._arrivalHistory).create()
    }
    this.updateFilter(FilterRoutes.Wagon, this.wagon);
  }

  @Output() changeWagon: EventEmitter<boolean> = new EventEmitter();
  @Output() changeLane: EventEmitter<boolean> = new EventEmitter();
  @Output() changeArrivalHistory: EventEmitter<boolean> = new EventEmitter();

  public toggleWagon() {
    this.changeWagon.emit();
  }

  public toggleLane() {
    this.changeLane.emit();
  }

  public toggleArrivalHistory() {
    this.changeArrivalHistory.emit();
  }

  private getDefaultCountry(): string {
    return 'DK';
    switch (navigator.language) {
      case 'da': return 'DK';
      case 'de': return 'DE';
      case 'fr': return 'FR';
      case 'it': return 'IT';
      case 'sv': return 'SE';
      default: return 'DK';
    }
  }

  public resetWagonFilter() {
    this._wagon.departure_country = null;
    this._wagon.arrival_country = this.getDefaultCountry();
    this._wagon.location = null;
    this._wagon.lane = null;
    this._wagon.stakeholder = null;
    this._wagon.limit = null;
    this._wagon.type = null;
    this._wagon.manual_eta = null;
  }

  public resetArrivalHistoryFilter() {
    this.arrivalHistory.departure_country = null;
    this.arrivalHistory.departure_to = null;
    if (this.privileges.isAdmin) {
      this._arrivalHistory.arrival_from = moment().local().subtract(7, 'days').format('YYYY-MM-DD');
    } else {
      this._arrivalHistory.arrival_from = moment().local().subtract(1, 'month').format('YYYY-MM-DD')
    }
    this.arrivalHistory.arrival_to = moment().local().format('YYYY-MM-DD');
    this.arrivalHistory.departure_country = null;
    this.arrivalHistory.arrival_country = null;
    this.arrivalHistory.stakeholder = null;
    this.arrivalHistory.lane = null;
    this.arrivalHistory.type = null;
  }

  public resetLaneFilter() {
    this.lane.departure_country = null;
    this.lane.arrival_country = this.getDefaultCountry();
  }

  public updateFilter(path: FilterRoutes, filter: IWagonFilter | ILaneFilter | IArrivalHistoryFilter) {
    switch(path) {
      case FilterRoutes.Wagon: 
        this.wagon = filter as IWagonFilter;
        break;
      case FilterRoutes.Lane: 
        this.lane = filter as ILaneFilter;
        break;
      case FilterRoutes.ArrivalHistory: 
        this.arrivalHistory = filter as IArrivalHistoryFilter;
        break;
    }
    if (this.loggedIn.loggedIn) {
      this.router.navigate([path], {
        queryParams: filter
      })
    }
  }

  public get wagon(): IWagonFilter {
    return this._wagon;
  }

  public set wagon(value: IWagonFilter) {
    this._wagon = value;
    this.toggleWagon();
  }

  public get lane(): ILaneFilter {
    return this._lane;
  }

  public set lane(value: ILaneFilter) {
    this._lane = value;
    this.toggleLane();
  }

  public get arrivalHistory(): IArrivalHistoryFilter {
    return this._arrivalHistory;
  }

  public set arrivalHistory(value: IArrivalHistoryFilter) {
    this._arrivalHistory = value;
    this.toggleArrivalHistory();
  }

  public getParams(obj: any): string {
    let params = Object.keys(obj)
      .filter(key => obj[key])
      .map((key: string) => {
        if (obj[key]) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        } else {
          return '';
        }
      })
      .join('&')
    return params.substring(0, params.length)
  }
}

export interface Differ {
  wagon: KeyValueDiffer<string, any>;
  lane: KeyValueDiffer<string, any>;
  arrivalHistory: KeyValueDiffer<string, any>;
}

export interface IWagonFilter {
  departure_country?: string,
  arrival_country?: string,
  location?: number;
  lane?: number;
  stakeholder?: number;
  limit?: number;
  type?: string;
  manual_eta?: number;
  departure_station?: number;
  arrival_station?: number;
  receiver?: number;
}

export interface ILaneFilter {
  departure_country?: string;
  arrival_country?: string;
}

export interface IArrivalHistoryFilter {
  departure_from?: string;
  departure_to?: string;
  arrival_from?: string;
  arrival_to?: string;
  departure_country?: string;
  arrival_country?: string;
  stakeholder?: number;
  lane?: number;
  type?: string;
}
