import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import * as moment from 'moment';
import { interval, Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { FilterRoutes, FilterService } from 'src/app/services/filter.service';
import { SearchService } from 'src/app/services/search.service';
import { StringService } from 'src/app/services/string.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrivalHistory } from 'src/app/classes/arrival-history';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { DialogRegistrationsComponent } from '../dialog/dialog-registrations/dialog-registrations.component';
import { ArrivalHistoryService } from 'src/app/services/arrival-history.service';
import { Registration } from 'src/app/classes/registration';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';
import { Lane, Stakeholder } from 'src/app/classes/items';
import { PrivilegesService } from 'src/app/services/privileges.service';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { SwipeService } from 'src/app/services/swipe.service';
import { DialogEditCommentComponent } from '../dialog/dialog-edit-comment/dialog-edit-comment.component';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-arrival-history',
  templateUrl: './arrival-history.component.html',
  styleUrls: ['./arrival-history.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
      },
    } }
  ]
})
export class ArrivalHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginatorTop: MatPaginator;
  @ViewChild(MatMenuTrigger) public contextMenu: MatMenuTrigger;
  private arrivalHistorySub: Subscription;
  private inputSub: Subscription;
  private stringsSub: Subscription;
  private updateArrivalHistorySub: Subscription;
  @HostListener('document:keydown', ['$event'])
    handleKeyboard(event: KeyboardEvent) {
      switch(event.code) {
        case 'ArrowRight':
          this.paginatorTop?.nextPage();
          break;
        case 'ArrowLeft':
          this.paginatorTop?.previousPage();
          break;
      }
    }
  headers = [
    'selected', 'wagon_number', 'container', 
    'departure_time', 'arrival_time',
    'departure_station', 
    'arrival_station',
    'wagon_type',
    'agreement_number', 'sender', 'description', 'reference', 'info', 'registrations',
    'message'
  ];
  dataSource = new MatTableDataSource<ArrivalHistory>();
  
  days = [
    1, 3, 7, 14, 30, 50
  ];
  public stakeholderControl = new FormControl();
  public laneControl = new FormControl();
  public filteredStakeholders: Stakeholder[];
  public filteredLanes: Lane[];
  public transportType: string;
  public transportTypeSub: Subscription;
  public initialStakeholder: Stakeholder;
  private stakeholderSub: Subscription;
  private laneSub: Subscription;
  public lane: Lane;
  public stakeholder: Stakeholder;
  private privilegesSub: Subscription;
  public allSelected: boolean;
  public contextMenuPositionX: number = 0;
  public contextMenuPositionY: number = 0;


  constructor(public data: DataService,
              public strings: StringService,
              private searchService: SearchService,
              public filterService: FilterService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              public arrivalHistoryService: ArrivalHistoryService,
              public privileges: PrivilegesService,
              private swipeService: SwipeService) { }

  ngOnInit(): void {
    this.privilegesSub = this.privileges.change.subscribe(() => { if (this.privileges.isAdminOrInternalUser) this.setupAdminSubscriptions() });
    if (this.privileges.isAdminOrInternalUser) {
      this.setupAdminSubscriptions();
    }

    //subscribe to changes in search field
    this.inputSub = this.searchService.change.subscribe((value) => { this.dataSource.filter = value })
    //subscribe to changes in strings
    this.stringsSub = this.strings.change.subscribe(() => {
      if (this.dataSource.paginator) {
        this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
      }
    })
    const i = interval(1000 * 60);
    this.updateArrivalHistorySub = i.subscribe(() => { 
      this.arrivalHistoryService.update();
    })
    this.swipeService.swipeNext.subscribe(() => this.paginatorTop?.nextPage())
    this.swipeService.swipePrevious.subscribe(() => this.paginatorTop?.previousPage())
  }

  private setupAdminSubscriptions() {
    this.stakeholderSub = this.route.queryParams.pipe(
      mergeMap((params) => {
        if (params.stakeholder) return this.data.stakeholder(params.stakeholder);
        else return [];
      })
    ).subscribe((stakeholder?: Stakeholder) => { 
      this.stakeholder = stakeholder; 
      if (stakeholder) this.filterService.wagon.stakeholder = this.stakeholder?.id || null 
    })
    this.laneSub = this.route.queryParams.pipe(
      mergeMap((params) => {
        if (params.lane) return this.data.lane(params.lane)
        else return [];
      })
    ).subscribe((lane?: Lane) => {
      this.lane = lane; 
      if (lane) this.filterService.wagon.lane = this.lane?.id || null 
    })
  }

  public stakeholderChanged(value) {
    if (typeof value === 'string' && value === '') {
      this.stakeholder = null; 
      if (this.filterService.arrivalHistory.stakeholder) this.filterService.arrivalHistory.stakeholder = null;
      this.stakeholderControl.setValue('');
    } else if (typeof value === 'object') { 
      this.stakeholder = value; 
      this.filterService.arrivalHistory.stakeholder = this.stakeholder.id;
    } else {
      this.filteredStakeholders = value ? this.data.filterStakeholders(value) : this.data.stakeholders?.slice()
    }
  }

  public laneChanged(value) {
    if (typeof value === 'string' && value === '') {
      this.lane = null; 
      if (this.filterService.arrivalHistory.lane) this.filterService.arrivalHistory.lane = null;
      this.laneControl.setValue('');
    } else if (typeof value === 'object') { 
      this.lane = value; 
      this.filterService.arrivalHistory.lane = this.lane.id;
    } else {
      this.filteredLanes = value ? this.data.filterLanes(value) : this.data.lanes?.slice()
    }
  }

  checkEntirePage(checked: boolean) {
    let startIdx = this.paginatorTop.pageSize * this.paginatorTop.pageIndex;
    let endIdx = startIdx + this.paginatorTop.pageSize - 1;
    for (let i=startIdx; i<=endIdx; i++) {
      if (this.dataSource.data[i]) {
        this.dataSource.data[i].selected = checked;
      } else break;
    }
  }

  ngDoCheck() {
    const arrivalHistoryChanges = this.filterService.differ.arrivalHistory.diff(this.filterService.arrivalHistory);
    if (arrivalHistoryChanges) {
      this.filterService.updateFilter(FilterRoutes.ArrivalHistory, this.filterService.arrivalHistory);
    }
  }

  ngOnDestroy() {
    if (this.arrivalHistorySub) this.arrivalHistorySub.unsubscribe();
    if (this.inputSub) this.inputSub.unsubscribe();
    if (this.stringsSub) this.stringsSub.unsubscribe();
    if (this.transportTypeSub) this.transportTypeSub.unsubscribe();
    if (this.stakeholderSub) this.stakeholderSub.unsubscribe();
    if (this.laneSub) this.laneSub.unsubscribe();
    this.filterService.resetArrivalHistoryFilter();
  }

  dateIsValid(d: Date): boolean {
    return d instanceof Date && !isNaN(d.getTime());
  }

  getItemInformation(arrivalHistory: ArrivalHistory): string {
    let nettoWeight = (arrivalHistory.netto_weight) ? `${this.strings.appStrings['NETTO_WEIGHT']}: ${arrivalHistory.netto_weight} kg\n` : '';
    let grossWeight = (arrivalHistory.brutto_weight) ? `${this.strings.appStrings['BRUTTO_WEIGHT']}: ${arrivalHistory.brutto_weight} kg\n` : '';
    let rid = (arrivalHistory.rid) ? `${this.strings.appStrings['RID']}\n` : '';
    let senderRef = (arrivalHistory.sender_ref) ? `${this.strings.appStrings['SENDER_REFERENCE']}: ${arrivalHistory.sender_ref}\n` : '';
    let lengthCode = (arrivalHistory.size) ? `${this.strings.appStrings['SIZE']}: ${arrivalHistory.size}\n` : '';
    return nettoWeight + grossWeight + rid + senderRef + lengthCode;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginatorTop;
    this.dataSource.filter = this.searchService.searchString;
    this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
    this.dataSource.filterPredicate = (data, filter: string)  => {
      const accumulator = (currentTerm, key) => {
        switch(key) {
          case '_arrival_time': {
            if (this.dateIsValid(data.arrival_time)) {
              return currentTerm + `
                ${moment(data.arrival_time).local().format('DD-MM-YYYY')}
                ${moment(data.arrival_time).local().format('YYYY-MM-DD')}
                ${moment(data.arrival_time).local().format('DD.MM.YYYY')}
                ${moment(data.arrival_time).local().format('YYYY.MM.DD')}
                ${moment(data.arrival_time).local().format('DDMMYYYY')}
                ${moment(data.arrival_time).local().format('YYYYMMDD')}
              `.replace(/\s+/g, '');
            } else return '';
          }
          case '_departure_time': {
            if (this.dateIsValid(data.departure_time)) {
              return currentTerm + `
                ${moment(data.departure_time).local().format('DD-MM-YYYY')}
                ${moment(data.departure_time).local().format('YYYY-MM-DD')}
                ${moment(data.departure_time).local().format('DD.MM.YYYY')}
                ${moment(data.departure_time).local().format('YYYY.MM.DD')}
                ${moment(data.departure_time).local().format('DDMMYYYY')}
                ${moment(data.departure_time).local().format('YYYYMMDD')}
              `.replace(/\s+/g, '');
            } else return ''
          }
          default: return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.arrivalHistorySub = this.data.observeArrivalHistory().subscribe(() => {
      this.dataSource.data = this.data.arrivalHistory || [];
      if (this.data.arrivalHistory && this.data.arrivalHistory.length > 0) {
        if (this.route.snapshot.queryParamMap.get('sort')) {
          this.sortTable({
            active: this.route.snapshot.queryParamMap.get('sort'),
            direction: (this.route.snapshot.queryParamMap.get('direction') === 'desc') ? 'desc' : 'asc'
          })
        } else {
          this.sortTable({ active: 'arrival_time', direction: 'desc' })
        }
        if (this.route.snapshot.queryParamMap.get('page') && this.dataSource.paginator) {
          try {
            this.dataSource.paginator.pageIndex = Number.parseInt(this.route.snapshot.queryParamMap.get('page'))
          } catch(e) {}
        }
      } else this.dataSource.data = [];
    })
  }

  sortTable(sort: Sort) {
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: { sort: sort.active, direction: sort.direction },
        queryParamsHandling: 'merge'
      })
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit': return this.compare(a.itu_number, b.itu_number, isAsc);
        case 'agreement_number': return this.compare(a.lane?.agreement_number, b.lane?.agreement_number, isAsc);
        case 'departure_station': return this.compare(a.lane?.departure_station?.name, b.lane?.departure_station?.name, isAsc);
        case 'arrival_station': return this.compare(a.lane?.arrival_station?.name, b.lane?.arrival_station?.name, isAsc);
        case 'departure_time': return this.compare(a.departure_time, b.departure_time, isAsc);
        case 'arrival_time': return this.compare(a.arrival_time, b.arrival_time, isAsc);
        case 'sender_ref': return this.compare(a.sender_ref, b.sender_ref, isAsc);
        case 'description': return this.compare(a.description, b.description, isAsc);
        case 'sender': return this.compare(a.lane.sender?.name, b.lane.sender?.name, isAsc);
        case 'reference': return this.compare(a.sender_ref, b.sender_ref, isAsc);
        default: return 0;
      }
    })
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onPageChangeTop(event: PageEvent) {
      let startIdx = this.paginatorTop.pageSize * this.paginatorTop.pageIndex;
      let endIdx = startIdx + this.paginatorTop.pageSize - 1;
      let selected = true;
      for (let i=startIdx; i<=endIdx; i++) {
        if (this.dataSource.data[i]) {
          if (!this.dataSource.data[i].selected) {
            selected = false;
            break;
          }
        } else break;
      }
      this.allSelected = selected;
      this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: { page: event.pageIndex },
        queryParamsHandling: 'merge'
      })
  }

  setComment(arrival: ArrivalHistory) {
    let selectedArrivals: ArrivalHistory[] = this.dataSource.data.filter(w => w.selected);
    if (!selectedArrivals || selectedArrivals.length == 0) selectedArrivals = [arrival];
    let message = (selectedArrivals.length <= 1) ? this.strings.appStrings['UNIT'] : this.strings.appStrings['UNITS'];
    message += '<br>';
    for (let i=0; i<selectedArrivals.length; i++) {
      message += selectedArrivals[i]?.itu_number || selectedArrivals[i].wagon_number;
      if (i < selectedArrivals.length - 1) {
        message += ', '
      }
    }
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      message: message,
      comment: arrival?.comment
    }
    const dialog = this.dialog.open(DialogEditCommentComponent, config);
    dialog.afterClosed().subscribe((comment) => {
      if (comment) {
        if (comment == 'delete-comment') {
          this.arrivalHistoryService.setComment(selectedArrivals, null).subscribe(() => { this.arrivalHistoryService.update() })
        } else {
          this.arrivalHistoryService.setComment(selectedArrivals, comment).subscribe(() => { this.arrivalHistoryService.update() })
        }
      }
    })
  }

  onContextMenu(event: MouseEvent, arrival: ArrivalHistory) {
    event.preventDefault();
    this.contextMenuPositionX = event.clientX;
    this.contextMenuPositionY = event.clientY;
    this.contextMenu.menuData = { item: arrival };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openRegistrations(arrivalHistory: ArrivalHistory) {
    if (!arrivalHistory) return;
    arrivalHistory['is_loading_registrations'] = true;
    this.arrivalHistoryService.getRegistrationHistory(arrivalHistory.id).subscribe({
      next: (response) => {
        if (!response || !response.data || response.data instanceof Array === false) {
          const config = new MatDialogConfig();
          config.width = '500px';
          config.height = '300px';
          config.data = {
            title: this.strings.appStrings['REGISTRATIONS'],
            message: this.strings.appStrings['NO_REGISTRATIONS_FOUND'],
            state: 'OK_OPTION'
          };
          this.dialog.open(DialogConfirmComponent, config);
          arrivalHistory['is_loading_registrations'] = false;
          return;  
        } else {
          arrivalHistory.registrations = [];
          for (let registration of response.data) {
            arrivalHistory.registrations.push(new Registration(registration));
          }
          const config = new MatDialogConfig();
          config.autoFocus = false;
          config.closeOnNavigation = true;
          config.width = '600px';
          config.data = { wagon: arrivalHistory }
          this.dialog.open(DialogRegistrationsComponent, config);
          arrivalHistory['is_loading_registrations'] = false;
        }
      },
      error: () => { arrivalHistory['is_loading_registrations'] = false }
    })
  }

  exportToExcel()
  {
    let data = this.dataSource.data.map((elem) => {
      return {
        Wagon_Number: elem.wagon_number,
        Unit: elem.itu_number,
        Departure: moment(elem.departure_time).local().format('YYYY-MM-DD'),
        Arrived: moment(elem.arrival_time).local().format('YYYY-MM-DD'),
        Departure_Station: elem.lane?.departure_station?.name,
        Arrival_Station: elem.lane?.arrival_station?.name,
        Wagon_Type: elem.wagon_type,
        KA_No: elem.lane?.agreement_number,
        Sender: elem.lane?.sender?.name,
        Goods: elem.description,
        Sender_Reference: elem.sender_ref || '',
        Net_Weight: elem.netto_weight,
        Gross_Weight: elem.brutto_weight,
        Size: elem.size,
        RID: elem.rid,
        Comment: elem.comment
      }
    })
    if (data.length == 0) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.height = '300px';
      config.data = {
        title: this.strings.appStrings['AN_ERROR_OCCURRED'],
        message: this.strings.appStrings['NO_DATA_TO_EXPORT'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'WIP_Arrival_Export');
    XLSX.writeFile(wb, `WIP_Arrival_Export_${moment().local().format('YYYY_MM_DD_HH_MM_SS')}.xlsx`);
  }
}
