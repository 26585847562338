import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RouteConfigLoadStart } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Stakeholder } from 'src/app/classes/items';
import { DataService } from 'src/app/services/data.service';
import { LaneService } from 'src/app/services/lane.service';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-dialog-attach-stakeholder',
  templateUrl: './dialog-attach-stakeholder.component.html',
  styleUrls: ['./dialog-attach-stakeholder.component.scss']
})
export class DialogAttachStakeholderComponent implements OnInit {

  form: FormGroup;
  filteredStakeholders: Observable<Stakeholder[]>;
  private laneId: number;

  constructor(public data: DataService,
              public strings: StringService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogAttachStakeholderComponent>,
              @Inject(MAT_DIALOG_DATA) configData,
              private laneService: LaneService) {
                this.laneId = configData.id;
              }

  ngOnInit(): void {
    this.form = this.fb.group({
      stakeholder: new FormControl('', [
        Validators.required
      ])
    })
    this.filteredStakeholders = this.form.controls.stakeholder.valueChanges.pipe(
      startWith(this.data.stakeholders),
      map((value: any) => {
        if (!value || value instanceof Array) return this.data.stakeholders;
        return this.data.stakeholders.filter((stakeholder: Stakeholder) => {
          return `
            ${stakeholder.name}
            ${stakeholder.contacts.toString()}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })
      })
    )
    this.data.observeStakeholders().subscribe(() => {
      this.form.controls.stakeholder.setValue('');
    })
  }

  add() {
    let stakeholder = this.data.stakeholders.find(item => item.name === this.form.value.stakeholder);
    if (stakeholder) {
      this.laneService.attachStakeholder(this.laneId, stakeholder.id).subscribe(() => {
        this.dialogRef.close(stakeholder);
      })
    }
  }

  close() {
    this.dialogRef.close();
  }

}
