import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { pairs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StringService {

  public conStrings: Object = {};
  public appStrings: Object = {};
  public readonly TYPE_CONNECTION = 1;
  public readonly TYPE_LANE = 2;

  constructor(private http: HttpClient,
              private header: HeaderService,
              @Inject(CookieService) private cookie: CookieService,
              private api: ApiService) { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public getStrings(type: number, language?: string) {
    if ([1, 2].indexOf(type) === -1) return;
    let location: string = null;
    if (type === this.TYPE_CONNECTION) location = 'connection-strings';
    else location = 'strings';
    if (!language) {
      language = this.cookie.get('language');
      if (!language) {
        language = navigator.language.split('-')[0];
      }
    }
    //Default to english if not danish
    if (language != 'dan') language = 'eng';
    this.cookie.set('language', language, 60 * 60* 24 * 30, '/', '/', true, 'None');
    return this.http.get<any>(
      `${this.api.LOCATION}/${location}/${language}`,
      this.header.getOptions()
    )
    .subscribe((strings) => {
      switch (type) {
        case this.TYPE_CONNECTION:
          this.conStrings = strings;
          break;
        case this.TYPE_LANE:
          this.appStrings = strings.data;
          break;
        default:
          break;
      }
      this.toggle();
    });
  }  

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorInt = new MatPaginatorIntl();
    paginatorInt.itemsPerPageLabel = this.appStrings['ITEMS_PER_PAGE'];
    paginatorInt.firstPageLabel = this.appStrings['FIRST_PAGE'];
    paginatorInt.previousPageLabel = this.appStrings['PREVIOUS_PAGE'];
    paginatorInt.nextPageLabel = this.appStrings['NEXT_PAGE'];
    paginatorInt.lastPageLabel = this.appStrings['LAST_PAGE'];
    const rangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) return `0 ${this.appStrings['OF']} ${length}`;
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) : 
        startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${this.appStrings['OF']} ${length}`;
    }
    paginatorInt.getRangeLabel = rangeLabel;
    return paginatorInt;
  }
}


