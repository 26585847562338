<div class="cont">
    <div class="login-main-text">
        <h6 class="company-title">DB Cargo Scandinavia</h6>
        <img src="assets/img/DB_WIP_icon.png" class="app-icon">
        <h3 class="d-none d-xl-block app-title">Wagon Information Portal</h3>
    </div>
    <div class="sidenav"></div>    
    <div class="main" *ngIf="state === STATE_LOGIN">
        <div class="main-content col-md-5 col-sm-12">
            <div class="login-form border justify-content-center p-3">
                <div class="col-xs-3" *ngIf="showEmailWasSent">
                    <span style="color:orange">{{strings.conStrings['EMAIL_WAS_SENT']}}</span>
                </div>
                <div class="col-xs-3" *ngIf="showMagicRequestEmailSent">
                    <span style="color:orange">{{strings.appStrings['MAGIC_LOGIN_REQUEST_SENT']}}</span>
                </div>
                <div class="row text-center">
                    <form [formGroup]="loginForm">
                        <mat-form-field class="form-group text-center w-100">
                            <mat-label>{{strings.conStrings['EMAIL']}}</mat-label>
                            <input #loginUsername matInput type="email" formControlName="username" class="inp form-control input-text small" (keyup.enter)="externalLogin()" required>
                        </mat-form-field>
                        <mat-form-field class="form-group text-center w-100">
                            <mat-label>{{strings.conStrings['PASSWORD']}}</mat-label>
                            <input #loginPassword matInput [type]="hide ? 'password' : 'text'" formControlName="password" class="inp form-control input-text small" (keyup.enter)="externalLogin()" required>
                            <mat-icon *ngIf="loginForm.value.password" style="cursor:pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </form>
                </div>
                <div class="row text-center">
                    <div class="col">
                        <button type="button" class="mat-raised-button mat-primary p-1 mt-3 ms-1 me-1" (click)="externalLogin()">{{strings.conStrings['LOGIN']}}</button>
                    </div>
                </div>
                <div class="row text-center">
                    
                    <div class="col">
                        <button type="button" class="mat-raised-button p-1 mt-3 mx-1" routerLink="/login/request">{{strings.conStrings['REQUEST_PASSWORD']}}</button>
                    </div>
                    <div class="col">
                        <button type="button" class="mat-raised-button mat-primary p-1 mt-3 ms-1 me-1" routerLink="/login/magic-login-request">{{strings.appStrings['MAGIC_LOGIN']}}</button>
                    </div>
                    <div class="col">
                        <button type="button" class="mat-raised-button p-1 mt-3 mx-1" style="margin-left:5px" (click)="internalLogin()">{{strings.conStrings['BKU_LOGIN']}}</button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    <div class="main" *ngIf="state === STATE_REQUEST">
        <div class="main-content col-md-5 col-sm-12">
            <div class="login-form" style="text-align:center">
                <span style="font-size: large;" class="col-xs-3">
                    {{strings.conStrings['CREATE_OR_RESET_PASSWORD']}}
                </span>
                <br>
                <span style="font-size: small;" class="col-xs-3">
                    {{strings.conStrings['CREATE_OR_RESET_DESC']}}
                </span>
                <br>
                <br>
                <div class="row text-center">
                    <form [formGroup]="requestForm">
                        <mat-form-field class="form-group text-center w-100">
                            <mat-label>{{strings.conStrings['EMAIL']}}</mat-label>
                            <input matInput type="email" formControlName="email" class="inp form-control input-text small" (keyup.enter)="requestNewPassword()" required>
                        </mat-form-field>
                    </form>
                </div>
                <div class="row text-center">
                    <div class="col-sm">
                        <button type="button" class="mat-raised-button mat-primary p-1 mt-3 ms-1 me-1" (click)="requestNewPassword()">{{strings.conStrings['SEND']}}</button>
                    </div>
                    <div class="col-sm">
                        <button type="button" class="mt-3 mat-raised-button mat p-1 mt-3 mx-1" routerLink="/login">{{strings.conStrings['LOGIN']}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main" *ngIf="state === STATE_CREATE">
        <div class="main-content col-md-5 col-sm-12">
            <div class="mt-2 text-center">
                <div class="col-xs-3">
                    <span style="font-size: medium;font-weight: bold;">{{strings.conStrings['PASSWORD_MINIMUM_REQUIREMENTS']}}</span>
                    <br>
                    <span>
                        Min. 12 characters<br>
                        Min. 1 uppercase letter [A-Z]<br>
                        Min. 1 lowercase letter [a-z]<br>
                        Min. 1 digit [0-9]<br>
                        Min. 1 special character [!, @, #, $, %, ^, &, *, (, ), -, _, +]<br>
                    </span>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm">
                        <div class="row text-center">
                            <form [formGroup]="createForm">
                                <div class="row">
                                    <mat-form-field class="form-group text-center w-100">
                                        <mat-label>{{strings.conStrings['EMAIL']}}</mat-label>
                                        <input matInput type="email" formControlName="email" class="inp form-control input-text small" required>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group text-center w-100">
                                        <mat-label>{{strings.conStrings['PASSWORD']}}</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" (keyup.enter)="createPassword()" class="inp form-control input-text small">
                                        <mat-icon *ngIf="createForm.value.password" style="cursor:pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group text-center w-100">
                                        <mat-label>{{strings.conStrings['REPEAT_NEW_PASSWORD']}}</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="retypePassword" (keyup.enter)="createPassword()" class="inp form-control input-text small">
                                        <mat-icon *ngIf="createForm.value.password" style="cursor:pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </mat-form-field>
                                </div>
                            </form>
                        </div>
                        <div class="row text-center">
                            <div class="col-sm">
                                <button type="button" class="mat-raised-button mat-primary p-1 mt-3 ms-4 me-1" (click)="createPassword()">{{strings.conStrings['SEND']}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main" *ngIf="state === STATE_MAGIC_LOGIN">
        <div class="main-content col-md-5 col-sm-12">
            <div class="mt-2 text-center">
                <div class="col-xs-3">
                    <span style="font-size: medium;font-weight: bold;">{{strings.appStrings['CLICK_FOR_MAGIC_LOGIN']}}</span>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm">
                        <div class="row text-center">
                            <form [formGroup]="magicLoginForm">
                                <mat-form-field class="form-group text-center w-100">
                                    <mat-label>{{strings.conStrings['EMAIL']}}</mat-label>
                                    <input matInput type="email" formControlName="email" class="inp form-control input-text small" (keyup.enter)="requestMagicLogin()" required>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-sm">
                            <button type="button" class="mat-raised-button mat-primary p-1 mt-3 ms-4 me-1" (click)="requestMagicLogin()">{{strings.conStrings['SEND']}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main mt-5" *ngIf="state === STATE_ACCESS_DENIED">
        <div class="row col-md-6 col-sm-12" style="margin: 0 auto;text-align:center">
            <img src="assets/img/access-denied.png" style="width:150px;margin:0 auto;" />
            <div>{{strings.conStrings['ACCESS_DENIED']}}</div>
        </div>
    </div>
</div>