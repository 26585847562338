<div class="m-2" style="text-align: center;">
    <div *ngIf="data.isLoadingStakeholders" class="spinner-border text-primary mt-3"></div>
    <div class="mat-elevation-z8 table-response-md" style="text-align: start;">

        <mat-accordion>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                <mat-panel-title>
                </mat-panel-title>
                </mat-expansion-panel-header>
                    <button type="button" class="mat-raised-button mat-primary p-1" (click)="exportToExcel()">
                        {{strings.appStrings['EXPORT']}}
                    </button>
            </mat-expansion-panel>
        </mat-accordion>

        <div 
            *ngIf="stakeholderService.isLoading" 
            style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner 
                color="primary" 
                mode="indeterminate">
            </mat-progress-spinner>
        </div>

        <table
            mat-table
            matSort
            (matSortChange)="sortTable($event)"
            [dataSource]="dataSource"
            matSortActive="name"
            matSortDirection="desc"
            class="table table-light table-striped table-hover">
        
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="p-1">{{strings.appStrings['NAME']}}</th>
                <td mat-cell *matCellDef="let stakeholder" class="p-1">
                    <span innerHTML="{{stakeholder.name}}"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="contact_name" class="p-1">{{strings.appStrings['NAME']}}</th>
                <td mat-cell *matCellDef="let stakeholder" class="p-1">
                    <span *ngFor="let contact of stakeholder?.contacts">
                        {{contact.name}}<br>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="contact_email" class="p-1">{{strings.appStrings['EMAIL']}}</th>
                <td mat-cell *matCellDef="let stakeholder" class="p-1">
                    <span *ngFor="let contact of stakeholder?.contacts">
                        {{contact.email}}<br>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_phone_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="contact_phone_number" class="p-1">{{strings.appStrings['PHONE_NUMBER']}}</th>
                <td mat-cell *matCellDef="let stakeholder" class="p-1">
                    <span *ngFor="let contact of stakeholder?.contacts">
                        {{contact.phone_number}}<br>
                    </span>
                </td>
            </ng-container>

            <!--<ng-container matColumnDef="contacts">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="contacts" class="p-1">
                    <span>{{strings.appStrings['CONTACT']}}</span>                    
                </th>
                <td mat-cell *matCellDef="let stakeholder" class="p-1">
                    <span *ngFor="let contact of stakeholder?.contacts">
                        <div class="grid-3">
                            <span>{{contact.name}}</span>
                            <span>{{contact.email}}</span>
                            <span>{{contact.phone_number}}</span>
                        </div>
                    </span>
                </td>
            </ng-container>-->

            <ng-container matColumnDef="lane_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="lane_amount" class="p-1">{{strings.appStrings['LANES']}}</th>
                <td mat-cell *matCellDef="let stakeholder" class="p-1">
                    <span *ngIf="stakeholder.lanes.length === 0 || stakeholder.lanes.length > 1">
                        {{stakeholder.lanes.length}} {{strings.appStrings['LANES']}}
                    </span>
                    <span *ngIf="stakeholder.lanes.length === 1">
                        {{stakeholder.lanes.length}} {{strings.appStrings['LANE']}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="stakeholder_wagons">
                <th mat-header-cell *matHeaderCellDef class="edit-column p-1"></th>
                <td mat-cell *matCellDef="let stakeholder" class="edit-column p-1">
                    <span 
                        (click)="searchService.clear(); filterService.wagon.lane = null;" 
                        class="material-icons edit" 
                        matTooltip="{{strings.appStrings['WAGONS']}}" 
                        routerLink="/wagons"
                        [queryParams]="{stakeholder: stakeholder.id, lane: null}">
                        train
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef class="edit-column p-1"></th>
                <td mat-cell *matCellDef="let stakeholder" class="edit-column p-1">
                    <span 
                        class="material-icons edit" 
                        routerLink="/stakeholder/{{stakeholder.id}}"
                        matTooltip="{{strings.appStrings['EDIT']}}">
                        edit
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef class="delete-column p-1"></th>
                <td mat-cell *matCellDef="let stakeholder" class="delete-column p-1">
                    <span 
                        class="material-icons delete" 
                        (click)="deleteStakeholder(stakeholder)"
                        matTooltip="{{strings.appStrings['DELETE']}}">
                        delete
                    </span>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="headers"></tr>
            <tr
                id="stakeholder{{row.id}}"
                mat-row style="height: auto;"
                *matRowDef="let row; columns: headers"></tr>
        </table>

        <mat-paginator 
            #paginator
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[10, 20, 30, 40, 50]"
            (page)="onPageChange($event)">
        </mat-paginator>
    </div>
</div>