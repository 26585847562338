import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface RequestItem {
  request: HttpRequest<unknown>;
  tries: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public readonly APPLICATION = "WIP";
  public readonly PLATFORM = "Browser";
  public readonly LOCATION = environment.apiUrl;
  public failedRequests: RequestItem[] = [];

  public readonly VERIFY = `${this.LOCATION}/verify`;

  constructor() { }
}
