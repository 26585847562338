import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StringService } from '../services/string.service';
import { Router } from '@angular/router';
import { catchError, switchMap, filter, take, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggedInService } from '../services/logged-in.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DialogConfirmComponent } from '../components/dialog/dialog-confirm/dialog-confirm.component';
import { ApiService } from '../services/api.service';
import { Roles } from '../services/privileges.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isDisplayingDialog: boolean = false;
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth: AuthService,
              private strings: StringService,
              private router: Router,
              private loggedIn: LoggedInService,
              private dialog: MatDialog,
              private api: ApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addToken(request, this.auth.getAccessToken());
    return next.handle(request).pipe(tap((event) => {
      if (event instanceof HttpResponse) {
        if (event.status >= 200 && event.status < 400) {
          if (this.api.failedRequests.length) {
            const failedIdx = this.api.failedRequests.findIndex(e => e.request.url === request.url);
            if (failedIdx !== -1) {
              this.api.failedRequests.splice(failedIdx, 1);
            }
          }
        }
      }
    }), catchError((error): Observable<any> => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          if (this.auth.getAccessToken()) {
            if (this.router.url.indexOf('/login/access-denied') === -1) {
              return this.onUnauthorized(request, next);
            } else if (this.loggedIn.loggedIn) {
              this.auth.logout();
            }
          } else if (this.loggedIn.loggedIn) {
            this.auth.logout();
          }
        } else if (error.status === 403) {
          this.router.navigateByUrl('/login/access-denied')
        } else {
          if (error.url.indexOf(environment.ssoUrl) === -1) {
            const failed = this.api.failedRequests.find(e => e.request.url === request.url);
            if (failed) {
              failed.tries++;
              if (failed.tries < 3) {
                return this.intercept(request, next);
              } else {
                const failedIdx = this.api.failedRequests.findIndex(e => e.request.url === request.url);
                if (failedIdx !== -1) {
                  this.api.failedRequests.splice(failedIdx, 1);
                }
              }
            } else {
              this.api.failedRequests.push({ request: request, tries: 1 });
              return this.intercept(request, next);
            }
            if (this.strings.conStrings[error.error.message]) {
              this.openDialog(this.strings.conStrings[error.error.message]);
            } else this.openDialog(error.error.message || error.message);
          } else if (error.url.indexOf(environment.ssoUrl) !== -1 && this.router.url.indexOf('create-password') === -1 && error.status === 400) {
            this.auth.requestAuthCode(location.origin + this.router.url);
          }
          return throwError(() => { return error });
        }
      }
      return throwError(() => { return error });
    }))
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      request = request.clone({
        setHeaders: { 'Authorization': `Bearer ${token}` },
        withCredentials: true
      })
    }
    if (request.url.indexOf(environment.apiUrl) !== -1) {
      request = request.clone({
        setHeaders: { 'Use-Contact-Auth': (this.auth.getRole() == Roles.STAKEHOLDER) ? '1' : '0' }
      })
    }
    return request;
  }

  private onUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.auth.refreshAccessToken().pipe(
        switchMap((response) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.access_token);
          return next.handle(this.addToken(request, response.access_token));
        })
      )
    } else {
      this.isRefreshing = false;
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken))
        })
      )
    }
  }

  openDialog(message: string) {
    if (!this.isDisplayingDialog) {
      this.isDisplayingDialog = true;
      const config = new MatDialogConfig();
      config.autoFocus = true;
      config.closeOnNavigation = true;
      config.width = '500px';
      config.data = { 
        title: this.strings.conStrings['MESSAGE_FROM_SERVER'],
        message: message,
        state: 'OK_OPTION' 
      }
      const dialog = this.dialog.open(DialogConfirmComponent, config);
      dialog.afterClosed().subscribe(() => this.isDisplayingDialog = false)
    }
  }
}
