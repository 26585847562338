import { IStation } from "../interfaces/i-station";

export class Station {

    private _id: number;
    private _station_id: string;
    private _name: string;
    private _zipcode: string;
    private _country_code: string;
    private _country_code_iso: string;

    constructor(obj?: IStation) {
        if (!obj) return;
        this._id = obj.id;
        this._station_id = obj.station_id;
        this._name = obj.name;
        this._zipcode = obj.zipcode;
        this._country_code = obj.country_code;
        this._country_code_iso = obj.country_code_iso;
    }

    toJSON(): Object {
        return {
            id: this._id,
            station_id: this._station_id,
            name: this._name,
            zipcode: this._zipcode,
            country_code: this._country_code,
            country_code_iso: this._country_code_iso
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter station_id
     * @return {string}
     */
	public get station_id(): string {
		return this._station_id;
	}

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter zipcode
     * @return {string}
     */
	public get zipcode(): string {
		return this._zipcode;
	}

    /**
     * Getter country_code
     * @return {string}
     */
	public get country_code(): string {
		return this._country_code;
	}

    /**
     * Getter country_code_iso
     * @return {string}
     */
	public get country_code_iso(): string {
		return this._country_code_iso;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter station_id
     * @param {string} value
     */
	public set station_id(value: string) {
		this._station_id = value;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter zipcode
     * @param {string} value
     */
	public set zipcode(value: string) {
		this._zipcode = value;
	}

    /**
     * Setter country_code
     * @param {string} value
     */
	public set country_code(value: string) {
		this._country_code = value;
	}

    /**
     * Setter country_code_iso
     * @param {string} value
     */
	public set country_code_iso(value: string) {
		this._country_code_iso = value;
	}
    
    
}
