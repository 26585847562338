import { Pipe, PipeTransform } from '@angular/core';
import { Station } from '../classes/station';

@Pipe({
  name: 'stationName'
})
export class StationNamePipe implements PipeTransform {

  transform(stations: Station[], id: number): string {
    return stations.find(e => e.id === id)?.name;
  }

}
