import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';

interface UnderMaintenanceResponse {
  under_maintenance: boolean;
  expected_end: string;
}

@Injectable({
  providedIn: 'root'
})
export class UnderMaintenanceService {

  private _underMaintenance: boolean = false;
  private _expectedEnd: string;

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService) {}

  public get underMaintenance(): boolean {
    return this._underMaintenance;
  }

  public get expectedEnd(): string {
    return this._expectedEnd;
  }

  public get() {
    this.http.get<UnderMaintenanceResponse>(
      `${this.api.LOCATION}/under-maintenance`,
      this.header.getOptions()
    )
    .subscribe((response) => {
      this._underMaintenance = response.under_maintenance;
      this._expectedEnd = response.expected_end;
    })
  }
}
