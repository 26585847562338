<div class="container d-flex justify-content-center form-holder">
    <div class="row text-center justify-content-center w-100">
        <form [formGroup]="form" style="width:100%;font-size: medium;">
            <h4 *ngIf="!editId">{{strings.appStrings['ADD_LANE']}}</h4>
            <h4 *ngIf="editId">{{strings.appStrings['LANE']}} {{lane}}</h4>
            <div class="form-group row justify-content-center w-100">
                <mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['AGREEMENT_NUMBER']}}</mat-label>
                    <input 
                        matInput
                        type="text"
                        formControlName="agreement_number"
                        name="agreement_number"
                        class="form-control"
                        maxlength="30"
                        required>
                </mat-form-field>
                &nbsp;
                <mat-form-field class="form-group col-sm">
                    <mat-label>{{strings.appStrings['TRACK']}}</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="track"
                        name="track"
                        maxlength="30"
                        class="form-control">
                </mat-form-field>
                &nbsp;
                <mat-form-field class="form-group col-sm mt-2">
                    <mat-label>{{strings.appStrings['IS_RECEIVER_ID_KEY']}}</mat-label>
                    <mat-select formControlName="is_receiver_id_key">
                        <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                        <mat-option [value]="0">{{strings.appStrings['NO']}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <h6>{{strings.appStrings['DEPARTURE_STATION']}}</h6>
            <div class="form-group justify-content-center w-100">
                <div class="row">
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['DEPARTURE_STATION']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="departure_station_name"
                            name="departure_station_name"
                            class="form-control"
                            maxlength="50"
                            [matAutocomplete]="departureAuto"
                            required>
                        <mat-autocomplete 
                            #departureAuto="matAutocomplete"
                            (optionSelected)="stationSelected('departure', $event.option.value)">
                            <mat-option *ngFor="let station of filteredDepartureStations | async" [value]="station.name">
                                {{station.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['ID']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="departure_station_station_id"
                            name="departure_station_station_id"
                            class="form-control"
                            maxlength="30"
                            required>
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['ZIPCODE']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="departure_station_zipcode"
                            name="departure_station_zipcode"
                            maxlength="12"
                            class="form-control">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['COUNTRY_CODE']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="departure_station_country_code"
                            name="departure_station_country_code"
                            maxlength="2"
                            class="form-control">
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['COUNTRY_CODE_ISO']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="departure_station_country_code_iso"
                            name="departure_station_country_code_iso"
                            maxlength="2"
                            class="form-control">
                    </mat-form-field>
                </div>
            </div>
            <h6>{{strings.appStrings['ARRIVAL_STATION']}}</h6>
            <div class="form-group justify-content-center w-100">
                <div class="row">
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['NAME']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="arrival_station_name"
                            name="arrival_station_name"
                            class="form-control"
                            maxlength="50"
                            [matAutocomplete]="arrivalAuto"
                            required>
                        <mat-autocomplete 
                            #arrivalAuto="matAutocomplete"
                            (optionSelected)="stationSelected('arrival', $event.option.value)">
                            <mat-option *ngFor="let station of filteredArrivalStations | async" [value]="station.name">
                                {{station.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['ID']}}</mat-label>
                        <input 
                            matInput
                            type="text"
                            formControlName="arrival_station_station_id"
                            name="arrival_station_station_id"
                            class="form-control"
                            maxlength="30"
                            required>
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['ZIPCODE']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="arrival_station_zipcode"
                            name="arrival_station_zipcode"
                            maxlength="12"
                            class="form-control">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['COUNTRY_CODE']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="arrival_station_country_code"
                            name="arrival_station_country_code"
                            maxlength="2"
                            class="form-control">
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['COUNTRY_CODE_ISO']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="arrival_station_country_code_iso"
                            name="arrival_station_country_code_iso"
                            maxlength="2"
                            class="form-control">
                    </mat-form-field>
                </div>
            </div>
            <h6>{{strings.appStrings['RECEIVER']}}</h6>
            <div class="form-group justify-content-center w-100">
                <div class="row">
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['NAME']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="receiver_name"
                            name="receiver_name"
                            class="form-control"
                            maxlength="50"
                            [matAutocomplete]="receiverNameAuto">
                        <mat-autocomplete 
                            #receiverNameAuto="matAutocomplete"
                            (optionSelected)="receiverSelected($event.option.value)">
                            <mat-option *ngFor="let receiver of filteredReceiversByName | async" [value]="receiver.name">
                                {{receiver.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="form-group col-sm">
                        <mat-label>{{strings.appStrings['CUSTOMER_NUMBER']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="receiver_customer_number"
                            name="receiver_customer_number"
                            class="form-control"
                            maxlength="50"
                            [matAutocomplete]="receiverCustomerNumberAuto">
                        <mat-autocomplete 
                            #receiverCustomerNumberAuto="matAutocomplete"
                            (optionSelected)="receiverSelected(null, $event.option.value)">
                            <mat-option *ngFor="let receiver of filteredReceiversByCustomerNumber | async" [value]="receiver.customer_number">
                                {{receiver.customer_number}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <button type="button" class="mat-raised-button mat-primary p-2" (click)="createLane()">
                <div *ngIf="loading"><span class="spinner-border spinner-border-sm"></span>&nbsp;</div>
                <span *ngIf="!editId">{{strings.appStrings['ADD']}}</span>
                <span *ngIf="editId">{{strings.appStrings['UPDATE']}}</span>
            </button>
        </form>
    </div>
</div>

<div *ngIf="editId" class="container d-flex justify-content-center mt-5">
    <button mat-button-raised (click)="addStakeholder()" class="mat-raised-button p-1">{{strings.appStrings['ADD']}} {{strings.appStrings['STAKEHOLDER']}}</button>
</div>

<div *ngIf="lane?.stakeholders.length > 0" class="container d-flex justify-content-center mt-5">
    <div class="text-center justify-content-center w-100">
        <div class="mat-elevation-z8 table-response-md" style="text-align:start">
            <table
                mat-table
                matSort
                (matSortChange)="sortTable($event)"
                [dataSource]="lane?.stakeholders"
                matSortActive="agreement_number"
                matSortDirection="desc"
                class="table table-light table-striped table-hover">
            
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="name">{{strings.appStrings['NAME']}}</th>
                    <td mat-cell *matCellDef="let stakeholder">
                        <span>{{stakeholder.name}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="contacts">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="contacts">{{strings.appStrings['CONTACT']}}</th>
                    <td mat-cell *matCellDef="let stakeholder">
                        <span *ngFor="let contact of stakeholder?.contacts">
                            <div class="grid-3">
                                <span>{{contact.name}}</span>
                                <span>{{contact.email}}</span>
                                <span>{{contact.phone_number}}</span>
                            </div>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="access_track_and_trace">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="access_track_and_trace">{{strings.appStrings['ACCESS_TRACK_AND_TRACE']}}</th>
                    <td mat-cell *matCellDef="let stakeholder">
                        <mat-select (selectionChange)="setAccess(stakeholder, $event.target.value)" [(ngModel)]="stakeholder.access_track_and_trace">
                            <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                            <mat-option [value]="0">{{strings.appStrings['NO']}}</mat-option>
                        </mat-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="access_demurrage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="access_demurrage">{{strings.appStrings['ACCESS_DEMURRAGE']}}</th>
                    <td mat-cell *matCellDef="let stakeholder">
                        <mat-select (selectionChange)="setAccess(stakeholder, $event.value)" [(ngModel)]="stakeholder.access_demurrage">
                            <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                            <mat-option [value]="0">{{strings.appStrings['NO']}}</mat-option>
                        </mat-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="open_stakeholder">
                    <th mat-header-cell *matHeaderCellDef class="edit-column"></th>
                    <td mat-cell *matCellDef="let stakeholder" class="edit-column">
                        <span class="material-icons edit" routerLink="/stakeholder/{{stakeholder.id}}">open_in_browser</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="delete-column"></th>
                    <td mat-cell *matCellDef="let stakeholder" class="delete-column">
                        <span class="material-icons delete" (click)="detachStakeholder(stakeholder)">delete</span>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="headers"></tr>
                <tr
                    id="stakeholder{{row.id}}"
                    mat-row style="height: auto;"
                    *matRowDef="let row; columns: headers"></tr>
            </table>
        </div>
    </div>
</div>

<!--<div #alert class="alert alert-warning hide">
    <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong style="position:relative;">{{alertString}}</strong>
</div>-->