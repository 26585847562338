import { IStakeholderType } from "../interfaces/i-stakeholder-type";

export class StakeholderType {

    private _id: number;
    private _type: string;

    constructor(obj?: IStakeholderType) {
        if (!obj) return;
        this._id = obj.id;
        this._type = obj.type;
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter type
     * @return {string}
     */
	public get type(): string {
		return this._type;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter type
     * @param {string} value
     */
	public set type(value: string) {
		this._type = value;
	}

}
