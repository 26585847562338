import { EventEmitter, Injectable, Output } from '@angular/core';
import { Stakeholder } from '../classes/items';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { IStakeholder } from '../interfaces/i-stakeholder';

@Injectable({
  providedIn: 'root'
})
export class StakeholderService {

  private _stakeholders: Stakeholder[] = null;
  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService) { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get stakeholders(): Stakeholder[] {
    if (!this._stakeholders && !this._isLoading && !this._loadFailed) {
      this.get();
    }
    return this._stakeholders;
  }

  public set stakeholders(stakeholders: Stakeholder[]) {
    this._stakeholders = stakeholders;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public clear() {
    this._stakeholders = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  public get() {
    this._isLoading = true;
    this.http.get<IStakeholder>(
      `${this.api.LOCATION}/stakeholders`,
      this.header.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          this.stakeholders = [];
          for (let elem of response) {
            this._stakeholders.push(new Stakeholder(elem));
          }
          this._isLoading = false;
          this._loadFailed = response.length === 0;
          this.toggle();
        }
      },
      error: () => {
        this._isLoading = false;
        this._loadFailed = true;
      }
    })
  }

  public create(stakeholder: Stakeholder) {
    return this.http.post<any>(
      `${this.api.LOCATION}/stakeholders`,
      { stakeholder: stakeholder.toJSON() },
      this.header.getOptions()
    )
  }

  public update(stakeholder: Stakeholder) {
    return this.http.put<any>(
      `${this.api.LOCATION}/stakeholders`,
      { stakeholder: stakeholder.toJSON() },
      this.header.getOptions()
    )
  }

  public delete(stakeholder: Stakeholder) {
    return this.http.delete<any>(
      ` ${this.api.LOCATION}/stakeholders/${stakeholder.id}`,
      this.header.getOptions()
    )
  }

  public attachLane(stakeholderId: number, laneId: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lane-stakeholder/attach`,
      { stakeholder_id: stakeholderId, lane_id: laneId },
      this.header.getOptions()
    )
  }

  public detachLane(stakeholderId: number, laneId: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lane-stakeholder/detach`,
      { stakeholder_id: stakeholderId, lane_id: laneId },
      this.header.getOptions()
    )
  }

  public setLaneAccess(stakeholderId: number, laneId: number, accessTrackAndTrace: number, accessDemurrage: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/lane-stakeholder/access`,
      { stakeholder_id: stakeholderId, lane_id: laneId, access_track_and_trace: accessTrackAndTrace, access_demurrage: accessDemurrage },
      this.header.getOptions()
    )
  }

  public setNotifications(laneId: number, setNotifications: number) {
    return this.http.put<any>(
      `${this.api.LOCATION}/stakeholders/set-notifications`,
      { lane_id: laneId, set_notifications: setNotifications },
      this.header.getOptions()
    )
  }
}
