import { Component, OnInit } from '@angular/core';
import { LoggedInService } from 'src/app/services/logged-in.service';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  readonly ABOUT = {
    da: "At få godset sikkert frem er altafgørende for os. Vi gør det med miljøet i fokus – og vi bruger vores solide DB-netværk til at gøre det. Vi har 12 railports og to store kombiterminaler i henholdsvis Taulov og Høje Taastrup. Og i vores store DB-netværk kan vi få godset til både Italien og Kina.<br><br>Vi kan levere en transportform, der er grøn – og som udleder meget mindre CO2 end lastbiler. Vi investerer i grønne lokomotiver, og vi kører energibesparende. Miljøet er vigtigt for os, og vi prioriterer det hver eneste dag.",
    en: "Getting your freight safely to its destination is all-important to us. We focus on the environment- and work closely with our extensive DB network to fulfil this mission. We have 12 rail ports, as well as large combination terminals in Taulov and Høje Taastrup. Drawing on the power of our large DB network, we can get your freight to destinations in Italy, China and beyond.<br><br>We can deliver a green transport solution that emits much less CO2 than road transport. We invest in green locomotives and operate our trains according to energy-saving practices. The environment is important to us and we prioritise it every single day."
  }

  constructor(public strings: StringService,
              public loggedIn: LoggedInService) { }

  ngOnInit(): void {
  }

  public getAbout(): string {
    let lang = navigator.language;
    if (lang == 'da') return this.ABOUT.da;
    else return this.ABOUT.en;
  }

}
