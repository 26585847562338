import { IContainer } from "../interfaces/i-container";

export class Container {

    private _id: number;
    private _nhm: string;
    private _itu_number: string;
    private _width: number;
    private _height: number;
    private _length: number;
    private _length_code: number;
    private _gross_weight: number;
    private _description: string;
    private _comment: string;

    constructor(obj?: IContainer) {
        if (!obj) return;
        this._id = obj.id;
        this._nhm = obj.nhm;
        this._itu_number = obj.itu_number;
        this._width = obj.width;
        this._height = obj.height;
        this._length = obj.length;
        this._length_code = obj.length_code;
        this._gross_weight = obj.gross_weight;
        this._description = obj.description;
        this._comment = obj.comment;
    }
    
    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter itu_number
     * @return {string}
     */
	public get itu_number(): string {
		return this._itu_number;
	}

    /**
     * Getter width
     * @return {number}
     */
	public get width(): number {
		return this._width;
	}

    /**
     * Getter height
     * @return {number}
     */
	public get height(): number {
		return this._height;
	}

    /**
     * Getter length
     * @return {number}
     */
	public get length(): number {
		return this._length;
	}

    /**
     * Getter length_code
     * @return {number}
     */
	public get length_code(): number {
		return this._length_code;
	}

    /**
     * Getter gross_weight
     * @return {number}
     */
	public get gross_weight(): number {
		return this._gross_weight;
	}

    /**
     * Getter description
     * @return {string}
     */
	public get description(): string {
		return this._description;
    }
    
    /**
     * Getter comment
     * @return {string}
     */
	public get comment(): string {
		return this._comment;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter nhm
     * @param {string} value
     */
	public set nhm(value: string) {
		this._nhm = value;
	}

    /**
     * Setter itu_number
     * @param {string} value
     */
	public set itu_number(value: string) {
		this._itu_number = value;
	}

    /**
     * Setter width
     * @param {number} value
     */
	public set width(value: number) {
		this._width = value;
	}

    /**
     * Setter height
     * @param {number} value
     */
	public set height(value: number) {
		this._height = value;
	}

    /**
     * Setter length
     * @param {number} value
     */
	public set length(value: number) {
		this._length = value;
	}

    /**
     * Setter length_code
     * @param {number} value
     */
	public set length_code(value: number) {
		this._length_code = value;
	}

    /**
     * Setter gross_weight
     * @param {number} value
     */
	public set gross_weight(value: number) {
		this._gross_weight = value;
	}

    /**
     * Setter description
     * @param {string} value
     */
	public set description(value: string) {
		this._description = value;
    }

    /**
     * Setter comment
     * @param {string} value
     */
	public set comment(value: string) {
		this._comment = value;
    }
    
    public toString(): string {
        return `
            ${this._nhm}
            ${this._itu_number}
            ${this._description}
            ${this._gross_weight}
            ${this._length}
            ${this._width}
            ${this._height}
            ${this._length_code}
        `.replace(/\s+/g, '');
    }

}
