<div class="m-2" style="text-align: center;">
    <div 
        *ngIf="data.isLoadingArrivalHistory"
        class="spinner-border text-primary mt-3">
    </div>

    <div class="mat-elevation-z8 table-response-md" style="text-align: start;">
        <mat-accordion>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                <mat-panel-title>
                    {{strings.appStrings['FILTER']}}
                    <mat-icon>filter_alt</mat-icon>
                </mat-panel-title>
                <mat-panel-description>
                    <strong>{{strings.appStrings['ACTIVE_FILTERS']}}:</strong>
                    &nbsp;
                    <span *ngIf="filterService.arrivalHistory.departure_from">
                        {{strings.appStrings['DEPARTURE_FROM']}}:
                        {{filterService.arrivalHistory.departure_from | date : 'yyyy-MM-dd'}}
                        <span *ngIf="filterService.arrivalHistory.departure_to || filterService.arrivalHistory.arrival_from || filterService.arrivalHistory.arrival_to || filterService.arrivalHistory.departure_country || filterService.arrivalHistory.arrival_country || (privileges.isAdminOrInternalUser && (filterService.arrivalHistory.stakeholder || filterService.arrivalHistory.type))">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.arrivalHistory.departure_to">
                        {{strings.appStrings['DEPARTURE_TO']}}:
                        {{filterService.arrivalHistory.departure_to | date : 'yyyy-MM-dd'}}
                        <span *ngIf="filterService.arrivalHistory.arrival_from || filterService.arrivalHistory.arrival_to || filterService.arrivalHistory.departure_country || filterService.arrivalHistory.arrival_country || (privileges.isAdminOrInternalUser && (filterService.arrivalHistory.stakeholder || filterService.arrivalHistory.type))">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.arrivalHistory.arrival_from">
                        {{strings.appStrings['ARRIVAL_FROM']}}:
                        {{filterService.arrivalHistory.arrival_from | date : 'yyyy-MM-dd'}}
                        <span *ngIf="filterService.arrivalHistory.arrival_to || filterService.arrivalHistory.departure_country || filterService.arrivalHistory.arrival_country || (privileges.isAdminOrInternalUser && (filterService.arrivalHistory.stakeholder || filterService.arrivalHistory.type))">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.arrivalHistory.arrival_to">
                        {{strings.appStrings['ARRIVAL_TO']}}:
                        {{filterService.arrivalHistory.arrival_to | date : 'yyyy-MM-dd'}}
                        <span *ngIf="filterService.arrivalHistory.departure_country || filterService.arrivalHistory.arrival_country || (privileges.isAdminOrInternalUser && (filterService.arrivalHistory.stakeholder || filterService.arrivalHistory.type))">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.arrivalHistory.departure_country">
                        {{strings.appStrings['DEPARTURE_COUNTRY']}}:
                        {{filterService.arrivalHistory.departure_country}}
                        <span *ngIf="filterService.arrivalHistory.arrival_country || (privileges.isAdminOrInternalUser && (filterService.arrivalHistory.stakeholder || filterService.arrivalHistory.type))">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.arrivalHistory.arrival_country">
                        {{strings.appStrings['ARRIVAL_COUNTRY']}}:
                        {{filterService.arrivalHistory.arrival_country}} 
                        <span *ngIf="(privileges.isAdminOrInternalUser && (filterService.arrivalHistory.stakeholder || filterService.arrivalHistory.type))">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.arrivalHistory.stakeholder">
                        {{strings.appStrings['STAKEHOLDER']}}: 
                        {{stakeholder?.name}}
                        <span *ngIf="privileges.isAdminOrInternalUser && filterService.arrivalHistory.type">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.arrivalHistory.type">
                        {{strings.appStrings['TRANSPORT_TYPE']}}:
                        {{strings.appStrings[filterService.wagon.type?.toUpperCase()]}}
                    </span>
                </mat-panel-description>
                <div class="led-box" style="position:absolute; right: 0;">
                    <div class="led led-green"></div>
                    <span>{{strings.appStrings['LAST_UPDATED']}}: {{arrivalHistoryService.lastUpdated | date: 'HH:mm'}}</span>
                </div>
                </mat-expansion-panel-header>
                <section>
                    <mat-form-field class="col-xs-6">
                        <mat-label>{{strings.appStrings['DEPARTURE_INTERVAL']}}</mat-label>
                        <mat-date-range-input [rangePicker]="departure">
                            <input [ngModel]="filterService.arrivalHistory.departure_from" (dateChange)="filterService.arrivalHistory.departure_from = $event?.target?.value?.format('YYYY-MM-DD')" matStartDate placeholder="{{strings.appStrings['DEPARTURE_FROM']}}">
                            <input [ngModel]="filterService.arrivalHistory.departure_to" (dateChange)="filterService.arrivalHistory.departure_from = $event?.target?.value?.format('YYYY-MM-DD')" matEndDate placeholder="{{strings.appStrings['DEPARTURE_TO']}}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="departure"></mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix (click)="filterService.arrivalHistory.departure_from = null;filterService.arrivalHistory.departure_to = null;">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #departure></mat-date-range-picker>
                    </mat-form-field>
    
                    <mat-form-field class="col-xs-6 ms-2">
                        <mat-label>{{strings.appStrings['ARRIVAL_INTERVAL']}}</mat-label>
                        <mat-date-range-input [rangePicker]="arrival">
                            <input [ngModel]="filterService.arrivalHistory.arrival_from" (dateChange)="filterService.arrivalHistory.arrival_from = $event?.target?.value?.format('YYYY-MM-DD')" matStartDate placeholder="{{strings.appStrings['ARRIVAL_FROM']}}">
                            <input [ngModel]="filterService.arrivalHistory.arrival_to" (dateChange)="filterService.arrivalHistory.arrival_to = $event?.target?.value?.format('YYYY-MM-DD')" matEndDate placeholder="{{strings.appStrings['ARRIVAL_TO']}}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="arrival"></mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix (click)="filterService.arrivalHistory.arrival_from = null;filterService.arrivalHistory.arrival_to = null;">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #arrival></mat-date-range-picker>
                    </mat-form-field>
                </section>

                <section>
                    <mat-form-field class="col-xs-6">
                        <mat-label>{{strings.appStrings['DEPARTURE_COUNTRY']}}</mat-label>
                        <mat-select [(value)]="filterService.arrivalHistory.departure_country">
                            <mat-option [value]="null"></mat-option>
                            <mat-option *ngFor="let country of data.countries | async" [value]="country">{{country}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                
                    <mat-form-field class="col-xs-6 ms-2">
                        <mat-label>{{strings.appStrings['ARRIVAL_COUNTRY']}}</mat-label>
                        <mat-select [(value)]="filterService.arrivalHistory.arrival_country" [disabled]="!privileges.isAdminOrInternalUser">
                            <mat-option [value]="null"></mat-option>
                            <mat-option *ngFor="let country of data.countries | async" [value]="country">{{country}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>

                <section>
                    <mat-form-field class="col-cs-6" *ngIf="privileges.isAdminOrInternalUser">
                        <mat-label>{{strings.appStrings['TRANSPORT_TYPE']}}</mat-label>
                        <mat-select (selectionChange)="filterService.arrivalHistory.type = $event.value" [value]="transportType" class="filter-select">
                            <mat-option [value]="null"></mat-option>
                            <mat-option value="intermodal">{{strings.appStrings['INTERMODAL']}}</mat-option>
                            <mat-option value="single_wagon">{{strings.appStrings['SINGLE_WAGON']}}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <mat-form-field class="col-xs-6 ms-2" *ngIf="privileges.isAdminOrInternalUser">
                        <mat-label>{{strings.appStrings['STAKEHOLDER']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            maxlength="50"
                            [matAutocomplete]="stakeholderAuto"
                            (input)="stakeholderChanged($event.target.value)"
                            style="color:white"
                            [ngModel]="stakeholder?.name">
                        <mat-autocomplete
                            #stakeholderAuto="matAutocomplete"
                            (optionSelected)="stakeholderChanged($event.option.value)">
                            <mat-option
                                *ngFor="let stakeholder of filteredStakeholders"
                                [value]="stakeholder">
                                {{stakeholder.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <button mat-button *ngIf="stakeholderControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="stakeholderChanged('')">
                            <mat-icon>close</mat-icon>
                            </button>
                    </mat-form-field>

                    <mat-form-field class="col-xs-6 ms-2" style="width:400px" *ngIf="privileges.isAdminOrInternalUser">
                        <mat-label>{{strings.appStrings['LANE']}}</mat-label>
                        <input
                            matInput
                            type="text"
                            maxlength="50"
                            [matAutocomplete]="laneAuto"
                            (input)="laneChanged($event.target.value)"
                            [ngModel]="(lane) ? lane?.agreement_number + ' ' + lane?.departure_station?.name + ' - ' + lane?.arrival_station?.name : ''"
                            style="color:white;width: 400px">
                        <mat-autocomplete
                            #laneAuto="matAutocomplete"
                            [panelWidth]="400"
                            (optionSelected)="laneChanged($event.option.value)">
                            <mat-option
                                *ngFor="let lane of filteredLanes"
                                [value]="lane">
                                <span style="font-size: small;">{{lane.agreement_number}}</span>
                                <span style="color:cornflowerblue;font-size: small;">&nbsp;&nbsp;&nbsp;{{lane.departure_station?.name}} - {{lane.arrival_station?.name}}</span>
                                <span *ngIf="lane.sender?.id && lane.receiver?.id" style="color: chocolate;font-size: small;">&nbsp;&nbsp;&nbsp;{{lane.sender?.name}} - {{lane.receiver?.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <button mat-button *ngIf="laneControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="laneChanged('')">
                            <mat-icon>close</mat-icon>
                            </button>
                    </mat-form-field>
                </section>
                <br>
                <button type="button" class="mat-raised-button mat-primary p-1" (click)="exportToExcel()">
                    {{strings.appStrings['EXPORT']}}
                </button>
            </mat-expansion-panel>
        </mat-accordion>

        <div 
            *ngIf="data.isLoadingArrivalHistory" 
            style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner 
                color="primary" 
                mode="indeterminate">
            </mat-progress-spinner>
        </div>

        <mat-paginator 
            #paginatorTop
            [pageIndex]="0"
            [pageSize]="10"
            [length]="dataSource.data.length"
            [pageSizeOptions]="[10, 20, 30, 40, 50]"
            (page)="onPageChangeTop($event);">
        </mat-paginator>

        <table
            mat-table
            [dataSource]="dataSource"
            class="table table-light table-striped table-hover">

            <ng-container matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef class="p-1 d-none d-xl-table-cell" [matTooltip]="strings.appStrings['SELECT_ALL_IN_CURRENT_PAGE']" [matTooltipPosition]="'left'">
                    <mat-checkbox *ngIf="privileges.canEditArrivalHistoryComment" [(ngModel)]="allSelected" (change)="checkEntirePage($event.checked)"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-xl-table-cell">
                    <mat-checkbox *ngIf="privileges.canEditArrivalHistoryComment" [(ngModel)]="wagon.selected"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="wagon_number">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['WAGON_NUMBER']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span>{{elem.wagon_number}}</span>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="container">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    class="p-1">
                    {{strings.appStrings['CONTAINER']}}
                </th>
                <td 
                    mat-cell 
                    *matCellDef="let elem" 
                    class="p-1 text right">
                    <span *ngIf="elem.itu_number != elem.wagon_number">{{elem.itu_number}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="departure_time">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['DEPARTURE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span>{{elem.departure_time | date: 'yyyy-MM-dd'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="arrival_time">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['ARRIVED']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span>{{elem.arrival_time | date: 'yyyy-MM-dd'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="wagon_type">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['WAGON_TYPE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span>{{elem.wagon_type}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="agreement_number">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['AGREEMENT_NUMBER']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span>{{elem.lane?.agreement_number}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="departure_station">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['DEPARTURE_STATION']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span innerHTML="{{elem.lane?.departure_station?.name}}"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="arrival_station">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['ARRIVAL_STATION']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    <span innerHTML="{{elem.lane?.arrival_station?.name}}"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="sender">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1 d-none d-xl-table-cell">
                    {{strings.appStrings['SENDER']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1 truncate-cell d-none d-xl-table-cell"
                    [matTooltip]="elem.lane.sender?.name"
                    [matTooltipPosition]="'left'">
                    <span
                        class="break-all"
                        *ngIf="elem.lane.sender?.name?.length > 10">
                        {{elem.lane.sender?.name | slice : 0 : 9}}
                    </span>
                    <span
                        class="break-all"
                        *ngIf="elem.lane.sender?.name?.length <= 10">
                        {{elem.lane.sender?.name}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['FREIGHT_TYPE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1 truncate-cell"
                    [matTooltip]="elem.description"
                    [matTooltipPosition]="'left'">
                    <span innerHTML="{{elem.description}}"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1">
                    {{strings.appStrings['SENDER_REFERENCE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1">
                    {{elem.sender_ref}}
                </td>
            </ng-container>

            <ng-container matColumnDef="info">
                <th mat-header-cell *matHeaderCellDef class="p-1"></th>
                <td mat-cell *matCellDef="let elem" class="p-1">
                    <span
                        [matTooltip]="getItemInformation(elem)"
                        [matTooltipPosition]="'left'"
                        class="material-icons btn-icon">
                        info
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="registrations">
                <th mat-header-cell *matHeaderCellDef class="p-1"></th>
                <td mat-cell *matCellDef="let elem" class="p-1">
                    <div *ngIf="elem.is_loading_registrations">
                        <mat-progress-spinner 
                            color="primary" 
                            mode="indeterminate"
                            [diameter]="25"
                            style="background: rgba(0,0,0,0);">
                        </mat-progress-spinner>
                    </div>
                    <span
                        *ngIf="!elem.is_loading_registrations"
                        class="material-icons btn-icon"
                        (click)="openRegistrations(elem)">
                        history
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="message">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1 d-none d-md-table-cell">
                </th>
                <td
                    mat-cell
                    *matCellDef="let elem"
                    class="p-1 d-none d-md-table-cell"
                    [matTooltip]="elem.comment"
                    [matTooltipPosition]="'left'">
                    <span 
                        *ngIf="elem.comment"
                        [class.blink]="!elem.hovering" 
                        class="material-icons btn-icon reg"
                        (mouseover)="elem.hovering=true">
                        message
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="headers; sticky: true"></tr>
            <tr
                mat-row
                style="height:auto;"
                *matRowDef="let row; columns: headers"
                (contextmenu)="onContextMenu($event, row)">
            </tr>
        </table>

        <div
            style="visibility: hidden; position: fixed"
            [style.left.px]="contextMenuPositionX"
            [style.top.px]="contextMenuPositionY"
            [matMenuTriggerFor]="contextMenu">
        </div>

        <span
            style="position: fixed;"
            #spanTrigger
            class="context-menu-trigger"
            #matTrigger="matMenuTrigger"
            [matMenuTriggerFor]="contextMenu">
            <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                    <button mat-menu-item (click)="setComment(item)">
                        <span 
                            class="material-icons">
                            record_voice_over
                        </span>
                        {{strings.appStrings['EDIT_COMMENT']}}
                    </button>
                </ng-template>
            </mat-menu>
        </span>
    </div>
</div>
