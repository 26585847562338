import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, ViewChildren } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';
import { StringService } from 'src/app/services/string.service';
import { PrivilegesService } from '../../services/privileges.service';
import { SearchService } from 'src/app/services/search.service';
import { forkJoin, interval, Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Wagon } from 'src/app/classes/wagon';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { WagonService } from 'src/app/services/wagon.service';
import { DialogRegistrationsComponent } from '../dialog/dialog-registrations/dialog-registrations.component';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { first, map, mergeMap, startWith } from 'rxjs/operators';
import { FilterRoutes, FilterService } from  '../../services/filter.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RowColorService } from '../../services/row-color.service';
import * as XLSX from 'xlsx';
import { DialogEditCommentComponent } from '../dialog/dialog-edit-comment/dialog-edit-comment.component';
import { Lane, Stakeholder } from 'src/app/classes/items';
import { DateSortPipe } from '../../pipes/date-sort.pipe';
import { Registration } from 'src/app/classes/registration';
import { DialogEndJourneyComponent } from '../dialog/dialog-end-journey/dialog-end-journey.component';
import { Container } from 'src/app/classes/container';
import { NotificationService } from '../../services/notification.service';
import { SwipeService } from 'src/app/services/swipe.service';
import { EtaPipe } from 'src/app/pipes/eta.pipe';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { MatMenuTrigger } from '@angular/material/menu';
import { Station } from 'src/app/classes/station';
import { SenderReceiver } from 'src/app/classes/sender-receiver';

@Component({
  selector: 'app-wagons',
  templateUrl: './wagons.component.html',
  styleUrls: ['./wagons.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [ 
    EtaPipe ,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: 'DD/MM',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
      },
    } }
  ]
})
export class WagonsComponent implements OnInit {
  @ViewChild('eta', { static: false }) eta: ElementRef;
  @ViewChild(MatPaginator) paginatorTop: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatMenuTrigger) public contextMenu: MatMenuTrigger;
  public totalLength: number = 0;
  private inputSub: Subscription;
  private wagonSub: Subscription;
  private stringsSub: Subscription;
  private updateWagonsSub: Subscription;
  public contextMenuPositionX: number = 0;
  public contextMenuPositionY: number = 0;
  public selectedRowIdx: number;
  @HostListener('document:keydown', ['$event'])
    handleKeyBoardEvent(event: KeyboardEvent) {
      switch (event.code) {
        case 'ArrowRight':
          this.paginatorTop?.nextPage();
          break;
        case 'ArrowLeft':
          this.paginatorTop?.previousPage();
          break;
      }
    }

  headers = [
    'selected', 'unit', 'wagon_number', 'container', 'expected_arrival', 'location',
    'registrations', 'departure_station',
    'arrival_station', 
    'wagon_type', 
    'agreement_number', 'sender', 'description', 'reference', 'info', 'message'
  ];
  dataSource = new MatTableDataSource<Wagon>();
  //locationControl = new FormControl();
  //filteredLocations: Observable<string[]>;
  //locations: string[];
  private pipe: DatePipe;
  private sortDatePipe: DateSortPipe;
  private etaPipe: EtaPipe;
  //private locationSub: Subscription;
  private stakeholderSub: Subscription;
  private privilegesSub: Subscription;
  private laneSub: Subscription;
  public stakeholder: Stakeholder;
  public lane: Lane;
  public allSelected: boolean;
  public stakeholderControl = new FormControl();
  public filteredStakeholders: Stakeholder[];
  public laneControl = new FormControl();
  public filteredLanes: Lane[];
  public locationSub: Subscription;
  public transportType: string;
  public transportTypeSub: Subscription;
  public filteredDepartureStations: Observable<Station[]>;
  public filteredArrivalStations: Observable<Station[]>;
  public filteredReceivers: Observable<SenderReceiver[]>;
  public filteredLocations: Observable<Station[]>;
  stationFilterForm: FormGroup;
  receiverFilterForm: FormGroup;
  locationFilterForm: FormGroup;

  constructor(public data: DataService,
              public strings: StringService,
              public privileges: PrivilegesService,
              private searchService: SearchService,
              private dialog: MatDialog,
              public wagonService: WagonService,
              public filterService: FilterService,
              private route: ActivatedRoute,
              private router: Router,
              public rowColorService: RowColorService,
              private dateSortPipe: DateSortPipe,
              private notificationService: NotificationService,
              private swipeService: SwipeService,
              private fb: FormBuilder) {
                this.dataSource = new MatTableDataSource<Wagon>([]);
                this.pipe = new DatePipe('en');
                this.etaPipe = new EtaPipe();
              }

  ngOnInit() {
    this.privilegesSub = this.privileges.change.subscribe(() => { if (this.privileges.isAdminOrInternalUser) this.setupAdminSubscriptions() });
    if (this.privileges.isAdminOrInternalUser) {
      this.setupAdminSubscriptions();
    }
    //subscribe to changes in search field
    this.inputSub = this.searchService.change.subscribe((value) => { this.dataSource.filter = value })
    //subscribe to changes in strings
    this.stringsSub = this.strings.change.subscribe(() => {
      if (this.dataSource.paginator) {
        this.dataSource.paginator._intl = this.strings.getPaginatorIntl();
      }
    })
    const i = interval(1000 * 60);
    this.updateWagonsSub = i.subscribe(() => { 
      this.wagonService.update();
    })
    this.swipeService.swipeNext.subscribe(() => this.paginatorTop?.nextPage())
    this.swipeService.swipePrevious.subscribe(() => this.paginatorTop?.previousPage())
    this.stationFilterForm = this.fb.group({
      departure_station: new FormControl(),
      arrival_station: new FormControl()
    })
    this.receiverFilterForm = this.fb.group({
      receiver: new FormControl()
    })
    this.locationFilterForm = this.fb.group({
      location: new FormControl()
    })
    this.filteredDepartureStations = this.stationFilterForm.controls.departure_station.valueChanges.pipe(
      startWith([]),
      map((value) => {
        if (!value || value instanceof Array) { this.filterService.wagon.departure_station = null; return this.data.stations?.slice(0, 10) }
        if (value instanceof Station) { this.filterService.wagon.departure_station = value.id; return; }
        const stations: Station[] = [];
        this.data.wagons.forEach((wagon: Wagon) => {
          if (!stations.some(s => s.id == wagon.lane.departure_station.id)) {
            stations.push(wagon.lane.departure_station)
          }
        })
        return stations.filter((station: Station) => {
          return `
            ${station.country_code}
            ${station.station_id}
            ${station.name}
            ${station.country_code_iso}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })?.slice(0, 10);
      })
    )
    this.filteredArrivalStations = this.stationFilterForm.controls.arrival_station.valueChanges.pipe(
      startWith([]),
      map((value) => {
        if (!value || value instanceof Array) { this.filterService.wagon.arrival_station = null; return this.data.stations?.slice(0, 10) }
        if (value instanceof Station) { this.filterService.wagon.arrival_station = value.id; return; }
        const stations: Station[] = [];
        this.data.wagons.forEach((wagon: Wagon) => {
          if (!stations.some(s => s.id == wagon.lane.arrival_station.id)) {
            stations.push(wagon.lane.arrival_station)
          }
        })
        return stations.filter((station: Station) => {
          return `
            ${station.country_code}
            ${station.station_id}
            ${station.name}
            ${station.country_code_iso}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })?.slice(0, 10);
      })
    )
    this.filteredReceivers = this.receiverFilterForm.controls.receiver.valueChanges.pipe(
      startWith([]),
      map((value) => {
        if (!value || value instanceof Array) { this.filterService.wagon.receiver = null; return [] }
        if (value instanceof SenderReceiver) { this.filterService.wagon.receiver = value.id; return; }
        const receivers: SenderReceiver[] = [];
        this.data.wagons.forEach((wagon: Wagon) => {
          if (!receivers.some(r => r.id == wagon.lane.receiver.id)) {
            receivers.push(wagon.lane.receiver)
          }
        })
        return receivers.filter((receiver: SenderReceiver) => {
          return `
            ${receiver.name}
            ${receiver.customer_number}
            ${receiver.address}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })?.slice(0, 10);
      })
    );
    this.filteredLocations = this.locationFilterForm.controls.location.valueChanges.pipe(
      startWith([]),
      map((value) => {
        if (!value || value instanceof Array) { this.filterService.wagon.location = null; return [] }
        if (value instanceof Station) { this.filterService.wagon.location = value.id; return; }
        const locations: Station[] = [];
        this.data.wagons.forEach((wagon: Wagon) => {
          if (wagon.registrations.length > 0) {
            const location = wagon.registrations.reduce((a, b) => { return a.registered_time > b.registered_time ? a : b }).station;
            if (!locations.some(l => l.id === location.id)) {
              locations.push(location);
            }
          }
        })
        return locations.filter((location: Station) => {
          return `
            ${location.country_code}
            ${location.station_id}
            ${location.name}
            ${location.country_code_iso}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })?.slice(0, 10);
      })
    )
  }

  private setupAdminSubscriptions() {
    this.stakeholderSub = this.route.queryParams.pipe(
      mergeMap((params) => {
        if (params.stakeholder) return this.data.stakeholder(params.stakeholder);
        else return [];
      })
    ).subscribe((stakeholder?: Stakeholder) => { 
      this.stakeholder = stakeholder; 
      if (stakeholder) { this.filterService.wagon.stakeholder = this.stakeholder?.id || null }
    })
    this.laneSub = this.route.queryParams.pipe(
      mergeMap((params) => {
        if (params.lane) return this.data.lane(params.lane)
        else this.lane = null; return [];
      })
    ).subscribe((lane?: Lane) => {
      this.lane = lane; 
      if (lane) this.filterService.wagon.lane = this.lane?.id || null 
    })
    this.transportTypeSub = this.route.queryParams.subscribe(params => this.transportType = params.type || null)
  }

  public stakeholderChanged(value) {
    if (typeof value === 'string' && value === '') {
      this.stakeholder = null; 
      if (this.filterService.wagon.stakeholder) this.filterService.wagon.stakeholder = null;
      this.stakeholderControl.setValue('');
    } else if (typeof value === 'object') { 
      this.stakeholder = value; 
      this.filterService.wagon.stakeholder = this.stakeholder.id;
    } else {
      this.filteredStakeholders = value ? this.data.filterStakeholders(value) : this.data.stakeholders?.slice()
    }
  }

  public laneChanged(value) {
    if (typeof value === 'string' && value === '') {
      this.lane = null; 
      if (this.filterService.wagon.lane) this.filterService.wagon.lane = null;
      this.laneControl.setValue('');
    } else if (typeof value === 'object') { 
      this.lane = value; 
      this.filterService.wagon.lane = this.lane.id;
    } else {
      this.filteredLanes = value ? this.data.filterLanes(value) : this.data.lanes?.slice()
    }
  }

  onContextMenu(event: MouseEvent, wagon: Wagon) {
    event.preventDefault();
    this.contextMenuPositionX = event.clientX;
    this.contextMenuPositionY = event.clientY;
    this.contextMenu.menuData = { item: wagon };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    this.selectedRowIdx = wagon.id;
  }

  ngDoCheck() {
    const wagonChanges = this.filterService.differ.wagon.diff(this.filterService.wagon);
    if (wagonChanges) {
      this.filterService.updateFilter(FilterRoutes.Wagon, this.filterService.wagon);
    }
  }

  ngOnDestroy() {
    if (this.inputSub) this.inputSub.unsubscribe();
    if (this.wagonSub) this.wagonSub.unsubscribe();
    if (this.locationSub) this.locationSub.unsubscribe();
    if (this.stringsSub) this.stringsSub.unsubscribe();
    if (this.updateWagonsSub) this.updateWagonsSub.unsubscribe();
    if (this.stakeholderSub) this.stakeholderSub.unsubscribe();
    if (this.laneSub) this.laneSub.unsubscribe();
    if (this.transportTypeSub) this.transportTypeSub.unsubscribe();
    if (this.privilegesSub) this.privilegesSub.unsubscribe();
    this.filterService.resetWagonFilter();
  }

  ngAfterViewInit() {
    this.setDataSource(this.dataSource, this.paginatorTop);
    this.wagonSub = this.data.observeWagons().subscribe(() => {
      this.dataSource.data = this.data.wagons || [];
      if (this.data.wagons && this.data.wagons.length > 0) {
        //sort data
        if (this.route.snapshot.queryParamMap.get('sort')) {
          this.sortTable({
            active: this.route.snapshot.queryParamMap.get('sort'),
            direction: (this.route.snapshot.queryParamMap.get('direction') === 'desc') ? 'desc' : 'asc'
          })
        } else {
          this.sortTable({active: 'expected_arrival', direction: 'asc'})
        }
      } else {
        this.dataSource.data = [];
      }
    })
  }

  private setDataSource(dataSource: MatTableDataSource<Wagon>, paginator: MatPaginator) {
    dataSource.paginator  = paginator;
    dataSource.paginator._intl = this.strings.getPaginatorIntl();
    dataSource.filter = this.searchService.searchString;
    dataSource.filterPredicate = (data, filter: string)  => {
      const accumulator = (currentTerm, key) => {
        // Read dates as utc and convert to local time.
        switch(key) {
          case '_extected_arrival': {
            if (this.dateIsValid(data.expected_arrival)) {
              return currentTerm + `
                ${moment.utc(data.expected_arrival).local().format('DD-MM-YYYY')}
                ${moment.utc(data.expected_arrival).local().format('YYYY-MM-DD')}
                ${moment.utc(data.expected_arrival).local().format('DD.MM.YYYY')}
                ${moment.utc(data.expected_arrival).local().format('YYYY.MM.DD')}
                ${moment.utc(data.expected_arrival).local().format('DDMMYYYY')}
                ${moment.utc(data.expected_arrival).local().format('YYYYMMDD')}
              `.replace(/\s+/g, '');
            } else return currentTerm + data[key];
          }
          case '_expected_departure': {
            if (this.dateIsValid(data.registrations[0]?.registered_time)) {
              return  currentTerm + `
                ${moment.utc(data.registrations[0]?.registered_time).local().format('DD-MM-YYYY')}
                ${moment.utc(data.registrations[0]?.registered_time).local().format('YYYY-MM-DD')}
                ${moment.utc(data.registrations[0]?.registered_time).local().format('DD.MM.YYYY')}
                ${moment.utc(data.registrations[0]?.registered_time).local().format('YYYY.MM.DD')}
                ${moment.utc(data.registrations[0]?.registered_time).local().format('DDMMYYYY')}
                ${moment.utc(data.registrations[0]?.registered_time).local().format('YYYYMMDD')}
              `.replace(/\s+/g, '');
            } else return currentTerm + data[key]
          }
          default: return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  onPageChangeTop(event: PageEvent) {
    let startIdx = this.paginatorTop.pageSize * this.paginatorTop.pageIndex;
    let endIdx = startIdx + this.paginatorTop.pageSize - 1;
    let selected = true;
    for (let i=startIdx; i<=endIdx; i++) {
      if (this.dataSource.data[i]) {
        if (!this.dataSource.data[i].selected) {
          selected = false;
          break;
        }
      } else break;
    }
    this.allSelected = selected;
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: { page: event.pageIndex, keep: 1 },
        queryParamsHandling: 'merge'
      });
  }

  sortTable(sort: Sort) {
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: { sort: sort.active, direction: sort.direction },
        queryParamsHandling: 'merge'
      });
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'wagon_number': return this.compare(a.wagon_number, b.wagon_number, isAsc);
        case 'agreement_number': return this.compare(a.lane.agreement_number, b.lane.agreement_number, isAsc);
        case 'departure_station': return this.compare(a.lane.departure_station.name, b.lane.departure_station.name, isAsc);
        case 'arrival_station': return this.compare(a.lane.arrival_station.name, b.lane.arrival_station.name, isAsc);
        case 'expected_arrival': return this.compareExpectedArrival(a, b, isAsc);
        case 'expected_departure': return this.compare(a.registrations[0]?.registered_time?.getTime() || Number.MAX_SAFE_INTEGER, b.registrations[0]?.registered_time?.getTime() || Number.MAX_SAFE_INTEGER, isAsc);
        case 'container': return this.compare(a.containers[0].itu_number, b.containers[0].itu_number, isAsc);
        case 'unit': return this.compare(a.containers[0]?.itu_number, b.containers[0]?.itu_number, isAsc);
        case 'size': return this.compare(a.size, b.size, isAsc);
        case 'netto_weight': return this.compare(a.netto_weight, b.netto_weight, isAsc);
        case 'brutto_weight': return this.compare(a.brutto_weight, b.brutto_weight, isAsc);
        case 'tare': return this.compare(a.tare, b.tare, isAsc);
        case 'rid': return this.compare(a.rid, b.rid, isAsc);
        case 'is_empty': return this.compare(a.is_empty, b.is_empty, isAsc);
        case 'sender_ref': return this.compare(a.sender_ref, b.sender_ref, isAsc);
        case 'location': return this.compare(a.registrations[0]?.station.name || 'z', b.registrations[0]?.station.name || 'z', isAsc);
        case 'wagon_type': return this.compare(a.wagon_type, b.wagon_type, isAsc);
        case 'train_number': return this.compare(a.train_number, b.train_number, isAsc);
        case 'description': return this.compare(a.containers[0].description, b.containers[0].description, isAsc);
        case 'sender': return this.compare(a.lane.sender?.name, b.lane.sender?.name, isAsc);
        case 'reference': return this.compare(a.sender_ref, b.sender_ref, isAsc);
        default: return 0;
      }
    })
  }

  public etaIsInterval(r: Registration): boolean {
    if (!r) return false;
    return moment(r.registered_time).add(r.positive_eta_variance, 'minutes').diff(moment(r.registered_time).add(r.negative_eta_variance, 'minutes'), 'days') > 0;
  }

  private compareExpectedArrival(a: Wagon, b: Wagon, isAsc: boolean): number {
    if (!a.registrations || !a.registrations.length) return 1;
    if (!b.registrations || !b.registrations.length) return -1;

    let aTime: number = Number.MAX_SAFE_INTEGER;
    let bTime: number = Number.MAX_SAFE_INTEGER;
    if (a.expected_arrival != null) aTime = a.expected_arrival.getTime();
    else if (a.newest_registration_with_eta != null) {
      if (this.etaIsInterval(a.newest_registration_with_eta)) {
        aTime = (this.etaPipe.transform(a, 'date', 0) as Date).getTime();
      } else {
        aTime = (this.etaPipe.transform(a, 'date') as Date).getTime();
      }
    }

    if (b.expected_arrival != null) bTime = b.expected_arrival.getTime();
    else if (b.newest_registration_with_eta != null) {
      if (this.etaIsInterval(b.newest_registration_with_eta)) {
        bTime = (this.etaPipe.transform(b, 'date', 0) as Date).getTime();
      } else {
        bTime = (this.etaPipe.transform(b, 'date') as Date).getTime();
      }
    }
    return (aTime < bTime ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  dateIsValid(d): boolean {
    return d instanceof Date && !isNaN(d.getTime());
  }

  daysUntil(d: Date): string {
    if (!d) return '';
    return `(${moment(d).startOf('day').diff(moment().startOf('day'), 'days').toString()} ${this.strings.appStrings['DAYS']})`;
  }

  oldestRegistration(registrations: Registration[]): Registration {
    let sorted = this.dateSortPipe.transform(registrations, 'asc');
    return sorted[0];
  }

  newestRegistration(registrations: Registration[]): Registration {
    let sorted = this.dateSortPipe.transform(registrations, 'desc');
    return (sorted) ? sorted[0] : null;
  }

  getWagonInformation(wagon: Wagon): string {
    let nettoWeight = (wagon.netto_weight) ? `${this.strings.appStrings['NETTO_WEIGHT']}: ${wagon.netto_weight} kg\n` : '';
    let grossWeight = (wagon.brutto_weight) ? `${this.strings.appStrings['BRUTTO_WEIGHT']}: ${wagon.brutto_weight} kg\n` : '';
    let rid = (wagon.rid) ? `${this.strings.appStrings['RID']}\n` : '';
    let senderRef = (wagon.sender_ref) ? `${this.strings.appStrings['SENDER_REFERENCE']}: ${wagon.sender_ref}\n` : '';
    let lengthCode = (wagon.containers[0]?.length_code) ? `${this.strings.appStrings['SIZE']}: ${wagon.containers[0].length_code}\n` : '';
    return nettoWeight + grossWeight + rid + senderRef + lengthCode;
  }

  isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty('detailRow');

  setETA(wagon: Wagon, d: Date) {
    let selectedWagons: Wagon[] = this.dataSource.data.filter((wagon: Wagon) => { return wagon.selected })
    let message = this.strings.appStrings['UPDATE_ETA'] + ' - ' + moment(d).local().format('DD-MM-YYYY') + '<br>';
    if (selectedWagons.length > 0) {
      message += selectedWagons.length + ' ' + this.strings.appStrings['WAGONS'] + ':<br>'
      for (let i=0; i<selectedWagons.length; i++) {
        message +=  selectedWagons[i].wagon_number;
        if (i < selectedWagons.length - 1) {
          message += ', '
        }
      }
    } else {
      message += this.strings.appStrings['WAGON'] + ' '
      message += wagon.wagon_number
    }
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['UPDATE_ETA'],
      message: message,
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        if (!selectedWagons || selectedWagons.length == 0) selectedWagons = [wagon];
        let datetime = (d) ? moment(d).local().format('YYYY-MM-DD') : null;
        this.wagonService.setETA(selectedWagons, datetime).subscribe(() => {
          this.wagonService.update();
        })
      } else {
        this.eta.nativeElement.value = (wagon.expected_arrival && !isNaN(wagon.expected_arrival.getTime())) ? wagon.expected_arrival : null;
      }
    })
  }

  setComment(wagon: Wagon) {
    let selectedWagons: Wagon[] = this.dataSource.data.filter(w => w.selected);
    if (!selectedWagons || selectedWagons.length == 0) selectedWagons = [wagon];
    let containers: Container[] = [];
    for (let w of selectedWagons) if (w.containers.length > 0) containers.push(w.containers[0]);
    let message = (containers.length <= 1) ? this.strings.appStrings['UNIT'] : this.strings.appStrings['UNITS'];
    message += '<br>';
    for (let i=0; i<selectedWagons.length; i++) {
      message += selectedWagons[i].containers[0]?.itu_number || selectedWagons[i].wagon_number;
      if (i < containers.length - 1) {
        message += ', '
      }
    }
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      message: message,
      comment: wagon.containers[0]?.comment
    }
    const dialog = this.dialog.open(DialogEditCommentComponent, config);
    dialog.afterClosed().subscribe((comment) => {
      if (comment) {
        if (comment == 'delete-comment') {
          this.wagonService.setComment(containers, null).subscribe(() => { this.wagonService.update() })
        } else {
          this.wagonService.setComment(containers, comment).subscribe(() => { this.wagonService.update() })
        }
      }
    })
  }

  endJourney(wagon: Wagon) {
    let selectedWagons: Wagon[] = this.dataSource.data.filter(w => w.selected);
    if (!selectedWagons || selectedWagons.length == 0) selectedWagons = [wagon];
    let containers: Container[] = [];
    for (let w of selectedWagons) if (w.containers.length > 0) containers.push(w.containers[0]);
    let message = (selectedWagons.length <= 1) ? this.strings.appStrings['UNIT'] + ' ' : this.strings.appStrings['UNITS'] + '<br>';
    for (let i=0; i<selectedWagons.length; i++) {
      message += selectedWagons[i].containers[0]?.itu_number || selectedWagons[i].wagon_number;
      if (i < containers.length - 1) {
        message += ', '
      }
    }
    message += '<br>' + this.strings.appStrings['ARE_YOU_SURE'];
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['END_WAGON_JOURNEY'],
      message: message,
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogEndJourneyComponent, config);
    dialog.afterClosed().subscribe((response) => {
      if (response[0] == true) {
        this.wagonService.endJourney(containers, response[1]).subscribe(() => {
          this.wagonService.update();
        })
      }
    })
  }

  deleteJourney(wagon: Wagon) {
    let selectedWagons: Wagon[] = this.dataSource.data.filter(w => w.selected);
    if (!selectedWagons || selectedWagons.length == 0) selectedWagons = [wagon];
    let containers: Container[] = [];
    for (let w of selectedWagons) if (w.containers.length > 0) containers.push(w.containers[0]);
    let message = (selectedWagons.length <= 1) ? this.strings.appStrings['UNIT'] + ' ' : this.strings.appStrings['UNITS'] + '<br>';
    for (let i=0; i<selectedWagons.length; i++) {
      message += selectedWagons[i].containers[0]?.itu_number || selectedWagons[i].wagon_number;
      if (i < containers.length - 1) {
        message += ', '
      }
    }
    message += '<br>' + this.strings.appStrings['ARE_YOU_SURE'];
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['DELETE_WAGON_JOURNEY'],
      message: message,
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((response) => {
      if (response == true) {
        this.wagonService.deleteJourney(containers).subscribe(() => {
          this.wagonService.update();
        })
      }
    })
  }

  checkEntirePage(checked: boolean) {
    let startIdx = this.paginatorTop.pageSize * this.paginatorTop.pageIndex;
    let endIdx = startIdx + this.paginatorTop.pageSize - 1;
    for (let i=startIdx; i<=endIdx; i++) {
      if (this.dataSource.data[i]) {
        this.dataSource.data[i].selected = checked;
      } else break;
    }
  }

  openRegistrations(wagon: Wagon) {
    if (!wagon) return;
    wagon['is_loading_registrations'] = true;
    this.wagonService.getRegistrations(wagon.id).subscribe({
      next: (response) => {
        if (!response || !response.data || response.data instanceof Array === false) {
          const config = new MatDialogConfig();
          config.width = '500px';
          config.height = '300px';
          config.data = {
            title: this.strings.appStrings['REGISTRATIONS'],
            message: this.strings.appStrings['NO_REGISTRATIONS_FOUND'],
            state: 'OK_OPTION'
          };
          this.dialog.open(DialogConfirmComponent, config);
          wagon['is_loading_registrations'] = false;
          return;  
        } else {
          wagon.registrations = [];
          for (let registration of response.data) {
            wagon.registrations.push(new Registration(registration));
          }
          const config = new MatDialogConfig();
          config.autoFocus = false;
          config.closeOnNavigation = true;
          config.width = '600px';
          config.data = { wagon: wagon }
          this.dialog.open(DialogRegistrationsComponent, config);
          wagon['is_loading_registrations'] = false;
        }
      },
      error: () => { wagon['is_loading_registrations'] = false }
    })  
  }

  isArray(val): boolean {
    return val instanceof Array;
  }

  exportToExcel()
  {
    let data = this.dataSource.data.map((elem: Wagon) => {
      return {
        Wagon_Number: elem.wagon_number,
        Unit: (elem.containers[0]?.itu_number == elem.wagon_number) ? '' : elem.containers[0]?.itu_number,
        Expected_Arrival: this.dateIsValid(elem.expected_arrival) ? 
          moment(elem.expected_arrival).format('YYYY-MM-DD') : 
          this.etaPipe.transform(elem),
        Position: this.dateSortPipe.transform(elem.registrations, 'desc')[0]?.station?.name,
        Departure_Station: elem.lane?.departure_station?.name,
        Arrival_Station: elem.lane?.arrival_station?.name,
        Wagon_Type: elem.wagon_type,
        KA_No: elem.lane?.agreement_number,
        Sender: elem.lane?.sender?.name,
        Goods: elem.containers[0]?.description,
        Sender_Reference: elem.sender_ref || '',
        Net_Weight: elem.netto_weight,
        Gross_Weight: elem.brutto_weight,
        Size: elem.containers[0]?.length_code,
        RID: elem.rid,
        Comment: elem.containers[0]?.comment
      }
    })
    if (data.length == 0) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.height = '300px';
      config.data = {
        title: this.strings.appStrings['AN_ERROR_OCCURRED'],
        message: this.strings.appStrings['NO_DATA_TO_EXPORT'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'WIP_EXPORT');
    XLSX.writeFile(wb, `WIP_EXPORT_${moment().local().format('YYYY_MM_DD_HH_MM_SS')}.xlsx`);
  }


  getStationDisplay() {
    return (val) => this.displayStation(val);
  }

  private displayStation(station: Station): string {
    if (!station) return;
    return station.name + ' - ' + station.country_code + station.station_id;
  }

  getReceiverDisplay() {
    return (val) => this.displayReceiver(val);
  }

  private displayReceiver(receiver: SenderReceiver): string {
    if (!receiver) return;
    return receiver.name;
  }
}
