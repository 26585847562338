<div class="container">
    <mat-card>
        <mat-card-content style="text-align: center;">
            <div 
                *ngIf="data.isLoadingOperationStatus"
                class="spinner-border text-primary mt-3">
            </div>
            <div 
                *ngIf="!data.isLoadingOperationStatus" 
                class="justify-content-center"
                style="text-align: center;">
                <h4>{{strings.appStrings['OPERATION_STATUS']}}</h4>
                <div *ngIf="privileges.canEditOperationStatus" class="justify-content-center">
                    <span class="material-icons edit" style="font-size: xx-large;" matTooltip="{{strings.appStrings['EDIT']}}" routerLink="edit">
                        edit
                    </span>
                </div>
                <div *ngIf="status && status.length === 0" STYLE="margin-top: 50px;">
                    <span style="font-size: large;">
                        {{strings.appStrings['OPERATION_STATUS_DESC']}}
                    </span>
                </div>

                <div *ngIf="status && status.length > 0">
                    <div *ngFor="let elem of status" class="mt-3">
                        <mat-card>
                            <div class="status pt-2 pb-2">
                                <div class="row justify-content-center">
                                    <span style="font-size: x-large;" innerHTML="{{elem.title}}"></span>
                                </div>
                                <div class="row justify-content-center">
                                    <span style="font-size: medium;">{{elem.time | date : 'yyyy-MM-dd HH:mm'}}</span>
                                </div>
                                <div class="row justify-content-center mt-2">
                                    <span class="description" innerHTML="{{elem.description}}"></span>
                                </div>
                                <div class="row justify-content-center mt-2" *ngIf="elem.expected_end_time">
                                    <span style="font-size: medium;">{{strings.appStrings['EXPECTED_END_TIME']}} {{elem.expected_end_time | date : 'yyyy-MM-dd HH:mm'}}</span>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>