import { ILanguage } from "../interfaces/i-language";

export class Language {

    private _id: number;
    private _language: string;
    private _iso1_code: string;
    private _iso2_code: string;

    constructor(language?: ILanguage) {
        if (!language) return;
        this._id = language.id;
        this._language = language.language;
        this._iso1_code = language.iso1_code;
        this._iso2_code = language.iso2_code;
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter language
     * @return {string}
     */
	public get language(): string {
		return this._language;
	}

    /**
     * Getter iso1_code
     * @return {string}
     */
	public get iso1_code(): string {
		return this._iso1_code;
	}

    /**
     * Getter iso2_code
     * @return {string}
     */
	public get iso2_code(): string {
		return this._iso2_code;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter language
     * @param {string} value
     */
	public set language(value: string) {
		this._language = value;
	}

    /**
     * Setter iso1_code
     * @param {string} value
     */
	public set iso1_code(value: string) {
		this._iso1_code = value;
	}

    /**
     * Setter iso2_code
     * @param {string} value
     */
	public set iso2_code(value: string) {
		this._iso2_code = value;
	}

}
