import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RowColorService {

  private _trainColors: TrainColor[] = [];
  readonly COLORS: string[] = [
    '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', 
    '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', 
    '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', 
    '#000075', '#808080', '#ffffff', '#000000'
  ]
  private usedColors: string[];

  constructor() {
    this.usedColors = this.COLORS;
  }

  public getColor(trainNumber: string): string {
    if (this._trainColors.findIndex(item => item.trainNumber == trainNumber) == -1) {
      this._trainColors.push({
        trainNumber: trainNumber,
        color: this.determineColor()
      })
    }
    return this._trainColors.find(item => item.trainNumber == trainNumber).color;
  }

  private determineColor(): string {

    if (this.usedColors.length == 0) this.usedColors = this.COLORS;
    let idx = Math.floor(Math.random() * this.usedColors.length);
    let color = this.usedColors[idx];
    this.usedColors.splice(idx, 1);
    return color;
  }
}

interface TrainColor {
  trainNumber: string;
  color: string
}
