import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() { }

  private _searchString: string;

  @Output() change: EventEmitter<string> = new EventEmitter();

  public get searchString(): string {
    return this._searchString;
  }

  public clear() {
    this._searchString = "";
    this.toggle();
  }

  public toggle(searchString?: string) {
     this._searchString = searchString?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '');
    this.change.emit(this.searchString);
  }
}
