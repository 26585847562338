import { EventEmitter, HostListener, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwipeService {

  private _swipeCoord?: [number, number];
  private _swipeTime: number;

  constructor() { }

  @Output() swipePrevious: EventEmitter<boolean> = new EventEmitter();
  @Output() swipeNext: EventEmitter<boolean> = new EventEmitter();

  private toggleSwipeNext() {
    this.swipeNext.emit();
  }

  private toggleSwipePrevious() {
    this.swipePrevious.emit();
  }

  public swipe(e: TouchEvent, when: string) {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this._swipeCoord = coord;
      this._swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this._swipeCoord[0], coord[1] - this._swipeCoord[1]];
      const duration = time - this._swipeTime;

      if (duration < 500 &&
          Math.abs(direction[0]) > 30 &&
          Math.abs(direction[0]) > Math.abs(direction[1] * 3))
      {
        if (direction[0] < 0) this.toggleSwipeNext();
        else this.toggleSwipePrevious();
      }
    }
  }
}
