import { ILane } from '../interfaces/i-lane';
import { IStakeholder } from '../interfaces/i-stakeholder';
import { Contact } from './contact';
import { SenderReceiver } from './sender-receiver';
import { StakeholderType } from './stakeholder-type';
import { Station } from './station';

export class Stakeholder {

    private _id: number;
    private _name: string;
    private _contacts: Contact[] = [];
    private _stakeholder_type_id: number;
    private _access_track_and_trace: number;
    private _access_demurrage: number;
    private _lanes: Lane[] = [];
    private _stakeholder_type: StakeholderType;

    constructor(obj?: IStakeholder) {
        if (!obj) return;
        this._id = obj.id;
        this._name = obj.name;
        this._stakeholder_type_id = obj.stakeholder_type_id;
        if (obj.contacts) {
            for (let contact of obj.contacts) {
                this._contacts.push(new Contact(contact));
            }
        }
        this._access_track_and_trace = obj._pivot_access_track_and_trace;
        this._access_demurrage = obj._pivot_access_demurrage;
        if (obj.lanes && obj.lanes instanceof Array) {
            for (let lane of obj.lanes) {
                this._lanes.push(new Lane(lane));
            }
        }
        if (obj.stakeholder_type) {
            this._stakeholder_type = new StakeholderType(obj.stakeholder_type);
        }
    }

    toJSON(): Object {
        return {
            id: this._id,
            name: this._name,
            stakeholder_type_id: this.stakeholder_type_id,
            contacts: this._contacts.map((elem) => {
                return {
                    name: elem.name,
                    email: elem.email,
                    phone_number: elem.phone_number || null,
                    portal_access: elem.portal_access ? 1 : 0,
                    language_id: elem.language_id
                }
            })
        }
    }

    toString(): string {
        return this._name;
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
    }
    
    /**
     * Getter contacts
     * @return {Contact[]}
     */
	public get contacts(): Contact[] {
		return this._contacts;
	}

    /**
     * Getter access_track_and_trace
     * @return {number}
     */
	public get access_track_and_trace(): number {
		return this._access_track_and_trace;
    }
    
    /**
     * Getter access_demurrage
     * @return {number}
     */
	public get access_demurrage(): number {
		return this._access_demurrage;
	}

    /**
     * Getter lanes
     * @return {Lane[]}
     */
	public get lanes(): Lane[] {
		return this._lanes;
	}

    /**
     * Getter stakeholder_type_id
     * @return {number}
     */
	public get stakeholder_type_id(): number {
		return this._stakeholder_type_id;
	}

    /**
     * Getter stakeholder_type
     * @return {StakeholderType}
     */
	public get stakeholder_type(): StakeholderType {
		return this._stakeholder_type;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
    }
    
    /**
     * Setter contacts
     * @param {Contact[]} value
     */
	public set contacts(value: Contact[]) {
		this._contacts = value;
	}

    /**
     * Setter access_track_and_trace
     * @param {number} value
     */
	public set access_track_and_trace(value: number) {
		this._access_track_and_trace = value;
    }
    
    /**
     * Setter access_demurrage
     * @param {number} value
     */
	public set access_demurrage(value: number) {
		this._access_demurrage = value;
	}
    
    /**
     * Setter lanes
     * @param {Lane[]} value
     */
	public set lanes(value: Lane[]) {
		this._lanes = value;
	}

    /**
     * Setter stakeholder_type_id
     * @param {number} value
     */
	public set stakeholder_type_id(value: number) {
		this._stakeholder_type_id = value;
	}

    /**
     * Setter stakeholder_type
     * @param {StakeholderType} value
     */
	public set stakeholder_type(value: StakeholderType) {
		this._stakeholder_type = value;
	}
}

export class Lane {

    private _id: number;
    private _agreement_number: string;
    private _departure_station_id: number;
    private _arrival_station_id: number;
    private _track: string;
    private _access_track_and_trace: number;
    private _access_demurrage: number;
    private _stakeholders: Stakeholder[] = [];
    private _departure_station: Station = new Station();
    private _arrival_station: Station = new Station();
    private _editing: boolean = false;
    private _sender: SenderReceiver = new SenderReceiver();
    private _receiver: SenderReceiver = new SenderReceiver();
    private _is_receiver_id_key: number;
    private _import_errors: string[];
    private _import_status: number;

    constructor(obj?: ILane) {
        if (!obj) return
        this._id = obj.id;
        this._agreement_number = obj.agreement_number;
        this._departure_station_id = obj.departure_station_id;
        this._arrival_station_id = obj.arrival_station_id;
        this._track = obj.track;
        if (obj.stakeholders && obj.stakeholders instanceof Array) {
            for (let stakeholder of obj.stakeholders) {
                this._stakeholders.push(new Stakeholder(stakeholder));
            }
        }
        this._departure_station = new Station(obj.departure_station);
        this._arrival_station = new Station(obj.arrival_station);
        this._sender = obj.sender ? new SenderReceiver(obj.sender) : null;
        this._receiver = obj.receiver ? new SenderReceiver(obj.receiver) : null;
        this._is_receiver_id_key = obj.is_receiver_id_key;
    }

    toJSON(): Object {
        return {
            id: this._id,
            agreement_number: this._agreement_number,
            departure_station_id: this._departure_station_id,
            arrival_station_id: this._arrival_station_id,
            track: this._track,
            is_receiver_id_key: this._is_receiver_id_key,
            departure_station: this._departure_station.toJSON(),
            arrival_station: this._arrival_station.toJSON(),
            receiver: this._receiver ? this._receiver.toJSON() : null
        }
    }

    public contactByEmail(email: string): Contact {
        for (let stakeholder of this._stakeholders) {
            for (let contact of stakeholder.contacts) {
                if (contact.email === email) return contact
            }
        }
        return null;
    }

    /**
     * Getter editing
     * @return {boolean }
     */
	public get editing(): boolean  {
		return this._editing;
	}

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter agreement_number
     * @return {string}
     */
	public get agreement_number(): string {
		return this._agreement_number;
	}

    /**
     * Getter departure_station_id
     * @return {number}
     */
	public get departure_station_id(): number {
		return this._departure_station_id;
	}

    /**
     * Getter arrival_station_id
     * @return {number}
     */
	public get arrival_station_id(): number {
		return this._arrival_station_id;
	}

    /**
     * Getter track
     * @return {string}
     */
	public get track(): string {
		return this._track;
	}
    
    /**
     * Getter access_track_and_trace
     * @return {number}
     */
	public get access_track_and_trace(): number {
		return this._access_track_and_trace;
    }
    
    /**
     * Getter access_demurrage
     * @return {number}
     */
	public get access_demurrage(): number {
		return this._access_demurrage;
    }
    
    /**
     * Getter stakeholders
     * @return {Stakeholder[] }
     */
	public get stakeholders(): Stakeholder[]  {
		return this._stakeholders;
	}

    /**
     * Getter departure_station
     * @return {Station}
     */
	public get departure_station(): Station {
		return this._departure_station;
	}

    /**
     * Getter arrival_station
     * @return {Station}
     */
	public get arrival_station(): Station {
		return this._arrival_station;
    }
    
    /**
     * Getter sender
     * @return {SenderReceiver}
     */
	public get sender(): SenderReceiver {
		return this._sender;
    }
    
    /**
     * Getter receiver
     * @return {SenderReceiver}
     */
	public get receiver(): SenderReceiver {
		return this._receiver;
	}

    /**
     * Getter is_receiver_id_key
     * @return {number}
     */
	public get is_receiver_id_key(): number {
		return this._is_receiver_id_key;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter agreement_number
     * @param {string} value
     */
	public set agreement_number(value: string) {
		this._agreement_number = value;
	}

    /**
     * Setter departure_station_id
     * @param {number} value
     */
	public set departure_station_id(value: number) {
		this._departure_station_id = value;
	}

    /**
     * Setter arrival_station_id
     * @param {number} value
     */
	public set arrival_station_id(value: number) {
		this._arrival_station_id = value;
	}

    /**
     * Setter track
     * @param {string} value
     */
	public set track(value: string) {
		this._track = value;
	}
    
    /**
     * Setter access_track_and_trace
     * @param {number} value
     */
	public set access_track_and_trace(value: number) {
		this._access_track_and_trace = value;
    }
    
    /**
     * Setter access_demurrage
     * @param {number} value
     */
	public set access_demurrage(value: number) {
		this._access_demurrage = value;
    }
    
    /**
     * Setter stakeholders
     * @param {Stakeholder[] } value
     */
	public set stakeholders(value: Stakeholder[] ) {
		this._stakeholders = value;
	}

    /**
     * Setter departure_station
     * @param {Station} value
     */
	public set departure_station(value: Station) {
		this._departure_station = value;
	}

    /**
     * Setter arrival_station
     * @param {Station} value
     */
	public set arrival_station(value: Station) {
		this._arrival_station = value;
    }
    
    /**
     * Setter editing
     * @param {boolean } value
     */
	public set editing(value: boolean ) {
		this._editing = value;
    }

    /**
     * Setter sender
     * @param {SenderReceiver} value
     */
	public set sender(value: SenderReceiver) {
		this._sender = value;
    }

    /**
     * Setter receiver
     * @param {SenderReceiver} value
     */
	public set receiver(value: SenderReceiver) {
		this._receiver = value;
    }

    /**
     * Setter is_receiver_id_key
     * @param {number} value
     */
	public set is_receiver_id_key(value: number) {
		this._is_receiver_id_key = value;
    }

    /**
     * Getter errors
     * @return {string[]}
     */
	public get import_errors(): string[] {
		return this._import_errors;
	}

    /**
     * Setter errors
     * @param {string[]} value
     */
	public set import_errors(value: string[]) {
		this._import_errors = value;
	}


    /**
     * Getter import_status
     * @return {number}
     */
	public get import_status(): number {
		return this._import_status;
	}

    /**
     * Setter import_status
     * @param {number} value
     */
	public set import_status(value: number) {
		this._import_status = value;
	}


    
    public toString(): string {
        return this.agreement_number + ' | ' + this.departure_station?.name + ' -> ' + this.arrival_station?.name;
    }
    
}
