import { EventEmitter, Injectable, Output } from '@angular/core';
import { Stakeholder } from '../classes/items';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { StakeholderType } from '../classes/stakeholder-type';
import { IStakeholderType } from '../interfaces/i-stakeholder-type';
import { SenderReceiver } from '../classes/sender-receiver';
import { ISenderReceiver } from '../interfaces/i-sender-receiver';

@Injectable({
  providedIn: 'root'
})
export class SenderReceiverService {

  private _sendersReceivers: SenderReceiver[] = null;
  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService) { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get sendersReceivers(): SenderReceiver[] {
    if (!this._sendersReceivers && !this._isLoading && !this._loadFailed) {
      this.get();
    }
    return this._sendersReceivers;
  }

  public set sendersReceivers(sendersReceivers: SenderReceiver[]) {
    this._sendersReceivers = sendersReceivers;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public clear() {
    this._sendersReceivers = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  public get() {
    this._isLoading = true;
    this.http.get<ISenderReceiver>(
      `${this.api.LOCATION}/sender-receiver`,
      this.header.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          this.sendersReceivers = [];
          for (let elem of response) {
            this._sendersReceivers.push(new SenderReceiver(elem));
          }
          this._isLoading = false;
          this._loadFailed = response.length === 0;
          this.toggle();
        }
      },
      error: () => {
        this._isLoading = false;
        this._loadFailed = true;
      }
    })
  }
}
