<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{data.title}}</h2>

    <span style="font-size: small;" mat-dialog-content>
        <span innerHTML="{{data.message}}"></span>
        <br><br>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{strings.appStrings['ARRIVAL_DATE']}}</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </span>

    <mat-dialog-actions>
        <div *ngIf="data.state==='YES_NO_OPTION'">
            <button #yes class="mat-raised-button mat-primary p-2" (click)="actionYes()">{{strings.appStrings['YES']}}</button>
            <button #no class="mat-raised-button p-2" (click)="actionNo()">{{strings.appStrings['NO']}}</button>
        </div>
        <div *ngIf="data.state==='OK_OPTION'">
            <button #ok class="mat-raised-button mat-primary p-2" (click)="actionOk()">{{strings.appStrings['OK'] || 'OK'}}</button>
        </div>
    </mat-dialog-actions>
</div>