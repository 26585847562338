import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "./api.service";
import { LoggedInService } from "./logged-in.service";
import { Router } from "@angular/router";
import { HeaderService } from "./header.service";
import { v4 as uuid } from "uuid";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DataService } from "./data.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { StringService } from "./string.service";
import { DialogConfirmComponent } from "../components/dialog/dialog-confirm/dialog-confirm.component";
import * as moment from "moment";

export enum Auth {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  STATE = "STATE",
  ROLE = "ROLE",
  USERNAME = "username",
  PASSWORD_EXPIRE_DATE = "PASSWORD_EXPIRE_DATE",
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private loggedIn: LoggedInService,
    private router: Router,
    private header: HeaderService,
    private dialog: MatDialog,
    private strings: StringService
  ) {}

  @Output() roleChange: EventEmitter<boolean> = new EventEmitter();

  public roleChangeToggle() {
    this.roleChange.emit();
  }

  private getAuthHeaders(): Object {
    let headers = new HttpHeaders().append(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    return { headers: headers };
  }

  private storeAccessToken(token: string) {
    if (token) localStorage.setItem(Auth.ACCESS_TOKEN, token);
    else localStorage.removeItem(Auth.ACCESS_TOKEN);
  }

  public getAccessToken(): string {
    return localStorage.getItem(Auth.ACCESS_TOKEN);
  }

  private storeRefreshToken(token: string) {
    if (token) localStorage.setItem(Auth.REFRESH_TOKEN, token);
    else localStorage.removeItem(Auth.REFRESH_TOKEN);
  }

  private getRefreshToken(): string {
    return localStorage.getItem(Auth.REFRESH_TOKEN);
  }

  public storeUsername(username: string) {
    if (username) localStorage.setItem(Auth.USERNAME, username);
    else localStorage.removeItem(Auth.USERNAME);
  }

  public getUsername(): string {
    return localStorage.getItem(Auth.USERNAME);
  }

  private storeState(state: string) {
    if (state) localStorage.setItem(Auth.STATE, state);
    else localStorage.removeItem(Auth.STATE);
  }

  public storePasswordExpireDate(passwordExpirationDate: string) {
    if (!passwordExpirationDate) {
      localStorage.removeItem(Auth.PASSWORD_EXPIRE_DATE);
      return;
    }

    localStorage.setItem(Auth.PASSWORD_EXPIRE_DATE, passwordExpirationDate);
  }

  public getPasswordExpireDate(): string {
    return localStorage.getItem(Auth.PASSWORD_EXPIRE_DATE);
  }

  public getState(): string {
    return localStorage.getItem(Auth.STATE);
  }

  public getRole(): string {
    return localStorage.getItem(Auth.ROLE);
  }

  public setRole(role: string) {
    localStorage.setItem(Auth.ROLE, role);
  }

  public requestAuthCode(redirect?: string) {
    let state = uuid();
    let stateObj = {
      id: state,
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM,
      redirect: redirect || null,
    };
    let encoded = btoa(JSON.stringify(stateObj));
    this.storeState(encoded);
    window.location.href = `
      ${environment.ssoUrl}/authorize?
      state=${encoded}&
      scope=openid&
      client_id=${environment.clientId}&
      redirect_uri=${environment.redirectUrl}&
      response_type=code&
      token_content_type=jwt
    `.replace(/\s+/g, "");
  }

  public requestAccessToken(code: string, redirect?: string) {
    let params = `
      code=${code}&
      redirect_uri=${environment.redirectUrl}&
      client_id=${environment.clientId}&
      grant_type=authorization_code&
      token_content_type=jwt
    `.replace(/\s+/g, "");
    return this.http
      .post<any>(
        `${environment.ssoUrl}/token`,
        new Blob([params], { type: "application/json" }),
        this.getAuthHeaders()
      )
      .subscribe((response) => {
        if (response && response.access_token) {
          this.storeAccessToken(response.access_token);
          this.loggedIn.toggle(true);
          this.validateSession();
        } else this.loggedIn.toggle(false);
        if (response && response.refresh_token) {
          this.storeRefreshToken(response.refresh_token);
        }
        if (redirect && redirect.indexOf(window.location.origin) !== -1) {
          let url = redirect.split(window.location.origin)[1];
          if (url === "") {
            this.router.navigate(["/wagons"], {
              queryParams: {
                arrival_country: "DK",
              },
              queryParamsHandling: "merge",
            });
          } else this.router.navigateByUrl(url);
        }
        return response;
      });
  }

  public refreshAccessToken() {
    let params = `
      refresh_token=${this.getRefreshToken()}&
      redirect_uri=${environment.redirectUrl}&
      client_id=${environment.clientId}&
      grant_type=refresh_token&
      token_content_type=jwt
    `.replace(/\s+/g, "");
    return this.http
      .post<any>(
        `${environment.ssoUrl}/token`,
        new Blob([params], { type: "application/json" }),
        this.getAuthHeaders()
      )
      .pipe(
        tap((response) => {
          if (response && response.access_token) {
            this.storeAccessToken(response.access_token);
            this.loggedIn.toggle(true);
          } else this.loggedIn.toggle(false);
          if (response && response.refresh_token) {
            this.storeRefreshToken(response.refresh_token);
          }
        })
      );
  }

  public logout() {
    this.storeAccessToken("");
    this.storeRefreshToken("");
    this.router.navigateByUrl("");
    this.loggedIn.toggle(false);
    this.http
      .get<any>(`${this.api.LOCATION}/logout`, this.header.getOptions())
      .subscribe({
        next: () => {
          location.reload();
        },
        error: () => {
          location.reload();
        },
      });
  }

  public login(username: string, password: string) {
    this.storeAccessToken(null);
    this.storeRefreshToken(null);
    let data = {
      username: username,
      password: password,
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM,
    };
    return this.http
      .post<any>(`${this.api.LOCATION}/login`, data, this.header.getOptions())
      .subscribe({
        next: (response) => {
          if (response) {
            this.setRole(response.role?.role);
            this.storeUsername(username);
            this.storePasswordExpireDate(response.passwordExpirationDate);
            this.loggedIn.toggle(true);
          }
        },
        error: () => {
          const config = new MatDialogConfig();
          config.autoFocus = true;
          config.closeOnNavigation = true;
          config.width = "500px";
          config.data = {
            title: this.strings.conStrings["AN_ERROR_OCCURRED"],
            message: this.strings.conStrings["INCORRECT_USERNAME_OR_PASSWORD"],
            state: "OK_OPTION",
          };
          this.dialog.open(DialogConfirmComponent, config);
        },
      });
  }

  public requestNewPassword(email: string) {
    let data = {
      username: email,
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM,
    };
    return this.http.post<any>(
      `${this.api.LOCATION}/request-new-password`,
      data,
      this.header.getOptions()
    );
  }

  public createPassword(password: string, token: string) {
    let data = {
      password: password,
      autoLogin: true,
      token: token,
    };
    return this.http.post<any>(
      `${this.api.LOCATION}/create-password`,
      data,
      this.header.getOptions()
    );
  }

  public validateSession() {
    let data = {
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM,
    };
    return this.http
      .post<any>(this.api.VERIFY, data, this.header.getOptions())
      .subscribe({
        next: (response) => {
          if (response && response.role) {
            this.storeUsername(response.username);
            this.storePasswordExpireDate(response.passwordExpirationDate);
            this.setRole(response.role?.role);
            this.loggedIn.toggle(true);
          } else {
            this.setRole(null);
            this.loggedIn.toggle(false);
          }
          this.roleChangeToggle();
        },
        error: () => {
          this.setRole(null);
          this.loggedIn.toggle(false);
          this.roleChangeToggle();
        },
      });
  }

  public deleteContactAccount() {
    return this.http.delete<void>(
      `${this.api.LOCATION}/contact-account`,
      this.header.getOptions()
    );
  }

  public requestMagicLogin(username: string) {
    return this.http.post<void>(
      `${this.api.LOCATION}/magic-login-request`,
      { username: username },
      this.header.getOptions()
    );
  }

  public magicLogin(username: string, token: string) {
    this.storeAccessToken(null);
    this.storeRefreshToken(null);
    let data = {
      username: username,
      token: token,
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM,
    };
    return this.http
      .post<any>(
        `${this.api.LOCATION}/magic-login`,
        data,
        this.header.getOptions()
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.setRole(response.role?.role);
            this.storePasswordExpireDate(response.passwordExpirationDate);
            this.storeUsername(username);
            this.loggedIn.toggle(true);
          }
        },
        error: () => {
          const config = new MatDialogConfig();
          config.autoFocus = true;
          config.closeOnNavigation = true;
          config.width = "500px";
          config.data = {
            title: this.strings.conStrings["AN_ERROR_OCCURRED"],
            message: this.strings.conStrings["INCORRECT_USERNAME_OR_PASSWORD"],
            state: "OK_OPTION",
          };
          this.dialog.open(DialogConfirmComponent, config);
        },
      });
  }
}
