import { EventEmitter, Injectable, Output } from '@angular/core';
import { Station } from '../classes/station';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { IStation } from '../interfaces/i-station';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  private _stations: Station[] = null;
  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService) { }


  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get stations(): Station[] {
    if (!this._stations && !this._isLoading && !this._loadFailed) {
      this.getStations();
    }
    return this._stations;
  }

  public clear() {
    this._stations = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  private getStations() {
    this._isLoading = true;
    this.http.get<IStation>(
      `${this.api.LOCATION}/stations`,
      this.header.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          this._stations = [];
          for (let elem of response) {
            this._stations.push(new Station(elem))
          }
          this._isLoading = false;
          this._loadFailed = false;
          this.toggle();
        }
      },
      error: () => {
        this._isLoading = false;
        this._loadFailed = true;
      }
    })
  }
}
