import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { OperationStatus } from 'src/app/classes/operation-status';
import { DataService } from 'src/app/services/data.service';
import { OperationStatusService } from 'src/app/services/operation-status.service';
import { StringService } from 'src/app/services/string.service';
import { DialogAddOperationStatusComponent } from '../dialog/dialog-add-operation-status/dialog-add-operation-status.component';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { OperationStatusComponent } from '../operation-status/operation-status.component';

@Component({
  selector: 'app-edit-operation-status',
  templateUrl: './edit-operation-status.component.html',
  styleUrls: ['./edit-operation-status.component.scss']
})
export class EditOperationStatusComponent implements OnInit {

  headers = [
    'title', 'time', 'description', 'expected_end', 'delete'
  ]
  private operationStatusSub: Subscription;
  dataSource = new MatTableDataSource<OperationStatus>();

  constructor(public data: DataService,
              public strings: StringService,
              public dialog: MatDialog,
              private operationStatusService: OperationStatusService) {
                this.dataSource = new MatTableDataSource<OperationStatus>([]);
              }

  ngOnInit(): void {
    this.operationStatusSub = this.data.observeOperationStatus().subscribe(() => {
      if (this.data.operationStatus) {
        this.dataSource.data = this.data.operationStatus
      }
    })
  }

  ngOnDestroy() {
    this.operationStatusSub.unsubscribe();
  }

  deactivateStatus(status: OperationStatus) {
    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = {
      title: this.strings.appStrings['CLOSE_OPERATION_STATUS'],
      message: this.strings.appStrings['ARE_YOU_SURE'],
      state: 'YES_NO_OPTION'
    };
    const dialog = this.dialog.open(DialogConfirmComponent, config);
    dialog.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.operationStatusService.close(status).subscribe(() => {
          this.operationStatusService.clear();
        })
      }
    })
  }

  addOperationStatus() {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '850px';
    const dialog = this.dialog.open(DialogAddOperationStatusComponent, config);
    dialog.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.operationStatusService.clear();
      }
    })
  }

}
