import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ArrivalHistory } from '../classes/arrival-history';
import { Wagon } from '../classes/wagon';
import { StringService } from './string.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _hasNotificationPermission: boolean = false;

  constructor(private strings: StringService) {
    this._hasNotificationPermission = localStorage.getItem('NOTIFICATION_PERMISSION') == '1';
  }

  checkPermissions() {
    if (!this._hasNotificationPermission) {
      Notification.requestPermission((permission: string) => {
        if (permission == 'granted') {
          this._hasNotificationPermission = true;
          localStorage.setItem('NOTIFICATION_PERMISSION', '1');
        } else {
          localStorage.setItem('NOTIFICATION_PERMISSION', '0')
        }
      })
    }
  }

  notify(updates: Wagon[] | ArrivalHistory[]) {
    if (!this._hasNotificationPermission) return;
    new Notification(
      `${updates.length} ${this.strings.appStrings['CHANGES']}`,
      { 
        body: moment().local().format('HH:mm') + ' - ' + this.strings.appStrings['CLICK_TO_SEE'],
        requireInteraction: false,
        image: "/assets/img/DB_WIP_icon.png",
        badge: "/assets/img/DB_WIP_icon.png",
      }
    )
  }
}
