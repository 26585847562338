import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { StringService } from '../../services/string.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedInService } from '../../services/logged-in.service';
import { Subscription } from 'rxjs';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Roles } from 'src/app/services/privileges.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /*private loginUsername: ElementRef;
  @ViewChild('loginUsername', { static: false }) set value(value: ElementRef) {
    if (value) this.loginUsername = value;
  }*/
  @ViewChild('loginUsername', { static: false }) loginUsername: ElementRef;
  @ViewChild('loginPassword', { static: false }) loginPassword: ElementRef;
  loginForm: FormGroup;
  requestForm: FormGroup;
  createForm: FormGroup;
  magicLoginForm: FormGroup;
  readonly STATE_LOGIN = 1;
  readonly STATE_REQUEST = 2;
  readonly STATE_CREATE = 3;
  readonly STATE_ACCESS_DENIED = 4;
  readonly STATE_MAGIC_LOGIN = 5;
  state: number = this.STATE_LOGIN;
  token: string;
  showEmailWasSent: boolean = false;
  showMagicRequestEmailSent: boolean = false;
  hide: boolean = true;
  private sub: Subscription;
  private paramSub: Subscription;

  constructor(public strings: StringService,
              private auth: AuthService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private loggedIn: LoggedInService,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.showEmailWasSent = this.route.snapshot.queryParamMap.get('email-sent') == '1';
    this.showMagicRequestEmailSent = this.route.snapshot.queryParamMap.get('magic-login-request') == '1';
    if (this.router.url === '/login/access-denied') {
      this.state = this.STATE_ACCESS_DENIED;
    } else {
      if (this.router.url === '/login/request') {
        this.state = this.STATE_REQUEST
      } else if (this.router.url === '/login/magic-login-request') {
        this.state = this.STATE_MAGIC_LOGIN;
      }
      let username = this.route.snapshot.paramMap.get('username');
      let application = this.route.snapshot.paramMap.get('application');
      let token = this.route.snapshot.paramMap.get('token');
      if (username && application && token) {
        this.state = this.STATE_CREATE;
        this.token = token;
      }
      this.setupForms(username);
    }
    this.sub = this.loggedIn.change.subscribe((loggedIn: boolean) => {
      if (loggedIn) this.router.navigate(['/wagons'], {
        queryParams: {
          arrival_country: 'DK'
        },
        queryParamsHandling: 'merge'
      })
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.state === this.STATE_LOGIN) {
      if (this.loginForm.value.username) {
        this.loginPassword.nativeElement.focus();
      } else {
        this.loginUsername.nativeElement.focus();
      }
    }
    
  }

  internalLogin() {
    this.auth.requestAuthCode(window.location.origin);
  }

  externalLogin() {
    if (this.loginForm.valid) {
      if (this.loginForm.value.username.indexOf('@deutschebahn.com', this.loginForm.value.username.length - '@deutschebahn.com'.length) === -1 && 
          this.loginForm.value.username.indexOf('@dbsr.dk', this.loginForm.value.username.length - '@dbsr.dk'.length) === -1)
      {
        this.auth.setRole(Roles.STAKEHOLDER)
      } else {
        this.auth.setRole(null)
      }
      this.auth.login(this.loginForm.value.username, this.loginForm.value.password);
    } else {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.autoFocus = false;
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['FILL_IN_REQUIRED'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
    }
  }

  requestMagicLogin() {
    if (this.magicLoginForm.valid) {
      if (this.magicLoginForm.value.email.indexOf('@deutschebahn.com', this.magicLoginForm.value.email.length - '@deutschebahn.com'.length) === -1 && 
          this.magicLoginForm.value.email.indexOf('@dbsr.dk', this.magicLoginForm.value.email.length - '@dbsr.dk'.length) === -1)
      {
        this.auth.requestMagicLogin(this.magicLoginForm.value.email)
        .subscribe(() => {
          this.router.navigate(['/login'], {
            queryParams: { 'magic-login-request': 1 }
          })
        }) 
      } else {
        const config = new MatDialogConfig();
        config.width = '500px';
        config.data = {
          title: this.strings.conStrings['AN_ERROR_OCCURRED'],
          message: this.strings.conStrings['EMAIL_NOT_VALID'],
          state: 'OK_OPTION'
        };
        this.dialog.open(DialogConfirmComponent, config);
      }
    }
  }

  requestNewPassword() {
    if (this.requestForm.valid) {
      if (this.requestForm.value.email.indexOf('@deutschebahn.com', this.requestForm.value.email.length - '@deutschebahn.com'.length) === -1 && 
          this.requestForm.value.email.indexOf('@dbsr.dk', this.requestForm.value.email.length - '@dbsr.dk'.length) === -1)
      {
        this.auth.setRole(Roles.STAKEHOLDER)
      } else {
        this.auth.setRole(null);
      }
      this.auth.requestNewPassword(this.requestForm.value.email).subscribe((response) => {
        this.auth.storeUsername(this.requestForm.value.email)
        this.router.navigate(['/login'], {
          queryParams: { 'email-sent': 1 }
        })
      })
    } else {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['EMAIL_NOT_VALID'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
    }
  }

  createPassword() {
    if (!this.createForm.valid) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['PASSWORD_DOES_NOT_MEET_REQUIREMENTS'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
    }
    else if (this.createForm.value.password !== this.createForm.value.retypePassword) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['PASSWORDS_NOT_SIMILAR'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
    } else if (!this.passwordMinimumRequirements(this.createForm.value.password)) {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: this.strings.conStrings['AN_ERROR_OCCURRED'],
        message: this.strings.conStrings['PASSWORD_DOES_NOT_MEET_REQUIREMENTS'],
        state: 'OK_OPTION'
      };
      this.dialog.open(DialogConfirmComponent, config);
    } else {
      if (this.createForm.controls.email.value.indexOf('@deutschebahn.com', this.createForm.controls.email.value.length - '@deutschebahn.com'.length) === -1 && 
          this.createForm.controls.email.value.indexOf('@dbsr.dk', this.createForm.controls.email.value.length - '@dbsr.dk'.length) === -1)
      {
        this.auth.setRole(Roles.STAKEHOLDER)
      } else {
        this.auth.setRole(null);
      }
      this.auth.createPassword(this.createForm.value.password, this.token).subscribe((response) => {
        this.state = 1;
      })
    }
  }

  private passwordMinimumRequirements(password: string): boolean {
    if (!password) return false;
    const UPPER = new RegExp("[A-Z]");
    const LOWER = new RegExp("[a-z]");
    const DIGIT = new RegExp("[0-9]");
    const SPECIAL = new RegExp("[!|@|#|$|%|^|&|*|(|)|_|\+|£|\?|\-]");
    if (password.length < 12) return false;
    if (!UPPER.test(password)) return false;
    if (!LOWER.test(password)) return false;
    if (!DIGIT.test(password)) return false;
    if (!SPECIAL.test(password)) return false;
    return true;
  }

  private setupForms(username: string) {
    this.loginForm = this.fb.group({
      username: new FormControl(this.auth.getUsername() || '', [
        Validators.required,
        Validators.minLength(5),
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(12)
      ])
    })
    this.requestForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.email
      ])
    })
    this.createForm = this.fb.group({
      email: new FormControl(username, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(12)
      ]),
      retypePassword: new FormControl('', [
        Validators.required,
        Validators.minLength(12)
      ])
    })
    this.magicLoginForm = this.fb.group({
      email: new FormControl(username, [
        Validators.required,
        Validators.email,
        Validators.minLength(5)
      ])
    })
    this.createForm.controls.email.disable();
  }
}
