<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{strings.appStrings['ADD']}} {{strings.appStrings['OPERATION_STATUS']}}</h2>

    <mat-dialog-content [formGroup]="form">
        <div class="form-group row justify-content-center w-100">
            <mat-form-field class="form-group col-sm w-100">
                <mat-label>{{strings.appStrings['TITLE']}}</mat-label>
                <input
                    matInput
                    type="text"
                    maxlength="40"
                    formControlName="title"
                    class="form-control"
                    style="font-size: medium;">
            </mat-form-field>
        </div>
        <div class="form-group row justify-content-center w-100">
            <mat-form-field class="form-group col-sm">
                <mat-label style="font-size: large;">{{strings.appStrings['TIME']}}</mat-label>
                <input 
                    matInput
                    type="datetime-local" 
                    formControlName="time"
                    style="font-size: medium;">
            </mat-form-field>
            <mat-form-field class="form-group col-sm">
                <mat-label style="font-size: large;">{{strings.appStrings['EXPECTED_END_TIME']}}</mat-label>
                <input
                    matInput
                    type="datetime-local"
                    formControlName="expected_end_time"
                    class="form-control mb-n2"
                    style="font-size: medium;">
            </mat-form-field>
        </div>
        <div class="form-group row justify-content-center w-100">
            <mat-form-field class="form-group col-sm">
                <mat-label style="font-size: large;">{{strings.appStrings['DESCRIPTION']}}</mat-label>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    #description="cdkTextareaAutosize"
                    cdkAutosizeMinRows=1
                    maxlength="400"
                    formControlName="description"
                    class="form-control"
                    style="padding:30px;font-size: medium;overflow:hidden"></textarea>
            </mat-form-field>
        </div>
        <div class="form-group row justify-content-center w-100">
            <mat-label style="font-size: large;">{{strings.appStrings['SEND_EMAIL']}}</mat-label>
            <mat-slide-toggle
                class="formControl"
                [color]="color"
                formControlName="send_email">
            </mat-slide-toggle>
            <mat-chip-list *ngIf="selectedContacts.length > 0" #contacts aria-label="contacts">
                <mat-chip 
                    *ngFor="let contact of selectedContacts"
                    [selectable]="false"
                    [removable]="false">
                    <span style="color:cornflowerblue">{{contact.name}}</span>&nbsp;
                    <span style="color:chocolate">{{contact.email}}</span>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div class="form-group row justify-content-center w-100">
            <mat-form-field class="form-group col-sm">
                <mat-label style="font-size: large;">{{strings.appStrings['STATIONS']}}</mat-label>
                <mat-chip-list #stations aria-label="stations">
                    <mat-chip
                        *ngFor="let station of selectedStations"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="removeStation(station)">
                        {{station.name}}
                        <span style="color:cornflowerblue">&nbsp;{{station.country_code}}{{station.station_id}}</span>
                        <span *ngIf="station.country_code_iso" style="color:chocolate;">&nbsp;{{station.country_code_iso}}</span>
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input
                        #stationInput
                        formControlName="stations"
                        [matAutocomplete]="autoStations"
                        [matChipInputFor]="stations"
                        [matChipInputSeparatorKeyCodes]="separatorKeyCodes">
                </mat-chip-list>
                <mat-autocomplete
                    #autoStations="matAutocomplete">
                    <mat-option
                        *ngFor="let station of filteredStations | async"
                        [value]="station">
                        <div style="font-size: small;">
                            <mat-checkbox [checked]="selectedStations.indexOf(station) !== -1" (change)="onStationSelected($event.checked, station)" (click)="$event.stopPropagation()">
                                <span>{{station.name}}</span>
                                <span style="color:cornflowerblue">&nbsp;&nbsp;&nbsp;{{station.country_code}}{{station.station_id}}</span>
                                <span *ngIf="station.country_code_iso" style="color:chocolate;">&nbsp;&nbsp;&nbsp;{{station.country_code_iso}}</span>
                            </mat-checkbox>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="form-group row justify-content-center w-100">
            <mat-form-field class="form-group col-sm">
                <mat-label style="font-size: large;">{{strings.appStrings['LANES']}}</mat-label>
                <mat-chip-list #lanes aria-label="lanes">
                    <input
                        #laneInput
                        formControlName="lanes"
                        [matAutocomplete]="autoLanes"
                        [matChipInputFor]="lanes"
                        [matChipInputSeparatorKeyCodes]="separatorKeyCodes">
                    <mat-chip
                        *ngFor="let lane of selectedLanes"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="removeLane(lane)">
                            {{lane.agreement_number}}
                        <span style="color:cornflowerblue">&nbsp;{{lane.departure_station?.name}} - {{lane.arrival_station?.name}}</span>
                        <span *ngIf="lane.sender?.id && lane.receiver?.id" style="color:chocolate">&nbsp;{{lane.sender?.name}} - {{lane.receiver?.name}}</span>
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <mat-autocomplete 
                    #autoLanes="matAutocomplete">
                    <mat-option 
                        *ngFor="let lane of filteredLanes | async"
                        [value]="lane">
                        <div style="font-size: small;">
                            <mat-checkbox [checked]="selectedLanes.indexOf(lane) !== -1" (change)="onLaneSelected($event.checked, lane)" (click)="$event.stopPropagation()">
                                <span>{{lane.agreement_number}}</span>
                                <span style="color:cornflowerblue">&nbsp;&nbsp;&nbsp;{{lane.departure_station?.name}} - {{lane.arrival_station?.name}}</span>
                                <span *ngIf="lane.sender?.id && lane.receiver?.id" style="color: chocolate;">&nbsp;&nbsp;&nbsp;{{lane.sender?.name}} - {{lane.receiver?.name}}</span>
                            </mat-checkbox>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        
        
        
        
        
        
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="mat-raised-button mat-primary p-1" (click)="save()" [disabled]="loading">
            <div *ngIf="loading"><span class="spinner-border spinner-border-sm"></span>&nbsp;</div>
            {{strings.appStrings['ADD']}}
        </button>
        <button class="mat-raised-button p-1" (click)="close()">{{strings.appStrings['CANCEL']}}</button>
    </mat-dialog-actions>
</div>
