<div class="mx-auto px-5" style="text-align: center;">
    <h4 class="mt-5">{{strings.appStrings['SETTINGS']}}</h4>
    <br><br>

    <h6>{{strings.appStrings['USER']}}</h6>
    <span style="font-size: medium;">{{username}}</span>
    <br><br>

    <div *ngIf="privileges.canDeleteAccount" class="mb-5">
        <button #ok class="mat-raised-button mat-primary p-2" (click)="deleteAccount()">{{strings.appStrings['DELETE_ACCOUNT']}}</button>
    </div>

    <div *ngIf="dataSource?.data?.length > 0">
        <h6>{{strings.appStrings['MY_LANES']}}</h6>
        <span style="font-size: medium;">{{strings.appStrings['NOTIFICATION_HINT']}}</span>
        <div class="mat-elevation-z8 table-response-md" style="text-align: left;">
            <table
                mat-table
                matSort
                (matSortChange)="sortTable($event)"
                [dataSource]="dataSource"
                matSortActive="agreement_number"
                matSortDirection="desc"
                class="table table-light table-striped table-hover">
            
                <ng-container matColumnDef="agreement_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="agreement_number">{{strings.appStrings['AGREEMENT_NUMBER']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.agreement_number}}</span>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="departure_station_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="departure_station_name">{{strings.appStrings['DEPARTURE_STATION']}} {{strings.appStrings['NAME']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.departure_station?.name}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="departure_station_station_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="departure_station_station_id">{{strings.appStrings['DEPARTURE_STATION']}} {{strings.appStrings['ID']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.departure_station?.station_id}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="departure_station_zipcode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="departure_station_zipcode">{{strings.appStrings['DEPARTURE_STATION']}} {{strings.appStrings['ZIPCODE']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.departure_station?.zipcode}}</span>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="arrival_station_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="arrival_station_name">{{strings.appStrings['ARRIVAL_STATION']}} {{strings.appStrings['NAME']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.arrival_station?.name}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="arrival_station_station_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="arrival_station_station_id">{{strings.appStrings['ARRIVAL_STATION']}} {{strings.appStrings['ID']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.arrival_station?.station_id}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="arrival_station_zipcode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="arrival_station_zipcode">{{strings.appStrings['ARRIVAL_STATION']}} {{strings.appStrings['ZIPCODE']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.arrival_station?.zipcode}}</span>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="track">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="track">{{strings.appStrings['TRACK']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <span>{{lane.track}}</span>
                    </td>
                </ng-container>
    
                <!--<ng-container matColumnDef="notification">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="send_notifications">{{strings.appStrings['SEND_NOTIFICATIONS']}}</th>
                    <td mat-cell *matCellDef="let lane">
                        <mat-select 
                            style="text-align:right"
                            (selectionChange)="setNotifications(lane, $event.value)"
                            [value]="lane.contactByEmail(username)?.notificationState(lane.id)"
                            [ngStyle]="{'background-color': lane.contactByEmail(username)?.notificationState(lane.id) === strings.appStrings['NONE'] ? 'red' : 'lightgreen'}">
                            <mat-option [value]="NONE">{{strings.appStrings['NONE']}}</mat-option>
                            <mat-option [value]="WEEKLY">{{strings.appStrings['WEEKLY']}}</mat-option>
                            <mat-option [value]="DAILY">{{strings.appStrings['DAILY']}}</mat-option>
                            <mat-option [value]="HOURLY">{{strings.appStrings['HOURLY']}}</mat-option>
                            <mat-option [value]="INSTANT">{{strings.appStrings['INSTANT']}}</mat-option>
                        </mat-select>
                    </td>
                </ng-container>-->
        
                <tr mat-header-row *matHeaderRowDef="headers"></tr>
                <tr
                    mat-row style="height: auto;"
                    *matRowDef="let row; columns: headers"></tr>
            </table>
        </div>
    </div>
</div>