import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Registration } from '../classes/registration';
import { Wagon } from '../classes/wagon';

@Pipe({
  name: 'eta'
})
export class EtaPipe implements PipeTransform {

  transform(wagon: Wagon, type?: string, idx?: number): string | Date {
    if (!wagon.newest_registration_with_eta) return;
    let minutesSinceCurrentEvent = moment().local().diff(moment(wagon.newest_registration_with_eta.registered_time).local(), 'minutes');
    let etaInMinutes = wagon.newest_registration_with_eta.minutes_until_arrival;
    let positiveEtaVariance = wagon.newest_registration_with_eta.positive_eta_variance;
    let negativeEtaVariance = wagon.newest_registration_with_eta.negative_eta_variance;
    if (this.shouldDisplayPeriod(wagon.newest_registration_with_eta)) {
      if (minutesSinceCurrentEvent < wagon.newest_registration_with_eta.minutes_until_next) {
          positiveEtaVariance -= minutesSinceCurrentEvent;
          negativeEtaVariance -= minutesSinceCurrentEvent;
      } else if (minutesSinceCurrentEvent > wagon.newest_registration_with_eta.minutes_until_next) {
          positiveEtaVariance -= wagon.newest_registration_with_eta.minutes_until_next;
          negativeEtaVariance -= wagon.newest_registration_with_eta.minutes_until_next;
      }
      if (positiveEtaVariance < 0) positiveEtaVariance = 0;
      if (negativeEtaVariance < 0) negativeEtaVariance = 0;
      if (type == 'date' && !isNaN(idx)) return [moment().local().add(negativeEtaVariance, 'minutes').toDate(), moment().local().add(positiveEtaVariance, 'minutes').toDate()][idx];
      return `${moment().local().add(negativeEtaVariance, 'minutes').format('DD/MM')}
                - 
              ${moment().local().add(positiveEtaVariance, 'minutes').format('DD/MM')}`;
    } else { 
      if (minutesSinceCurrentEvent < wagon.newest_registration_with_eta.minutes_until_next) {
          etaInMinutes -= minutesSinceCurrentEvent;
      } else if (minutesSinceCurrentEvent > wagon.newest_registration_with_eta.minutes_until_next) {
          etaInMinutes -= wagon.newest_registration_with_eta.minutes_until_next;
      }
      if (etaInMinutes < 0) etaInMinutes = 0;
      if (type == 'date') return moment().local().add(etaInMinutes, 'minutes').toDate();
      return moment().local().add(etaInMinutes, 'minutes').format('DD/MM');
    }
  }

  private shouldDisplayPeriod(r: Registration): boolean {
    return moment(r.registered_time).add(r.positive_eta_variance, 'minutes').diff(moment(r.registered_time).add(r.negative_eta_variance, 'minutes'), 'days') > 0;
  }

}
