import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Oauth2Component } from "./components/login/oauth2/oauth2.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { AuthenticationGuard } from "./guards/authentication.guard";
import { NavComponent } from "./components/nav/nav.component";
import { LanesComponent } from "./components/lane/lanes/lanes.component";
import { WagonsComponent } from "./components/wagons/wagons.component";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { SettingsComponent } from "./components/settings/settings.component";
import { LaneComponent } from "./components/lane/lane/lane.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { StakeholdersComponent } from "./components/stakeholder/stakeholders/stakeholders.component";
import { StakeholderComponent } from "./components/stakeholder/stakeholder/stakeholder.component";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogAttachLaneComponent } from "./components/stakeholder/dialog-attach-lane/dialog-attach-lane.component";
import { DialogConfirmComponent } from "./components/dialog/dialog-confirm/dialog-confirm.component";
import { DialogAttachStakeholderComponent } from "./components/lane/dialog-attach-stakeholder/dialog-attach-stakeholder.component";
import { UserLanesPipe } from "./pipes/user-lanes.pipe";
import { DialogRegistrationsComponent } from "./components/dialog/dialog-registrations/dialog-registrations.component";
import { ReversePipe } from "./pipes/reverse.pipe";
import { MatExpansionModule } from "@angular/material/expansion";
import { RegistrationItemDirective } from "./directives/registration-item.directive";
import { MatCardModule } from "@angular/material/card";
import { DialogCookieConsentComponent } from "./components/dialog/dialog-cookie-consent/dialog-cookie-consent.component";
import { StringService } from "./services/string.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ArrivalHistoryComponent } from "./components/arrival-history/arrival-history.component";
import { OperationStatusComponent } from "./components/operation-status/operation-status.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSliderModule } from "@angular/material/slider";
import { EditOperationStatusComponent } from "./components/edit-operation-status/edit-operation-status.component";
import { DialogAddOperationStatusComponent } from "./components/dialog/dialog-add-operation-status/dialog-add-operation-status.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatChipsModule } from "@angular/material/chips";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DateSortPipe } from "./pipes/date-sort.pipe";
import { FooterComponent } from "./components/footer/footer.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DialogEditCommentComponent } from "./components/dialog/dialog-edit-comment/dialog-edit-comment.component";
import { DialogEndJourneyComponent } from "./components/dialog/dialog-end-journey/dialog-end-journey.component";
import { registerLocaleData } from "@angular/common";
import localeDa from "@angular/common/locales/da";
import { EtaPipe } from "./pipes/eta.pipe";
import { DialogImportLanesComponent } from "./components/lane/dialog-import-lanes/dialog-import-lanes.component";
import { MatMenuModule } from "@angular/material/menu";
import { QAndAComponent } from "./components/q-and-a/q-and-a.component";
import { StationNamePipe } from "./pipes/station-name.pipe";
import { UnderMaintenanceComponent } from "./components/under-maintenance/under-maintenance.component";
import { MagicLoginComponent } from "./components/magic-login/magic-login.component";
import { PasswordExpireAlertComponent } from "./components/password-expire-alert/password-expire-alert.component";
registerLocaleData(localeDa);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    Oauth2Component,
    NavComponent,
    LanesComponent,
    WagonsComponent,
    SettingsComponent,
    LaneComponent,
    StakeholdersComponent,
    StakeholderComponent,
    DialogAttachLaneComponent,
    DialogConfirmComponent,
    DialogAttachStakeholderComponent,
    UserLanesPipe,
    DialogRegistrationsComponent,
    ReversePipe,
    RegistrationItemDirective,
    DialogCookieConsentComponent,
    ArrivalHistoryComponent,
    OperationStatusComponent,
    EditOperationStatusComponent,
    DialogAddOperationStatusComponent,
    DateSortPipe,
    FooterComponent,
    DialogEditCommentComponent,
    DialogEndJourneyComponent,
    EtaPipe,
    DialogImportLanesComponent,
    QAndAComponent,
    StationNamePipe,
    UnderMaintenanceComponent,
    MagicLoginComponent,
    PasswordExpireAlertComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatCardModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSliderModule,
    TextFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    ClipboardModule,
    MatMenuModule,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthenticationGuard,
    DateSortPipe,
    { provide: LOCALE_ID, useValue: "da-DK" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
