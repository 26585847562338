import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform(arr: any[]): any[] {
    if (!arr || arr instanceof Array === false) return arr;
    return arr.slice().reverse();
  }

}
