import { Component, Inject, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { Lane } from 'src/app/classes/items';
import { OperationStatus } from 'src/app/classes/operation-status';
import { DataService } from 'src/app/services/data.service';
import { OperationStatusService } from 'src/app/services/operation-status.service';
import { StringService } from 'src/app/services/string.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { Station } from 'src/app/classes/station';
import {ThemePalette} from '@angular/material/core';
import { Contact } from 'src/app/classes/contact';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-dialog-add-operation-status',
  templateUrl: './dialog-add-operation-status.component.html',
  styleUrls: ['./dialog-add-operation-status.component.scss']
})
export class DialogAddOperationStatusComponent implements OnInit {

  loading = false;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeyCodes: number [] = [ENTER, COMMA]
  selectedLanes: Lane[] = [];
  selectedStations: Station[] = [];
  selectedContacts: Contact[] = [];
  color: ThemePalette = 'accent';

  form: FormGroup;
  filteredLanes: Observable<Lane[]>;
  filteredStations: Observable<Station[]>;
  status: OperationStatus = new OperationStatus();
  private laneSub: Subscription;
  @ViewChild('laneInput') laneInput: ElementRef<HTMLInputElement>;
  @ViewChild('stationInput') stationInput: ElementRef<HTMLInputElement>;
  @ViewChild('description') description: CdkTextareaAutosize;
  @ViewChild('autoLanes') matAutocompleteLanes: MatAutocomplete;
  @ViewChild('autoStations') matAutocompleteStations: MatAutocomplete;
  triggerResize() {
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      return this.description.resizeToFitContent(true)
    })
  }

  constructor(private data: DataService,
              public strings: StringService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogAddOperationStatusComponent>,
              @Inject(MAT_DIALOG_DATA) configData,
              private operationStatusService: OperationStatusService,
              private ngZone: NgZone,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(40)
      ]),
      time: new FormControl(moment().local().format('YYYY-MM-DDTHH:mm'), [
        Validators.required,
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(400)
      ]),
      expected_end_time: new FormControl(null, [
        
      ]),
      send_email: new FormControl(true, [
        
      ]),
      lanes: new FormControl('', [

      ]),
      stations: new FormControl('', [

      ])
    })
    this.filteredLanes = this.form.controls.lanes.valueChanges.pipe(
      startWith(this.data.lanes),
      map((value) => {
        if (!value || value instanceof Array) return this.data.lanes?.slice(0, 10);
        if (value instanceof Lane) value = JSON.stringify(value);
        return this.data.lanes.filter((lane: Lane) => {
          return `
            ${lane.agreement_number}
            ${lane.departure_station?.name}
            ${lane.arrival_station?.name}
            ${lane.sender?.name}
            ${lane.receiver?.name}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })?.slice(0, 10)
      })
    )

    this.filteredStations = this.form.controls.stations.valueChanges.pipe(
      startWith(this.data.stations),
      map((value) => {
        if (!value || value instanceof Array) return this.data.stations?.slice(0, 10);
        if (value instanceof Station) value = JSON.stringify(value);
        return this.data.stations.filter((station: Station) => {
          return `
            ${station.name}
            ${station.country_code}
            ${station.station_id}
            ${station.country_code_iso}
            ${station.zipcode}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })?.slice(0, 10)
      })
    )
    
    this.laneSub = this.data.observeLanes().subscribe(() => {
      this.form.controls.lanes.setValue('')
      this.form.controls.stations.setValue('')
    })
  }

  ngOnDestroy() {
    this.laneSub.unsubscribe();
  }

  save() {
    if (this.form.valid) {
      const message = this.form.value.send_email ? 
        'Are you sure? An email will be sent to all relevant contacts. If no lanes are specified an email will be sent to all contacts in the system.' :
        'Are you sure?'
      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = {
        title: 'Create operation status',
        message: message,
        state: 'YES_NO_OPTION'
      };
      const dialog = this.dialog.open(DialogConfirmComponent, config);
      dialog.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.loading = true;
          this.status.title = this.form.controls.title.value;
          this.status.time = moment(this.form.controls.time.value).local().toDate();
          this.status.description = this.form.controls.description.value;
          this.status.expected_end_time = moment(this.form.controls.expected_end_time.value).local().toDate();
          this.status.send_email = this.form.controls.send_email.value;
          this.status.lanes = this.selectedLanes;
          this.operationStatusService.create(this.status).subscribe({
            next: () => {
              this.loading = false;
              this.dialogRef.close(true);
            },
            error: () => { this.loading = false; }
          })
        }
      })
      
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  removeLane(lane: Lane): void {
    const index = this.selectedLanes.indexOf(lane);
    if (index >= 0) {
      this.selectedLanes.splice(index, 1);
      this.determineContacts();
    }
  }

  removeStation(station: Station) {
    const index = this.selectedStations.indexOf(station);
    if (index >= 0) {
      this.selectedStations.splice(index, 1);
      this.data.lanes.forEach((lane: Lane) => {
        if (lane.departure_station?.station_id === station.station_id || 
          lane.arrival_station?.station_id === station.station_id)
        {
          this.removeLane(lane);
        }
      })
    }
  }

  determineContacts() {
    this.selectedContacts = [];
    for (let lane of this.selectedLanes) {
      for (let stakeholder of lane.stakeholders) {
        for (let contact of stakeholder.contacts) {
          if (this.selectedContacts.findIndex(c => c.id == contact.id) === -1) {
            this.selectedContacts.push(contact);
          }
        }
      }
    }
  }

  onLaneSelected(selected: boolean, lane: Lane): void {
    if (!selected) this.removeLane(lane);
    else {
      if (this.selectedLanes.indexOf(lane) === -1) {
        this.selectedLanes.push(lane);
        this.determineContacts()
      }
      this.laneInput.nativeElement.value = '';
      this.form.controls.lanes.setValue(null);
    }
  }

  onStationSelected(selected: boolean, station: Station) {
    if (!selected) this.removeStation(station);
    else {
      if (this.selectedStations.indexOf(station) === -1) {
        this.selectedStations.push(station);
        this.data.lanes.forEach((lane: Lane) => {
          if (lane.departure_station?.station_id === station.station_id || 
            lane.arrival_station?.station_id === station.station_id)
          {
            this.onLaneSelected(true, lane);
          }
        })
      }
      this.stationInput.nativeElement.value = '';
      this.form.controls.stations.setValue(null);
    }
  }

}
