import { Component, ContentChildren, ElementRef, Inject, OnInit, QueryList, ViewChild, AfterContentInit, ViewChildren, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Registration } from 'src/app/classes/registration';
import { RegistrationItemDirective } from 'src/app/directives/registration-item.directive';
import { StringService } from 'src/app/services/string.service';

@Component({
  selector: 'app-dialog-registrations',
  templateUrl: './dialog-registrations.component.html',
  styleUrls: ['./dialog-registrations.component.scss']
})
export class DialogRegistrationsComponent implements OnInit {

  public headers: string[] = [
    'icon', 'time', 'event', 'location'
  ];
  public dataSource: MatTableDataSource<Registration>;
  @ViewChild('close', { static: true }) closeBtn: ElementRef;
  data: any;

  constructor(public strings: StringService,
              private dialogRef: MatDialogRef<DialogRegistrationsComponent>,
              @Inject(MAT_DIALOG_DATA) data) { 
                this.data = data;
                this.dataSource = new MatTableDataSource(data.wagon.registrations);
              }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.closeBtn.nativeElement.focus();
  }

  actionClose() {
    this.dialogRef.close();
  }

  dateIsValid(d: Date) {
    return (d && !isNaN(d.getTime()));
  }
}
