<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{strings.appStrings['ADD']}} {{strings.appStrings['STAKEHOLDER']}}</h2>

    <mat-dialog-content [formGroup]="form">
        <mat-form-field class="form-group col-sm w-100">
            <mat-label>{{strings.appStrings['ADD']}} {{strings.appStrings['STAKEHOLDER']}}</mat-label>
            <input
                matInput
                type="text"
                name="stakeholder"
                class="form-control"
                formControlName="stakeholder"
                maxlength="50"
                [matAutocomplete]="arrivalAuto">
            <mat-autocomplete 
                #arrivalAuto="matAutocomplete">
                <mat-option>
                    <div class="grid-2">
                        <span><strong>{{strings.appStrings['NAME']}}</strong></span>
                        <span><strong>{{strings.appStrings['EMAIL']}}</strong></span>
                    </div>
                </mat-option>
                <mat-option 
                    *ngFor="let stakeholder of filteredStakeholders | async" 
                    [value]="stakeholder.name"
                    style="height:auto">
                        <div class="grid-2">
                            <span style="color: chocolate;">
                                <span class="material-icons" style="font-size: small;color:chocolate">
                                    contacts
                                </span>
                                {{stakeholder.name}}
                            </span>
                            <span style="font-size:small;float:right;" *ngFor="let contact of stakeholder.contacts">
                                <span class="material-icons" style="font-size: small;">
                                    email
                                </span>
                                {{contact.email}}
                            </span>
                        </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </mat-dialog-content>
    
    <mat-dialog-actions>
        <button class="mat-raised-button mat-primary p-1" (click)="add()">{{strings.appStrings['ADD']}}</button>
        <button class="mat-raised-button p-1" (click)="close()">{{strings.appStrings['CANCEL']}}</button>
    </mat-dialog-actions>
</div>
