import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { ArrivalHistory } from '../classes/arrival-history';
import { IArrivalHistory } from '../interfaces/i-arrival-history';
import { ApiService } from './api.service';
import { FilterService } from './filter.service';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class ArrivalHistoryService {

  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;
  private _arrivalHistory: ArrivalHistory[] = null;
  private _lastUpdated: Date = null;

  constructor(private http: HttpClient,
              private headers: HeaderService,
              private api: ApiService,
              private filterService: FilterService) {
                this.filterService.changeArrivalHistory.subscribe(() => {
                  if (!this._loadFailed) {
                    this.get(
                      this.filterService.getParams(
                        this.filterService.arrivalHistory
                      ),
                      true,
                      true
                    )
                  }
                })
              }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public update() {
    this.get(
      this.filterService.getParams(
        this.filterService.arrivalHistory
      ),
      false,
      false
    );
  }

  public get arrivalHistory(): ArrivalHistory[] {
    if (!this._arrivalHistory && !this._isLoading && !this._loadFailed) {
      this.get(
        this.filterService.getParams(
          this.filterService.arrivalHistory
        )
      );
    }
    return this._arrivalHistory;
  }

  public set arrivalHistory(arrival_history: ArrivalHistory[]) {
    this._arrivalHistory = arrival_history;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get lastUpdated(): Date {
    return this._lastUpdated;
  }

  public clear() {
    this._arrivalHistory = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  private get(params?: string, showLoading: boolean = true, clearData: boolean = false) {
    if (showLoading) {
      this._isLoading = true;
    }
    if (clearData) {
      this._arrivalHistory = [];
      this.toggle();
    }
    this.http.get<IArrivalHistory[]>(
      `${this.api.LOCATION}/arrival-history?${params}`,
      this.headers.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          let arrivalHistoryItems = [];
          for (let elem of response) {
            arrivalHistoryItems.push(new ArrivalHistory(elem));
          }
          this._arrivalHistory = arrivalHistoryItems.map((arrivalHistory: ArrivalHistory) => {
            let oldItem = this._arrivalHistory?.find(elem => elem.id == arrivalHistory.id);
            if (!oldItem) {
              return arrivalHistory;
            }
            else {
              arrivalHistory.selected = oldItem.selected;
              return arrivalHistory;
            }
          })
          this._isLoading = false;
          this._loadFailed = false;
          this._lastUpdated = moment().local().toDate();
          this.toggle();
        }
      },
      error: () => {
        this._loadFailed = true;
        this._isLoading = false;
      }
    })
  }

  public getRegistrationHistory(arrivalhistoryId: number) {
    return this.http.get<any>(
      `${this.api.LOCATION}/registration-history/${arrivalhistoryId}`,
      this.headers.getOptions()
    )
  }

  public setComment(arrivals: ArrivalHistory[], comment: string) {
    return this.http.put<any>(
      `${this.api.LOCATION}/arrival-history/comment`,
      { arrival_history: arrivals.map(a => a.id), comment: comment },
      this.headers.getOptions()
    )
  }
}
