import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "password-expire-alert",
  templateUrl: "./password-expire-alert.component.html",
  styleUrls: ["./password-expire-alert.component.scss"],
})
export class PasswordExpireAlertComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit(): void {}

  getDaysUntilPasswordExpire(): number {
    const expireDate = this.auth.getPasswordExpireDate();

    if (!expireDate) return null;

    // Strip away hh:mm:ss, while keeping the object as the MomentInput type used for .diff()
    const now = moment(moment().format("YYYY-MM-DD")).utc();

    return moment(expireDate, "YYYY-MM-DD").diff(now, "days");
  }
}
