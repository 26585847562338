<div class="p-2" style="text-align: left;">
    
    <div class="mat-elevation-z8">
        <!--<div *ngIf="data.isLoadingWagons" style="text-align: center;">
            <div class="spinner-border text-primary mt-3"></div>
        </div>-->
        <!--<div *ngIf="stakeholder" style="text-align:center">
            <div class="row justify-content-center">
                <h5>{{stakeholder.name}}</h5>
            </div>
        </div>-->

        <!--<div *ngIf="lane" style="text-align: center;">
            <div class="row justify-content-center">
                <h5>{{lane}}</h5>
            </div>
        </div>-->

        <mat-accordion>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                <mat-panel-title>
                    {{strings.appStrings['FILTER']}}
                    <mat-icon>filter_alt</mat-icon>
                </mat-panel-title>
                <mat-panel-description style="position:relative;">
                    <strong>{{strings.appStrings['ACTIVE_FILTERS']}}:</strong>
                    &nbsp;
                    <span *ngIf="filterService.wagon.departure_country">
                        {{strings.appStrings['DEPARTURE_COUNTRY']}}:
                        {{filterService.wagon.departure_country}}
                        <span *ngIf="filterService.wagon.arrival_country || filterService.wagon.departure_station || filterService.wagon.arrival_station || filterService.wagon.stakeholder || filterService.wagon.type || filterService.wagon.lane">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="filterService.wagon.arrival_country">
                        {{strings.appStrings['ARRIVAL_COUNTRY']}}:
                        {{filterService.wagon.arrival_country}}
                        <span *ngIf="filterService.wagon.departure_station || filterService.wagon.arrival_station || filterService.wagon.stakeholder || filterService.wagon.type || filterService.wagon.lane">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.departure_station">
                        {{strings.appStrings['DEPARTURE_STATION']}}
                        {{data.stations | stationName : filterService.wagon.departure_station}}
                        <span *ngIf="filterService.wagon.arrival_station || filterService.wagon.stakeholder || filterService.wagon.type || filterService.wagon.lane">, </span>
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.arrival_station">
                        {{strings.appStrings['ARRIVAL_STATION']}}
                        {{data.stations | stationName : filterService.wagon.arrival_station}}
                        <span *ngIf="filterService.wagon.stakeholder || filterService.wagon.type || filterService.wagon.lane">, </span>
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.stakeholder">
                        {{strings.appStrings['STAKEHOLDER']}}: 
                        {{stakeholder?.name}}
                        <span *ngIf="privileges.isAdminOrInternalUser && (filterService.wagon.lane || filterService.wagon.type)">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.lane">
                        {{strings.appStrings['LANE']}}:
                        {{lane?.agreement_number}} {{lane?.departure_station?.name + ' - '}} {{lane?.arrival_station?.name}}
                        <span *ngIf="privileges.isAdminOrInternalUser && (filterService.wagon.type || filterService.wagon.manual_eta)">,</span>
                        &nbsp;
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.type">
                        {{strings.appStrings['TRANSPORT_TYPE']}}:
                        {{strings.appStrings[filterService.wagon.type?.toUpperCase()]}}
                        <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.manual_eta">,</span>
                    </span>
                    <span *ngIf="privileges.isAdminOrInternalUser && filterService.wagon.manual_eta">
                        {{strings.appStrings['EDITED_MANUALLY']}}:
                        <span *ngIf="filterService.wagon.manual_eta == 1">{{strings.appStrings['YES']}}</span>
                        <span *ngIf="filterService.wagon.manual_eta != 1">{{strings.appStrings['NO']}}</span>
                    </span>
                </mat-panel-description>
                <div class="led-box d-none d-xl-table-cell" style="position:absolute; right: 0;">
                    <div class="led led-green"></div>
                    <span>{{strings.appStrings['LAST_UPDATED']}}: {{wagonService.lastUpdated | date: 'HH:mm'}}</span>
                </div>
                </mat-expansion-panel-header>
                    <section>
                        <mat-form-field class="col-xs-6">
                            <mat-label>{{strings.appStrings['DEPARTURE_COUNTRY']}}</mat-label>
                            <mat-select [(ngModel)]="filterService.wagon.departure_country">
                                <mat-option [value]="null"></mat-option>
                                <mat-option *ngFor="let country of data.countries | async" [value]="country">{{country}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field class="col-xs-6 ms-2">
                            <mat-label>{{strings.appStrings['ARRIVAL_COUNTRY']}}</mat-label>
                            <mat-select [(ngModel)]="filterService.wagon.arrival_country" [disabled]="!privileges.isAdminOrInternalUser">
                                <mat-option [value]="null"></mat-option>
                                <mat-option *ngFor="let country of data.countries | async" [value]="country">{{country}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div [formGroup]="stationFilterForm" *ngIf="privileges.isAdminOrInternalUser">
                            <mat-form-field class="col-xs-6 ms-2" style="width: 300px;">
                                <mat-label style="font-size: large;">{{strings.appStrings['DEPARTURE_STATION']}}</mat-label>
                                <input
                                    matInput
                                    #departureStationInput
                                    formControlName="departure_station"
                                    [matAutocomplete]="autoDepartureStations">
                                <mat-autocomplete
                                    #autoDepartureStations="matAutocomplete"
                                    [displayWith]="getStationDisplay()">
                                    <mat-option
                                        *ngFor="let station of filteredDepartureStations | async"
                                        [value]="station">
                                        <div style="font-size: small;width:auto;">
                                            <span>{{station.name}}</span>
                                            <span style="color:cornflowerblue">&nbsp;&nbsp;&nbsp;{{station.country_code}}{{station.station_id}}</span>
                                            <span *ngIf="station.country_code_iso" style="color:chocolate;">&nbsp;&nbsp;&nbsp;{{station.country_code_iso}}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="col-xs-6 ms-2" style="width: 300px;">
                                <mat-label style="font-size: large;">{{strings.appStrings['ARRIVAL_STATION']}}</mat-label>
                                <input
                                    matInput
                                    #arrivalStationInput
                                    formControlName="arrival_station"
                                    [matAutocomplete]="autoArrivalStations">
                                <mat-autocomplete
                                    #autoArrivalStations="matAutocomplete"
                                    [displayWith]="getStationDisplay()">
                                    <mat-option
                                        *ngFor="let station of filteredArrivalStations | async"
                                        [value]="station">
                                        <div style="font-size: small;width:auto;">
                                            <span>{{station.name}}</span>
                                            <span style="color:cornflowerblue">&nbsp;&nbsp;&nbsp;{{station.country_code}}{{station.station_id}}</span>
                                            <span *ngIf="station.country_code_iso" style="color:chocolate;">&nbsp;&nbsp;&nbsp;{{station.country_code_iso}}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div> 
                    </section>

                    <section *ngIf="privileges.isAdminOrInternalUser">
                        <mat-form-field class="col-xs-6">
                            <mat-label>{{strings.appStrings['STAKEHOLDER']}}</mat-label>
                            <input
                                matInput
                                type="text"
                                maxlength="50"
                                [matAutocomplete]="stakeholderAuto"
                                (input)="stakeholderChanged($event.target.value)"
                                style="color:white"
                                [ngModel]="stakeholder?.name">
                            <mat-autocomplete
                                #stakeholderAuto="matAutocomplete"
                                (optionSelected)="stakeholderChanged($event.option.value)">
                                <mat-option
                                    *ngFor="let stakeholder of filteredStakeholders"
                                    [value]="stakeholder">
                                    {{stakeholder.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button *ngIf="stakeholderControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="stakeholderChanged('')">
                                <mat-icon>close</mat-icon>
                                </button>
                        </mat-form-field>

                        <mat-form-field class="col-xs-6 ms-2" style="width:400px">
                            <mat-label>{{strings.appStrings['LANE']}}</mat-label>
                            <input
                                matInput
                                type="text"
                                maxlength="50"
                                [matAutocomplete]="laneAuto"
                                (input)="laneChanged($event.target.value)"
                                [ngModel]="(lane) ? lane?.agreement_number + ' ' + lane?.departure_station?.name + ' - ' + lane?.arrival_station?.name : ''"
                                style="color:white;width: 400px">
                            <mat-autocomplete
                                #laneAuto="matAutocomplete"
                                [panelWidth]="400"
                                (optionSelected)="laneChanged($event.option.value)">
                                <mat-option
                                    *ngFor="let lane of filteredLanes"
                                    [value]="lane">
                                    <span style="font-size: small;">{{lane.agreement_number}}</span>
                                    <span style="color:cornflowerblue;font-size: small;">&nbsp;&nbsp;&nbsp;{{lane.departure_station?.name}} - {{lane.arrival_station?.name}}</span>
                                    <span *ngIf="lane.sender?.id && lane.receiver?.id" style="color: chocolate;font-size: small;">&nbsp;&nbsp;&nbsp;{{lane.sender?.name}} - {{lane.receiver?.name}}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button *ngIf="laneControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="laneChanged('')">
                                <mat-icon>close</mat-icon>
                                </button>
                        </mat-form-field>

                        <div [formGroup]="receiverFilterForm">
                            <mat-form-field class="col-xs-6 ms-2" style="width: 300px;">
                                <mat-label style="font-size: large;">{{strings.appStrings['WAGON_RECEIVER']}}</mat-label>
                                <input
                                    matInput
                                    #receiverInput
                                    formControlName="receiver"
                                    [matAutocomplete]="autoReceivers">
                                <mat-autocomplete
                                    #autoReceivers="matAutocomplete"
                                    [displayWith]="getReceiverDisplay()">
                                    <mat-option
                                        *ngFor="let receiver of filteredReceivers | async"
                                        [value]="receiver">
                                        <div style="font-size: small;width:auto;">
                                            <span>{{receiver.name}}</span>
                                            <span *ngIf="receiver.customer_number" style="color:chocolate;">&nbsp;&nbsp;&nbsp;{{receiver.customer_number}}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </section>

                    <section *ngIf="privileges.isAdminOrInternalUser">
                        <mat-form-field class="col-xs-6">
                            <mat-label>{{strings.appStrings['EDITED_MANUALLY']}}</mat-label>
                            <mat-select [(ngModel)]="filterService.wagon.manual_eta">
                                <mat-option [value]="null"></mat-option>
                                <mat-option [value]="1">{{strings.appStrings['YES']}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="col-cs-6 ms-2">
                            <mat-label>{{strings.appStrings['TRANSPORT_TYPE']}}</mat-label>
                            <mat-select (selectionChange)="filterService.wagon.type = $event.value" [value]="transportType" class="filter-select">
                                <mat-option [value]="null"></mat-option>
                                <mat-option value="intermodal">{{strings.appStrings['INTERMODAL']}}</mat-option>
                                <mat-option value="single_wagon">{{strings.appStrings['SINGLE_WAGON']}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div [formGroup]="locationFilterForm">
                            <mat-form-field class="col-cs-6 ms-2">
                                <mat-label>{{strings.appStrings['LOCATION']}}</mat-label>
                                <input
                                    matInput
                                    #locationInput
                                    formControlName="location"
                                    [matAutocomplete]="autoLocations">
                                <mat-autocomplete
                                    #autoLocations="matAutocomplete"
                                    [displayWith]="getStationDisplay()">
                                    <mat-option
                                        *ngFor="let location of filteredLocations | async"
                                        [value]="location">
                                        <div style="font-size: small;width:auto;">
                                            <span>{{location.name}}</span>
                                            <span *ngIf="location.country_code_iso" style="color:chocolate;">&nbsp;&nbsp;&nbsp;{{location.country_code_iso}}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </section>

                    <button type="button" class="mat-raised-button mat-primary p-1" (click)="exportToExcel()">
                        {{strings.appStrings['EXPORT']}}
                    </button>
            </mat-expansion-panel>
        </mat-accordion>
        
        <mat-progress-spinner 
            *ngIf="data.isLoadingWagons"
            color="primary" 
            class="m-auto"
            mode="indeterminate">
        </mat-progress-spinner>

        <mat-paginator 
            #paginatorTop
            [pageIndex]="0"
            [pageSize]="10"
            [length]="dataSource.data.length"
            [pageSizeOptions]="[10, 20, 30, 40, 50]"
            (page)="onPageChangeTop($event);">
        </mat-paginator>

        <table 
            mat-table 
            #table
            matSort
            (matSortChange)="sortTable($event)"
            [dataSource]="dataSource" 
            matSortDirection="desc"
            matSortDisableClear 
            style="width:100%;"
            class="table table-light table-striped table-hover table-borderless">

            <ng-container matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef class="p-1 d-none d-xl-table-cell" [matTooltip]="strings.appStrings['SELECT_ALL_IN_CURRENT_PAGE']" [matTooltipPosition]="'left'">
                    <mat-checkbox *ngIf="privileges.canEditETA || privileges.canEndWagonJourney" [(ngModel)]="allSelected" (change)="checkEntirePage($event.checked)"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-xl-table-cell">
                    <mat-checkbox *ngIf="privileges.canEditETA || privileges.canEndWagonJourney" [(ngModel)]="wagon.selected"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="container">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="container" class="p-1 d-none d-md-table-cell">{{strings.appStrings['CONTAINER']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-md-table-cell">
                    <span *ngIf="wagon.containers[0]?.itu_number != wagon.wagon_number">{{wagon.containers[0]?.itu_number}}</span>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="wagon_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="wagon_number" class="p-1 d-none d-md-table-cell">{{strings.appStrings['WAGON_NUMBER']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-md-table-cell">
                    <span>{{wagon.wagon_number}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="unit" class="p-1 d-md-none">{{strings.appStrings['UNIT']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-md-none">
                    <span *ngIf="wagon.containers[0]?.itu_number">{{wagon.containers[0].itu_number}}</span>
                    <span *ngIf="!wagon.containers[0]?.itu_number">{{wagon.wagon_number}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="expected_arrival">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival" class="p-1 d-none d-sm-table-cell">{{strings.appStrings['EXPECTED_ARRIVAL']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-sm-table-cell">
                    <div *ngIf="privileges.canEditETA">
                        <div *ngIf="dateIsValid(wagon.expected_arrival)">
                            <mat-form-field appearance="fill" style="width:200px;">
                                <span *ngIf="privileges.isAdminOrInternalUser" [matTooltip]="strings.appStrings['EDITED_MANUALLY']" class="material-icons me-1" style="font-size: medium;color: rgb(236, 0, 22)">
                                    edit_calendar
                                </span>
                                <input #eta matInput readonly [matDatepicker]="picker" [value]="wagon.expected_arrival | date: 'yyyy-MM-dd'" (dateChange)="setETA(wagon, $event.target.value)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker startView="month">
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>{{strings.appStrings['CANCEL']}}</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>{{strings.appStrings['SAVE']}}</button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!dateIsValid(wagon.expected_arrival) && etaIsInterval(wagon.newest_registration_with_eta)">
                            <mat-form-field appearance="fill" style="width:200px;">
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate readonly [value]="(wagon | eta : 'date' : 0) | date: 'yyyy-MM-dd'" (dateChange)="setETA(wagon, $event.target.value)" placeholder="Start date">
                                <input matEndDate readonly [value]="(wagon | eta : 'date' : 1) | date: 'yyyy-MM-dd'" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker>
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>{{strings.appStrings['CANCEL']}}</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>{{strings.appStrings['SAVE']}}</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>
                        </div>
                        <div *ngIf="!dateIsValid(wagon.expected_arrival) && !etaIsInterval(wagon.newest_registration_with_eta)">
                            <mat-form-field appearance="fill" style="width:200px;">
                                <input #eta matInput readonly [matDatepicker]="picker" [value]="(wagon | eta : 'date') | date: 'yyyy-MM-dd'" (dateChange)="setETA(wagon, $event.target.value)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker startView="month">
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>{{strings.appStrings['CANCEL']}}</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>{{strings.appStrings['SAVE']}}</button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    <div *ngIf="!privileges.canEditETA">
                        <span style="color:black;" *ngIf="dateIsValid(wagon.expected_arrival)">{{wagon.expected_arrival | date: 'dd-MM-yyyy'}}</span>
                        <span style="color:black;" *ngIf="!dateIsValid(wagon.expected_arrival)">{{wagon | eta}}</span>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="departure_station">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="departure_station" class="p-1 d-none d-xl-table-cell">{{strings.appStrings['DEPARTURE_STATION']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-xl-table-cell">
                    <div>
                        <span
                            *ngIf="wagon.lane?.departure_station?.name?.length > 15"
                            [matTooltip]="wagon.lane.departure_station?.name"
                            [matTooltipPosition]="'left'">
                            {{wagon.lane.departure_station.name | slice : 0 : 15}}&hellip;
                        </span>
                        <span
                            class="break-all"
                            *ngIf="wagon.lane?.departure_station?.name?.length <= 15">
                            {{wagon.lane.departure_station.name}}
                        </span>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="arrival_station">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="arrival_station" class="p-1 d-none d-sm-table-cell">{{strings.appStrings['ARRIVAL_STATION']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-sm-table-cell">
                    <span 
                        *ngIf="wagon.lane?.arrival_station?.name?.length > 15"
                        [matTooltip]="wagon.lane.arrival_station.name"
                        [matTooltipPosition]="'left'">
                        {{wagon.lane.arrival_station.name | slice : 0 : 15}}&hellip;
                    </span>
                    <span
                        *ngIf="wagon.lane?.arrival_station?.name?.length <= 15">   
                        {{wagon.lane.arrival_station.name}} 
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="wagon_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="wagon_type" class="p-1 d-none d-xxl-table-cell">{{strings.appStrings['WAGON_TYPE']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-xxl-table-cell">
                    <span>{{wagon.wagon_type}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="info">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="info" class="p-1 d-none d-xl-table-cell"></th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-xl-table-cell">
                    <span
                        [matTooltip]="getWagonInformation(wagon)"
                        [matTooltipPosition]="'left'"
                        class="material-icons btn-icon reg">
                        info
                    </span>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="agreement_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="agreement_number" class="p-1 d-none d-xl-table-cell">{{strings.appStrings['AGREEMENT_NUMBER']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1 d-none d-xl-table-cell">
                    <span>{{wagon.lane.agreement_number}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="location" class="p-1">{{strings.appStrings['POSITION']}}</th>
                <td mat-cell *matCellDef="let wagon" class="p-1">
                    <div *ngIf="wagon.registrations?.length > 0">
                        <span class="fixed-top-left">
                            {{(wagon.registrations | dateSort : 'desc')[0]?.station?.name}}
                        </span>
                        <br>
                        <span class="w-100 combined" style="font-size:smaller;line-height: -800px;">
                            <b>{{((wagon.registrations | dateSort : 'desc')[0]?.event_type == 'PassedThrough') ? 'Passed' : (wagon.registrations | dateSort : 'desc')[0]?.event_type}}</b>
                            {{(wagon.registrations | dateSort : 'desc')[0]?.registered_time | date: 'dd-MM-yyyy HH:mm'}} 
                        </span>

                        <!--<span 
                            *ngIf="newestRegistration(wagon.registrations)?.station?.name?.length <= 11">
                            {{newestRegistration(wagon.registrations)?.registered_time | date: 'dd-MM-yyyy HH:mm'}}&nbsp;-&nbsp;
                            {{newestRegistration(wagon.registrations)?.event_type}}&nbsp;
                            
                            {{newestRegistration(wagon.registrations)?.station?.name}}
                        </span>
                        <span 
                            *ngIf="newestRegistration(wagon.registrations)?.station?.name?.length > 11" 
                            [matTooltip]="newestRegistration(wagon.registrations)?.station?.name"
                            [matTooltipPosition]="'left'">
                            {{newestRegistration(wagon.registrations)?.registered_time | date: 'dd-MM-yyyy HH:mm'}}&nbsp;-&nbsp;
                            {{newestRegistration(wagon.registrations)?.event_type}}&nbsp;
                            {{newestRegistration(wagon.registrations)?.station?.name | slice : 0 : 11}}&hellip;
                        </span>-->
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="registrations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="registrations" class="p-1"></th>
                <td mat-cell *matCellDef="let wagon">
                    <div *ngIf="wagon.is_loading_registrations">
                        <mat-progress-spinner 
                            color="primary" 
                            mode="indeterminate"
                            [diameter]="25"
                            style="background: rgba(0,0,0,0);">
                        </mat-progress-spinner>
                    </div>
                    <span
                        *ngIf="!wagon.is_loading_registrations"
                        class="material-icons btn-icon"
                        (click)="openRegistrations(wagon)">
                        history
                    </span>
                    <!--<div *ngIf="wagon.registrations.length > 0">
                        <span
                            class="material-icons btn-icon reg"
                            (click)="openRegistrations(wagon)">
                            history
                        </span>
                    </div>-->
                </td>
            </ng-container>

            <ng-container matColumnDef="sender">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="sender"
                    class="p-1 d-none d-xl-table-cell">
                    {{strings.appStrings['SENDER']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let wagon"
                    class="p-1 truncate-cell d-none d-xl-table-cell"
                    [matTooltip]="wagon.lane.sender?.name"
                    [matTooltipPosition]="'left'">
                    <span
                        class="break-all"
                        *ngIf="wagon.lane.sender?.name?.length > 10">
                        {{wagon.lane.sender?.name | slice : 0 : 9}}
                    </span>
                    <span
                        class="break-all"
                        *ngIf="wagon.lane.sender?.name?.length <= 10">
                        {{wagon.lane.sender?.name}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="reference"
                    class="p-1 d-none d-xl-table-cell">
                    {{strings.appStrings['SENDER_REFERENCE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let wagon"
                    class="p-1 d-none d-xl-table-cell">
                    {{wagon.sender_ref}}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="description"
                    class="p-1 d-none d-xl-table-cell">
                    {{strings.appStrings['FREIGHT_TYPE']}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let wagon"
                    class="p-1 truncate-cell d-none d-xl-table-cell"
                    [matTooltip]="wagon.containers[0]?.description"
                    [matTooltipPosition]="'left'">
                    <span 
                    class="break-all"
                        *ngIf="wagon.containers[0]?.description?.length > 10">
                        {{wagon.containers[0]?.description | slice : 0 : 9}}&hellip;
                    </span>
                    <span
                    class="break-all"
                        *ngIf="wagon.containers[0]?.description?.length <= 10">
                        {{wagon.containers[0]?.description}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="message">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="p-1 d-none d-md-table-cell">
                </th>
                <td
                    mat-cell
                    *matCellDef="let wagon"
                    class="p-1 d-none d-md-table-cell"
                    [matTooltip]="wagon.containers[0]?.comment"
                    [matTooltipPosition]="'left'">
                    <span 
                        *ngIf="wagon.containers[0]?.comment"
                        [class.blink]="!wagon.hovering" 
                        class="material-icons btn-icon reg"
                        (mouseover)="wagon.hovering=true">
                        message
                    </span>
                </td>
            </ng-container>
    
            <tr 
                mat-header-row 
                *matHeaderRowDef="headers; sticky: true"></tr>
            <tr
                mat-row
                style="height:auto;"
                *matRowDef="let row; columns: headers"
                (contextmenu)="onContextMenu($event, row)"
                [class.highlight]="selectedRowIdx == row.id">
            </tr>
        </table>

        <div
            style="visibility: hidden; position: fixed"
            [style.left.px]="contextMenuPositionX"
            [style.top.px]="contextMenuPositionY"
            [matMenuTriggerFor]="contextMenu">
        </div>

        <span
            style="position: fixed;"
            #spanTrigger
            class="context-menu-trigger"
            #matTrigger="matMenuTrigger"
            [matMenuTriggerFor]="contextMenu">
            <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="item" *ngIf="privileges.isAdmin">
                    <button mat-menu-item (click)="setComment(item)">
                        <span 
                            class="material-icons">
                            record_voice_over
                        </span>
                        {{strings.appStrings['EDIT_COMMENT']}}
                    </button>
                    <button mat-menu-item (click)="endJourney(item)">
                        <span 
                            class="material-icons">
                            task_alt
                        </span>
                        {{strings.appStrings['END_WAGON_JOURNEY']}}
                    </button>
                    <button mat-menu-item (click)="deleteJourney(item)">
                        <span 
                            class="material-icons">
                            delete
                        </span>
                        {{strings.appStrings['DELETE_WAGON_JOURNEY']}}
                    </button>
                </ng-template>
            </mat-menu>
        </span>
    </div>
</div>