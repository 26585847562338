import { Directive } from '@angular/core';

@Directive({
  selector: '[appRegistrationItem]'
})
export class RegistrationItemDirective {

  constructor() { }

}
