import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';
import { StringService } from 'src/app/services/string.service';
import { PrivilegesService } from 'src/app/services/privileges.service';
import * as moment from 'moment';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  username: string = localStorage.getItem('username');

  constructor(private auth: AuthService,
              public searchService: SearchService,
              public router: Router,
              public strings: StringService,
              public privileges: PrivilegesService,
              public filterService: FilterService) { }

  ngOnInit(): void {

  }

  logout() {
    this.auth.logout()
  }

  onSearch(value: string) {
    this.searchService.toggle(value);
  }

  daysSinceNow(days: number): string {
    return moment().local().subtract(days, 'days').format('YYYY-MM-DD')
  }

}
