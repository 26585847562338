<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{data.title}}</h2>

    <span mat-dialog-content>
        <div style="width:100%;text-align:center">
            <h6>{{strings.appStrings['WAGON']}} {{data.wagon.wagon_number}}</h6>
            <span 
                class="row justify-content-center" 
                style="font-size:medium">
                    {{strings.appStrings['AGREEMENT_NUMBER']}} {{data.wagon.lane.agreement_number}}
            </span>
            <span 
                class="row justify-content-center" 
                style="font-size: medium;" 
                [innerHTML]="data?.wagon?.lane?.departure_station?.name + ' - ' + data?.wagon?.lane?.arrival_station?.name">
            </span>
            <div *ngIf="!data?.wagon?.arrival_time">
                <span 
                    class="row justify-content-center" 
                    *ngIf="dateIsValid(data.wagon.expected_arrival) && data?.wagon?.registrations[data?.wagon?.registrations?.length - 1]?.station?.name !== data?.wagon?.lane?.arrival_station?.name" 
                    style="color:green;font-size: medium;">
                        {{strings.appStrings['EXPECTED_ARRIVAL']}} {{data.wagon.expected_arrival | date: 'dd-MM-yyyy'}}
                </span>
                <span 
                    class="row justify-content-center" 
                    *ngIf="!dateIsValid(data.wagon.expected_arrival) && data?.wagon?.registrations[data?.wagon?.registrations?.length - 1]?.station?.name !== data?.wagon?.lane?.arrival_station?.name" 
                    style="color:red;font-size: medium;">
                        {{strings.appStrings['EXPECTED_ARRIVAL']}} {{strings.appStrings['UNKNOWN'].toLowerCase()}}
                </span>
            </div>
            <div *ngIf="data?.wagon?.arrival_time">
                <span 
                    class="row justify-content-center" 
                    *ngIf="dateIsValid(data?.wagon?.arrival_time)" 
                    style="color:green;font-size: medium;">
                        {{strings.appStrings['ARRIVED']}} {{data.wagon.arrival_time | date: 'dd-MM-yyyy'}}
                </span>
            </div>
            <span
                class="row justify-content-center"
                *ngIf="data?.wagon?.registrations[data?.wagon?.registrations?.length - 1]?.station?.name === data?.wagon?.lane?.arrival_station?.name"
                style="color:green;font-size: medium;">
                {{strings.appStrings['ARRIVED']}}
            </span>
            <span class="row justify-content-center">
                {{data.wagon?.registrations[data.wagon?.registrations.length - 1]?.description}}
            </span>
        </div>
    </span>

    <div mat-dialog-content
        class="mt-2"
        style="max-height: 300px;">
        <table
            mat-table
            [dataSource]="dataSource.data | dateSort : 'desc'"
            class="table">
        
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let registration" class="p-1">
                    <span 
                        *ngIf="registration.event_type === 'Departure' || registration.event_type === 'LeftOrigin'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        speed
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Arrival' && registration.station?.name !== data?.wagon?.lane?.arrival_station?.name"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        account_balance
                    </span>
                    <span
                        *ngIf="(registration.event_type === 'Arrival' || registration.event_type === 'ReachedDestination') && registration.station?.name === data?.wagon?.lane?.arrival_station?.name"
                        class="material-icons static"
                        style="font-size: xx-large;color:lightgreen;">
                        check_circle
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Passing' || registration.event_type === 'PassedThrough'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        directions
                    </span>
                    <span
                        *ngIf="registration.event_type === 'BorderCrossing'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        nat
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Pulled'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        mediation
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Delivered' && registration.station?.name !== data?.wagon?.lane?.arrival_station?.name"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        trip_origin
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Ready-Strip' || registration.event_type === 'ReadyToPull' || registration.event_type === 'Ready-Stuff'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        hourglass_full
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Stripped'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        open_with
                    </span>
                    <span
                        *ngIf="registration.event_type === 'WagonBroken' || registration.event_type === 'WagonDamaged'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        error
                    </span>
                    <span
                        *ngIf="registration.event_type === 'WagonRepaired'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        check_box
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Stuffed'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        close_fullscreen
                    </span>
                    <span
                        *ngIf="registration.event_type === 'Unknown'"
                        class="material-icons static"
                        style="font-size: xx-large;color: #7f7f7f;">
                        error_outline
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef>{{strings.appStrings['TIME']}}</th>
                <td mat-cell *matCellDef="let registration" class="p-1">
                    <div class="d-none d-md-table-cell">
                        <span style="color: #7f7f7f;">{{registration.registered_time | date: 'dd/MM/yy HH:mm'}}</span>
                    </div>
                    <div class="d-md-none">
                        <div class="row">
                            <span style="color: #7f7f7f;">{{registration.registered_time | date: 'dd/MM/yy'}}</span>
                        </div>
                        <div class="row">
                            <span style="color: #7f7f7f;">{{registration.registered_time | date: 'HH:mm'}}</span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell">{{strings.appStrings['ACTION']}}</th>
                <td mat-cell *matCellDef="let registration" class="p-1 d-none d-md-table-cell">
                    <span style="color: #7f7f7f;">{{registration.event_type}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef>{{strings.appStrings['LOCATION']}}</th>
                <td mat-cell *matCellDef="let registration" class="p-1">
                    <div class="d-none d-md-table-cell">
                        <span
                            style="color: #7f7f7f;"
                            innerHTML="{{registration.station?.name}}">
                        </span>
                        <span 
                            style="color: #7f7f7f;" 
                            *ngIf="registration.station?.country_code_iso"
                            innerHTML="&nbsp;({{registration.station?.country_code_iso}})">        
                        </span>
                    </div>
                    <div class="d-md-none ms-2">
                        <div class="row">
                            <span
                                style="color: #7f7f7f;"
                                innerHTML="{{registration.event_type}}">
                            </span>
                        </div>
                        <div class="row">
                            <span 
                                *ngIf="registration.station?.name?.length <= 12"
                                style="color: #7f7f7f;" 
                                innerHTML="{{registration.station?.name}}">
                            </span>
                            <span
                                *ngIf="registration.station?.name?.length > 12"
                                style="color: #7f7f7f;"
                                innerHTML="{{registration.station?.name | slice : 0 : 12}}&hellip;"
                                [matTooltip]="registration.station?.name"
                                [matTooltipPosition]="'left'">
                            </span>
                            <span 
                                style="color: #7f7f7f;" 
                                *ngIf="registration.station?.country_code_iso"
                                innerHTML="&nbsp;({{registration.station?.country_code_iso}})">        
                            </span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr
                mat-row
                style="height: auto;"
                *matRowDef="let row; columns: headers"></tr>
        </table>
    </div>

    <mat-dialog-actions>
        <button #close class="mat-raised-button mat-primary p-1" (click)="actionClose()">{{strings.appStrings['CLOSE']}}</button>
    </mat-dialog-actions>
</div>