import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { StringService } from 'src/app/services/string.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

@Component({
  selector: 'app-dialog-end-journey',
  templateUrl: './dialog-end-journey.component.html',
  styleUrls: ['./dialog-end-journey.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
      },
    } }
  ]
})
export class DialogEndJourneyComponent implements OnInit {

  @ViewChild('ok', { static: false }) okBtn: ElementRef;
  @ViewChild('yes', { static: false }) yesBtn: ElementRef;
  data: any;
  date: moment.Moment = moment().local();

  constructor(public strings: StringService,
              private dialogRef: MatDialogRef<DialogEndJourneyComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
                this.data = data;
              }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.okBtn) this.okBtn.nativeElement.focus();
    else if (this.yesBtn) this.yesBtn.nativeElement.focus();
  }

  actionYes() {
    this.dialogRef.close([true, this.date.local().format('YYYY-MM-DD')]);
  }

  actionNo() {
    this.dialogRef.close([false]);
  }

  actionOk() {
    this.dialogRef.close();
  }

}
