<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{strings.appStrings['ADD']}} {{strings.appStrings['LANE']}}</h2>

    <mat-dialog-content [formGroup]="form">
        <mat-form-field class="form-group col-sm w-100">
            <mat-label>{{strings.appStrings['ADD']}} {{strings.appStrings['LANE']}}</mat-label>
            <input
                matInput
                type="text"
                name="lane"
                class="form-control"
                formControlName="lane"
                maxlength="50"
                [matAutocomplete]="arrivalAuto">
            <mat-autocomplete 
                #arrivalAuto="matAutocomplete">
                <mat-option>
                    <div class="grid-3">
                        <span><strong>{{strings.appStrings['AGREEMENT_NUMBER']}}</strong></span>
                        <span><strong>{{strings.appStrings['DEPARTURE_STATION']}}</strong></span>
                        <span><strong>{{strings.appStrings['ARRIVAL_STATION']}}</strong></span>
                    </div>
                </mat-option>
                <mat-option 
                    *ngFor="let lane of filteredLanes | async" 
                    [value]="lane.toString()">
                    <div class="grid-3">
                        <span>{{lane.agreement_number}}</span>
                        <span>{{lane.departure_station?.name}}</span>
                        <span>{{lane.arrival_station?.name}}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </mat-dialog-content>
    
    <mat-dialog-actions>
        <button class="mat-raised-button mat-primary p-1" (click)="add()">{{strings.appStrings['ADD']}}</button>
        <button class="mat-raised-button p-1" (click)="close()">{{strings.appStrings['CANCEL']}}</button>
    </mat-dialog-actions>
</div>
