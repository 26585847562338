<div class="dialog-container w-100">
    <h2 mat-dialog-title>{{strings.appStrings['COOKIE_POLICY_TITLE']}}</h2>

    <span mat-dialog-content style="font-size: small;">
        {{strings.appStrings['COOKIE_POLICY_DESCRIPTION']}}
        <br>
        <a style="color:blue" target="_blank" href="https://dk.dbcargo.com/rail-danmark-dk/privatpolicy-5799264">
            {{strings.appStrings['DATA_PROTECTION_POLICY']}}
        </a>
    </span>

    <mat-dialog-actions>
        <button class="mat-raised-button mat-primary p-1" (click)="accept()">{{strings.appStrings['ACCEPT']}}</button>
    </mat-dialog-actions>
</div>