import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthService } from './auth.service';

export enum Roles {
  STAKEHOLDER = "WIP_STAKEHOLDER",
  SALES = "WIP_SALES",
  ADMIN = "WIP_ADMIN",
  READ = "WIP_READ"
}

@Injectable({
  providedIn: 'root'
})
export class PrivilegesService {

  constructor(private auth: AuthService) {
    this.auth.roleChange.subscribe(() => { this.toggle() })
  }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  private toggle() {
    this.change.emit();
  }

  get isAdmin(): boolean {
    const role = this.auth.getRole();
    return (
      role && role === Roles.ADMIN
    )
  }

  get isAdminOrInternalUser(): boolean {
    const role = this.auth.getRole();
    return (
      role &&
      (
        role === Roles.ADMIN || 
        role === Roles.SALES || 
        role === Roles.READ
      )
    )
  }

  get canEditETA(): boolean {
    const role = this.auth.getRole();
    return (
      role &&
      (
        role === Roles.ADMIN || 
        role === Roles.SALES
      )
    )
  }

  get canEditRegister(): boolean {
    const role = this.auth.getRole();
    return (
      role &&
      (
        role === Roles.ADMIN ||
        role === Roles.SALES
      )
    )
  }

  get canEditOperationStatus(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.ADMIN || 
        role === Roles.SALES
      )
    )
  }

  get canSetupNotifications(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role !== Roles.ADMIN && 
        role !== Roles.SALES
      )
    )
  }

  get canSeeTrainNumberColumn(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.ADMIN || 
        role === Roles.SALES
      )
    )
  }

  get canEditWagonComment(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.ADMIN || 
        role === Roles.SALES
      )
    )
  }

  get canEditArrivalHistoryComment(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.ADMIN || 
        role === Roles.SALES
      )
    )
  }

  get canEndWagonJourney(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.ADMIN || 
        role === Roles.SALES
      )
    )
  }

  get canViewStakeholderFilter(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.ADMIN || 
        role === Roles.SALES || 
        role === Roles.READ
      )
    )
  }

  get canDeleteAccount(): boolean {
    const role = this.auth.getRole();
    return (
      role && 
      (
        role === Roles.STAKEHOLDER
      )
    )
  }
}
