import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParamService {

  private _currentQueryParams = null;
  private _previousQueryParams = null;

  constructor() { }

  public get currentQueryParams() {
    return this._currentQueryParams;
  }

  public set currentQueryParams(paramMap: any) {
    this._previousQueryParams = this._currentQueryParams;
    this._currentQueryParams = paramMap;
  }

  public get previousQueryParams() {
    return this._previousQueryParams;
  }
}
