import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StringService } from '../../../services/string.service';
import { DataService } from '../../../services/data.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Lane } from 'src/app/classes/items';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StakeholderService } from 'src/app/services/stakeholder.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-dialog-attach-lane',
  templateUrl: './dialog-attach-lane.component.html',
  styleUrls: ['./dialog-attach-lane.component.scss']
})
export class DialogAttachLaneComponent implements OnInit {

  form: FormGroup;
  filteredLanes: Observable<Lane[]>;
  private stakeholderId: number;

  constructor(public data: DataService,
              public strings: StringService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogAttachLaneComponent>,
              @Inject(MAT_DIALOG_DATA) configData,
              private stakeholderService: StakeholderService,
              private filterService: FilterService) {
                this.stakeholderId = configData.id;
              }

  ngOnInit(): void {
    this.filterService.lane = { departure_country: null, arrival_country: null }
    this.form = this.fb.group({
      lane: new FormControl('', [
        Validators.required
      ])
    })
    this.filteredLanes = this.form.controls.lane.valueChanges.pipe(
      startWith(this.data.lanes),
      map((value: any) => {
        if (!value || value instanceof Array) return this.data.lanes;        
        return this.data.lanes.filter((lane: Lane) => {
          return `
            ${lane.agreement_number}
            ${lane.departure_station?.name}
            ${lane.arrival_station?.name}
          `.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        })
      })
    )
    this.data.observeLanes().subscribe(() => {
      this.form.controls.lane.setValue('');
    })
  }

  add() {
    let lane = this.data.lanes.find(item => item.toString() === this.form.value.lane);
    if (lane) {
      this.stakeholderService.attachLane(this.stakeholderId, lane.id).subscribe(() => {
        this.dialogRef.close(lane);
      })
    }
    
  }

  close() {
    this.dialogRef.close();
  }

}
