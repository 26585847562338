<nav class="navbar navbar-expand-lg navbar-light">
  
  <div class="container-fluid">
    
    <div class="d-flex justify-content-start" style="width:20%;">
      <img 
        src="assets/img/DB_WIP_icon.png" 
        class="d-flex app-icon" 
        routerLink="/wagons"  
        [queryParams]="filterService.wagon" 
        queryParamsHandling="merge" 
        style="cursor:pointer" 
        (click)="filterService.wagon.lane = null; filterService.wagon.stakeholder = null; searchService.clear();">
    </div>
    <div class="d-flex justify-content-center mt-n2 d-none d-xl-block">
      <span 
        class="company-title" 
        routerLink="/wagons"  
        [queryParams]="filterService.wagon" 
        style="cursor: pointer;" 
        (click)="filterService.wagon.lane = null; filterService.wagon.stakeholder = null; searchService.clear()">
        DB Cargo Scandinavia
      </span>
    </div>
    <div id="navbarSupportedContent">
      <div class="d-flex justify-content-end d-none d-sm-block">
        <ul class="navbar-nav mt-2 mb-lg-0 nav-items">
          
          <form>
            <input 
              class="form-control" 
              type="search" 
              placeholder="{{strings.appStrings['SEARCH']}}" 
              aria-label="search" 
              name="search" 
              [ngModel]="searchService.searchString"
              (input)="onSearch($event.target.value)">
          </form>

          <li class="nav-item dropdown mb-2" style="margin-top:-10px;">
            <a class="nav-link" href="#" (click)="$event.preventDefault()" id="navbarDropdown" role="button" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons" style="font-size: xx-large;color: #ec0016;">
                account_circle
              </span>
            </a>
            <ul class="dropdown-menu dropdowm-menu-left" style="left:-200px;" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" routerLink="/settings">
                  <span 
                    class="material-icons"
                    style="font-size: x-large;">
                    settings
                  </span>
                  {{strings.appStrings['SETTINGS']}}
                </a>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <a class="dropdown-item" (click)="logout()" style="cursor:pointer;">
                  <span
                    class="material-icons"
                    style="font-size: x-large;">
                    logout
                  </span>
                  {{strings.conStrings['LOGOUT']}}
                </a>
              </li>
            </ul>
          </li>
          
        </ul>
      </div>
        
    </div>
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse w-100" id="navbarSupportedContent">
      <ul class="navbar-nav m-auto mt-xl-n2 nav-items">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/wagons" (click)="filterService.resetWagonFilter(); searchService.clear()">{{strings.appStrings['TRACK_AND_TRACE']}} <span class="sr-only"></span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/arrival-history" (click)="searchService.clear()">{{strings.appStrings['ARRIVAL_HISTORY']}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/operation-status" (click)="searchService.clear()">{{strings.appStrings['OPERATION_STATUS']}}</a>
        </li>
        <li *ngIf="privileges.isAdmin" class="nav-item dropdown" [routerLinkActive]="['active']">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{strings.appStrings['LANES']}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="/lanes" (click)="searchService.clear()">{{strings.appStrings['SEE_ALL']}}</a></li>
            <li><a class="dropdown-item" routerLink="/lane" (click)="searchService.clear()">{{strings.appStrings['ADD_NEW']}}</a></li>
          </ul>
        </li>
        <li *ngIf="privileges.isAdmin" class="nav-item dropdown" [routerLinkActive]="['active']">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{strings.appStrings['STAKEHOLDERS']}}
          </a>
          <div class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/stakeholders" (click)="searchService.clear()">{{strings.appStrings['SEE_ALL']}}</a></li>
            <li><a class="dropdown-item" routerLink="/stakeholder" (click)="searchService.clear()">{{strings.appStrings['ADD_NEW']}}</a></li>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
