import { ISenderReceiver } from "../interfaces/i-sender-receiver";

export class SenderReceiver {

    private _id: number;
    private _name: string;
    private _address: string;
    private _country_code_iso: string;
    private _reference: string;
    private _customer_number: number;

    constructor(obj?: ISenderReceiver) {
        if (!obj) return;
        this._id = obj.id;
        this._name = obj.name;
        this._address = obj.address;
        this._country_code_iso = obj.country_code_iso;
        this._reference = obj.reference;
        this._customer_number = obj.customer_number;
    }

    toJSON(): Object {
        return {
            id: this._id,
            name: this._name,
            address: this._address,
            country_code_iso: this._country_code_iso,
            reference: this._reference,
            customer_number: this._customer_number
        }
    }


    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter address
     * @return {string}
     */
	public get address(): string {
		return this._address;
	}

    /**
     * Getter country_code_iso
     * @return {string}
     */
	public get country_code_iso(): string {
		return this._country_code_iso;
	}

    /**
     * Getter reference
     * @return {string}
     */
	public get reference(): string {
		return this._reference;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter address
     * @param {string} value
     */
	public set address(value: string) {
		this._address = value;
	}

    /**
     * Setter country_code_iso
     * @param {string} value
     */
	public set country_code_iso(value: string) {
		this._country_code_iso = value;
	}

    /**
     * Setter reference
     * @param {string} value
     */
	public set reference(value: string) {
		this._reference = value;
	}

    /**
     * Getter customer_number
     * @return {number}
     */
	public get customer_number(): number {
		return this._customer_number;
	}

    /**
     * Setter customer_number
     * @param {number} value
     */
	public set customer_number(value: number) {
		this._customer_number = value;
	}

}
