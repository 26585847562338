import { EventEmitter, Injectable, Output } from '@angular/core';
import { Station } from '../classes/station';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';
import { IStation } from '../interfaces/i-station';
import { Language } from '../classes/language';
import { ILanguage } from '../interfaces/i-language';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _languages: Language[] = null;
  private _isLoading: boolean = false;
  private _loadFailed: boolean = false;

  constructor(private http: HttpClient,
              private api: ApiService,
              private header: HeaderService) { }


  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public toggle() {
    this.change.emit();
  }

  public get languages(): Language[] {
    if (!this._languages && !this._isLoading && !this._loadFailed) {
      this.getLanguages();
    }
    return this._languages;
  }

  public clear() {
    this._languages = null;
    this._isLoading = false;
    this._loadFailed = false;
    this.toggle();
  }

  private getLanguages() {
    this._isLoading = true;
    this.http.get<ILanguage>(
      `${this.api.LOCATION}/languages`,
      this.header.getOptions()
    )
    .subscribe({
      next: (response) => {
        if (response && response instanceof Array) {
          this._languages = [];
          for (let elem of response) {
            this._languages.push(new Language(elem))
          }
          this._isLoading = false;
          this._loadFailed = false;
          this.toggle();
        }
      },
      error: () => {
        this._isLoading = false;
        this._loadFailed = true;
      }
    })
  }
}
