import { Station } from './station';
import * as moment from 'moment';
import { IRegistration } from '../interfaces/i-registration';

export class Registration {

    private _id: number;
    private _wagon_id: number;
    private _event_type: string;
    private _registered_time: Date;
    private _remark: string;
    private _station_id: number;
    private _station: Station;
    private _minutes_until_next: number;
    private _minutes_until_arrival: number;
    private _negative_eta_variance: number;
    private _positive_eta_variance: number;

    constructor(obj?: IRegistration) {
        if (!obj) return;
        this._id = obj.id;
        this._wagon_id = obj.wagon_id;
        this._event_type = obj.event_type;
        // Read the UTC time from the server and convert it to local time
        this._registered_time = moment.utc(obj.registered_time).local().toDate();
        this._remark = obj.remark;
        this._station_id = obj.station_id;
        this._minutes_until_next = obj.minutes_until_next;
        this._minutes_until_arrival = obj.minutes_until_arrival;
        this._negative_eta_variance = obj.negative_eta_variance;
        this._positive_eta_variance = obj.positive_eta_variance;
        this._station = new Station(obj.station);
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter wagon_id
     * @return {number}
     */
	public get wagon_id(): number {
		return this._wagon_id;
	}

    /**
     * Getter event_type
     * @return {string}
     */
	public get event_type(): string {
		return this._event_type;
	}

    /**
     * Getter registered_time
     * @return {Date}
     */
	public get registered_time(): Date {
		return this._registered_time;
	}

    /**
     * Getter remark
     * @return {string}
     */
	public get remark(): string {
		return this._remark;
	}

    /**
     * Getter station_id
     * @return {number}
     */
	public get station_id(): number {
		return this._station_id;
	}

    /**
     * Getter station
     * @return {Station}
     */
	public get station(): Station {
		return this._station;
	}

    /**
     * Getter minutes_until_next
     * @return {number}
     */
	public get minutes_until_next(): number {
		return this._minutes_until_next;
	}

    /**
     * Getter minutes_until_arrival
     * @return {number}
     */
	public get minutes_until_arrival(): number {
		return this._minutes_until_arrival;
	}

    /**
     * Getter negative_eta_variance
     * @return {number}
     */
	public get negative_eta_variance(): number {
		return this._negative_eta_variance;
	}

    /**
     * Getter positive_eta_variance
     * @return {number}
     */
	public get positive_eta_variance(): number {
		return this._positive_eta_variance;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter wagon_id
     * @param {number} value
     */
	public set wagon_id(value: number) {
		this._wagon_id = value;
	}

    /**
     * Setter event_type
     * @param {string} value
     */
	public set event_type(value: string) {
		this._event_type = value;
	}

    /**
     * Setter registered_time
     * @param {Date} value
     */
	public set registered_time(value: Date) {
		this._registered_time = value;
	}

    /**
     * Setter remark
     * @param {string} value
     */
	public set remark(value: string) {
		this._remark = value;
	}

    /**
     * Setter station_id
     * @param {number} value
     */
	public set station_id(value: number) {
		this._station_id = value;
	}

    /**
     * Setter station
     * @param {Station} value
     */
	public set station(value: Station) {
		this._station = value;
	}

    /**
     * Setter minutes_until_next
     * @param {number} value
     */
	public set minutes_until_next(value: number) {
		this._minutes_until_next = value;
	}

    /**
     * Setter minutes_until_arrival
     * @param {number} value
     */
	public set minutes_until_arrival(value: number) {
		this._minutes_until_arrival = value;
	}

    /**
     * Setter negative_eta_variance
     * @param {number} value
     */
	public set negative_eta_variance(value: number) {
		this._negative_eta_variance = value;
	}

    /**
     * Setter positive_eta_variance
     * @param {number} value
     */
	public set positive_eta_variance(value: number) {
		this._positive_eta_variance = value;
	}
}
