import { Pipe, PipeTransform } from '@angular/core';
import { Registration } from '../classes/registration';

@Pipe({
  name: 'dateSort'
})
export class DateSortPipe implements PipeTransform {

  transform(arr: Registration[], order: string = 'asc'): Registration[] {
    if (!arr || arr instanceof Array === false) return arr;
    if (arr.length <= 1) return arr;
    const sorted = arr.sort((a: Registration, b: Registration) => {
      const aTime: number = (a && a.registered_time) ? a.registered_time.getTime() : 0;
      const bTime: number = (b && b.registered_time) ? b.registered_time.getTime() : 0;
      if (aTime < bTime) return order == 'asc' ? -1 : 1;
      if (aTime > bTime) return order == 'asc' ? 1 : -1;
      if (a.event_type < b.event_type) return order == 'asc' ? -1: 1;
      if (a.event_type > b.event_type) return order == 'asc' ? 1 : -1;
      if (a.station_id < b.station_id) return order == 'asc' ? -1 : 1;
      if (a.station_id > b.station_id) return order == 'asc' ? 1 : -1;
      return 0;
    })
    return sorted;
  }

}
