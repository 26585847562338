import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { Oauth2Component } from './components/login/oauth2/oauth2.component';
import { LanesComponent } from './components/lane/lanes/lanes.component';
import { WagonsComponent } from './components/wagons/wagons.component';
import { SettingsComponent } from './components/settings/settings.component';
import { LaneComponent } from './components/lane/lane/lane.component';
import { StakeholdersComponent } from './components/stakeholder/stakeholders/stakeholders.component';
import { StakeholderComponent } from './components/stakeholder/stakeholder/stakeholder.component';
import { DiscardChangesGuard } from './guards/discard-changes.guard';
import { ArrivalHistoryComponent } from './components/arrival-history/arrival-history.component';
import { OperationStatusComponent } from './components/operation-status/operation-status.component';
import { EditOperationStatusComponent } from './components/edit-operation-status/edit-operation-status.component';
import { QAndAComponent } from './components/q-and-a/q-and-a.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { UnderMaintenanceGuard } from './guards/under-maintenance.guard';
import { MagicLoginComponent } from './components/magic-login/magic-login.component';


const routes: Routes = [
  { path: '', component: WagonsComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'login', component: LoginComponent },
  { path: 'login/request', component: LoginComponent },
  { path: 'login/oauth2/:code/:state', component: Oauth2Component },
  { path: 'login/access-denied', component: LoginComponent },
  { path: 'login/magic-login-request', component: LoginComponent },
  { path: 'magic-login/:username/:token', component: MagicLoginComponent },
  { path: 'create-password/:username/:application/:token', component: LoginComponent },
  { path: 'qa', component: QAndAComponent },
  { path: 'wagons', component: WagonsComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'settings', component: SettingsComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'lanes', component: LanesComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'lane', component: LaneComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], canDeactivate: [ DiscardChangesGuard ], runGuardsAndResolvers: 'always' },
  { path: 'lane/:laneId', component: LaneComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], canDeactivate: [ DiscardChangesGuard ], runGuardsAndResolvers: 'always' },
  { path: 'stakeholders', component: StakeholdersComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'stakeholder', component: StakeholderComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], canDeactivate: [ DiscardChangesGuard ], runGuardsAndResolvers: 'always' },
  { path: 'stakeholder/:stakeholderId', component: StakeholderComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], canDeactivate: [ DiscardChangesGuard ], runGuardsAndResolvers: 'always' },
  { path: 'arrival-history', component: ArrivalHistoryComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'operation-status', component: OperationStatusComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'operation-status/edit', component: EditOperationStatusComponent, canActivate: [ AuthenticationGuard, UnderMaintenanceGuard ], runGuardsAndResolvers: 'always' },
  { path: 'maintenance', component: UnderMaintenanceComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
